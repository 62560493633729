@import url(https://maxcdn.icons8.com/fonts/line-awesome/1.1/css/line-awesome.min.css);
@import url(https://fonts.googleapis.com/css2?family=Lexend:wght@300&display=swap);
/*@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;600&display=swap');*/

/*@font-face {*/
/*    font-family: "Montserrat";*/
/*    src: local("Montserrat"), url("./fonts/Montserrat-Regular.ttf") format("truetype");*/
/*    font-weight: normal;*/
/*}*/

/*@font-face {*/
/*    font-family: "Inter";*/
/*    font-style: normal;*/
/*    font-weight: 400;*/
/*    src: local("Inter"),*/
/*    url("./fonts/Inter-VariableFont_slnt,wght.ttf") format("truetype");*/
/*    !*font-weight: normal;*!*/
/*}*/


@font-face {
    font-family: "DigitalFont";
    src: local("FontsFree-Net-DS-DIGI-2"),
    url(/static/media/FontsFree-Net-DS-DIGI-2.63f874d1.ttf)
    format("truetype");
}

body {
    margin: 0;
    /*font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',*/
    /*  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',*/
    /*  sans-serif;*/
    font-family: 'Lexend', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /*overflow: hidden;*/
    overflow-x: hidden;
}

button {
    font-family: 'Lexend', sans-serif;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:focus {
    outline-color: transparent;
    outline-style: none;
}

a:link {
    color: #416CAA;
    background-color: transparent;
    text-decoration: none;
}

/* Da skrol bude nevidljiv */
/*body::-webkit-scrollbar {*/
/*  display: none;*/
/*}*/

/*ZOOM */
/*#transition-popper id se odnosi na Leave dugme u zoom call-u koje se iz nekog razloga pozicionira ispod video call objekta*/
#transition-popper {
    z-index: 999;
}

/*meetingSDKElement id je div u kome se kreira zoom call window*/
#meetingSDKElement {
    justify-content: center;
}

/*klasa u kojoj se nalazi zoom call window*/
.jss10 {
    position: relative !important;;
}

/*modifikovanje google address dropdown-a*/
.pac-icon {
    display: none;
}

.pac-container:after {
    display: none;
}


.App {
  text-align: center;
}

.App-link {
  color: #61dafb;
}

.Layout_Layout__3SBgk {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    z-index: -2;
    /*width: 100%;*/
    background-color: white;
    min-height: 100vh;
    max-width: 100vw;
}

@media screen and (min-width: 700px){
    .Layout_Layout__3SBgk {
        width: 100%;
        justify-content: center;
        height: auto;
        /*background-color: #FBFBFB;*/
    }
}

.Layout_LayoutContent__YkCec {
    /*position: relative;*/
    /*min-height: calc(100vh - 65px);*/   /*  vrednost za stari/prethodni Footer (visine 65px).  */
    min-height: calc(100vh - 352px);
    /*background-color: #F3F6F8;*/
    z-index: 1;
    max-width: inherit;
    width: 100%;
}
.Layout_slide-container__1N0Lv {

}

.Footer_FooterMobile__1ppEU {
    font-size: 0.75rem;
    color: #535358;
    padding: .7rem;
    text-align: center;
    position: relative;
    bottom: 0;
    position: relative !important;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
}

.Footer_Footer__238hM {
    font-size: 0.75rem;
    color: #4F4F4F;
    padding: 1.25rem;
    /*width: 100vw;*/
    bottom: 0;
    /*box-shadow: 0 0 0.8rem #cccccc;*/
    position: relative !important;
    /*position: absolute;*/
    text-align: center;

    box-sizing: border-box;
    display: flex;
    justify-content: center;
    width: 100%;
    background-color: white;
}

/*.Footer div {*/
/*    !*margin: auto;*!*/
/*    position: absolute;*/
/*    bottom: 0;*/
/*    !*width: 100%;*!*/
/*    !*height: 2.5rem;*!*/
/*}*/




/*.FooterWrapper {*/
/*    width: 1302px;*/
/*    display: flex;*/
/*    box-sizing: border-box;*/
/*    padding: 3rem 4rem 3rem 2rem;*/
/*}*/
.Footer_Footer_Top__12hAV {
    /*width: 1302px;*/
    display: flex;
    box-sizing: border-box;
    font-size: 16px;
    /*padding: 3rem 4rem 3rem 2rem;*/
    /*padding: 32px 32px 32px 32px;*/
}

.Footer_Footer_LeftBox__11Nf1 {
    color: #4a4f54;
    width: 30%;
    /*padding: 2rem 4rem;*/
    padding: 1rem 0 2rem 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.Footer_Footer_LeftBox__11Nf1 p {
    margin: 0;
    padding-top: 0.4rem;
}

.Footer_Footer_RightBox__Jhibc {
    width: 70%;
    padding: 2rem;
    display: flex;
    /*justify-content: space-around;*/

    justify-content: flex-end;
}

.Footer_Footer_RightBox_Links__k5Buv {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    color: #4a4f54;

    padding-right: 8rem;
}
.Footer_Footer_RightBox_Links__k5Buv a {
    padding-top: 0.4rem;
    color: inherit;
}

.Footer_Footer_Bottom__143XK {
    color: #4a4f54;
    height: content-box;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    padding: 0 0.5rem;
}

.Footer_Footer_Bottom_SocialNetworks__394pz {
    font-size: 1.4rem;
    padding: 6px;
    margin: 0.5rem;
    border: 1px solid transparent;
    border-radius: 20%;
    color: black;
    width: 32px;
    height: 32px;
}

.Footer_Footer_Bottom_SocialNetworks__394pz:hover {
    /*stroke: white;*/
    /*fill: #00B2B1;*/
    border: 1px solid #00B2B1;
}

.Footer_FooterSectionTitle__2vYjh {
    font-weight: bold;
}

.Logo_Logo__1N0xH {
    background-color: transparent;
    padding: 8px;
    height: 2.75rem;
    box-sizing: border-box;
    border-radius: 5px;
    z-index: 30;
    cursor: pointer;
}

.Logo_Logo__1N0xH img {
    height: 100%;
}

@media only screen and (min-width: 700px) {
    .Logo_Logo__1N0xH {
        height: 3.5rem;
    }
}
.Wrapper_Wrapper__3HvnQ {
    width: 100vw;
}

@media screen and (min-width: 700px){
    .Wrapper_Wrapper__3HvnQ{
        width: 1366px;
    }
}
.PicturePlacer_PicturePlacer__SLJmx {
    /*background-image: url("../../../assets/Apoteka-cover-1_mobile.jpg");*/
    /*background: linear-gradient(to bottom right, red, yellow), url("../../../assets/Apoteka-cover-1_mobile.jpg");*/
    height: 100vh;
    display: flex;
    flex-direction: column;
    background-position-x: right;
    background-repeat: no-repeat;
    background-size: cover;
    justify-content: space-between;
}

.PicturePlacer_PicturePlacer__SLJmx .PicturePlacer_Content__4PUC7 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: auto 0;
    position: relative;
}

.PicturePlacer_PicturePlacer__SLJmx .PicturePlacer_LeftPane__1Pzd9 {
    display: flex;
    flex-direction: column;
    height: 100%;
    align-self: flex-end;
    padding-left: 2rem;
    max-width: 445px;
    max-height: 500px;
    margin: auto 0;
}

.PicturePlacer_PicturePlacer__SLJmx .PicturePlacer_CommercialImage__BovnX {
    display: flex;
    flex-direction: column;
    height: 100%;
    align-self: flex-start;
    /*padding-left: 128px;*/
    max-width: 600px;
    /*max-height: 500px;*/
    margin: auto 0;
    position: absolute;
    right: 0;
    bottom: 0;
    justify-content: center;
    padding-right: 40px;
    cursor: pointer;
}

.PicturePlacer_CommercialImage__BovnX .PicturePlacer_Image__34NMQ {
    width: 500px;
    height: 500px;
}

@media screen and (min-width: 700px){
    .PicturePlacer_PicturePlacer__SLJmx {
        /*background: linear-gradient(to bottom right, white, transparent 35%), url("../../../assets/Apoteka-cover-1.jpg") no-repeat;*/
        /*background: url("../../../assets/Apoteka-cover-1-grad.jpg") no-repeat;*/
        /*background: linear-gradient(to bottom right, white, transparent 25%), url("../../../assets/medicine-4097308_1920.jpg") no-repeat;*/
        background-position-x: center;
        /*background-repeat: no-repeat;*/
        background-size: cover;
        justify-content: flex-start;
        display: flex;
        flex-direction: column;
    }
}
.SearchWrapper_SearchWrapper__1Ovou {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    margin: 0;
}

.SearchWrapper_SearchWrapperSmall__2lhXu {
    border-radius: 1.563rem;
    margin: 0;
    /*z-index: 14;*/
/*    TODO ovo treba izmeniti, tj izbeci da se ne koristi important
       ako zelimo da izbegnemo important moramo da razdvojimo searchbar komponente za mainpage i za apppage*/
}

.SearchWrapper_Content__xIOQl {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    margin-top: 1rem;
    height: 4rem;

    /*position: sticky;*/
    /*top: 0;*/
    /*Stocky je da se zalepi za vrh, tj da ostaje uvek na vrhu kada se skroluje*/
}

@media screen and (min-width: 700px) {
    .SearchWrapper_Content__xIOQl {
        width: 145%;
        height: auto;
    }
}
.SearchBar_SearchBarMainPageWrapper__hLczV {
    display: flex;
    position: relative;
    border-radius: 3px;
    z-index: 23;
    margin: 0; /* kada se vrate linkovi iznad search bara, vratiti na: margin: 0 auto*/
    width: 90vw;
}

.SearchBar_SearchBarActionMenuWrapper__3Cb0N {
    display: flex;
    position: relative;
    border-radius: 3px;
    width: 100%;
    z-index: 23;
}

.SearchBar_SearchBarMainPage__2OPjE {
    display: flex;
    height: 2.52rem;
    width: 100%;
    position: relative;
}

.SearchBar_SearchIcon__2a-46 {
    position: absolute;
    height: 24px;
    width: 24px;
    top: 13px;
    right: 1rem;
    color: #535358;
    z-index: 23;
}

.SearchBar_SearchIconOnActionMenu__25MfR {
    position: absolute;
    height: 22px;
    width: 22px;
    top: 0.438rem;
    right: 1rem;
    color: #535358;
    z-index: 23;
}

.SearchBar_SearchBarActionMenu__3WCXW {
    height: 2.25rem;
    /*flex: 1;*/
    width: 100%;
    display: flex;
}

.SearchBar_SearchBarMainPage__2OPjE input {
    height: auto;
    width: 100%;
    position: relative;
    border-radius: 3px;
    border: 1px solid #e6e6e6;
    padding-left: 2rem;
    outline: 0;
    box-shadow: 0 0 0 0 rgba(136, 136, 136, 0);
    /*transition: box-shadow .3s;*/
    font-size: 1rem;
    color: #535358;
    z-index: 22;
    background-color: white;
}

.SearchBar_SearchBarActionMenu__3WCXW input {
    font-size: 1rem;
}

.SearchBar_SearchBarActionMenu__3WCXW input:focus, input:hover{
    border: 1px solid #013a63ff;
}

.SearchBar_SearchBarMainPage__2OPjE ::-webkit-input-placeholder{
    color: #8b8989;
    opacity: 0.5;
}

.SearchBar_SearchBarMainPage__2OPjE :-ms-input-placeholder{
    color: #8b8989;
    opacity: 0.5;
}

.SearchBar_SearchBarMainPage__2OPjE ::placeholder{
    color: #8b8989;
    opacity: 0.5;
}

.SearchBar_SearchBarActionMenu__3WCXW ::-webkit-input-placeholder {
    color: #8b8989;
    opacity: 1;
    font-size: 1rem;
}

.SearchBar_SearchBarActionMenu__3WCXW :-ms-input-placeholder {
    color: #8b8989;
    opacity: 1;
    font-size: 1rem;
}

.SearchBar_SearchBarActionMenu__3WCXW ::placeholder {
    color: #8b8989;
    opacity: 1;
    font-size: 1rem;
}

.SearchBar_SearchBarAppPage__UoXzg input:focus{
    /*padding: 0 50px;*/
}

.SearchBar_SearchBarInactive__j0lN3 {
    box-shadow: none;
    border: none;
    border-radius: 0;
}

.SearchBar_SearchBarMainPage__2OPjE input:focus {
    /*box-shadow: 0 0 0.625rem -0.25rem #535358;*/
    /*border: 1px solid #535358;*/
}

.SearchBar_SearchBarHidden__1Cq63 {
    display: none;
}

@media only screen and (min-width: 700px) {
    .SearchBar_SearchBarMainPageWrapper__hLczV {
        display: flex;
        position: relative;
        border-radius: 3px;
        z-index: 23;
        margin: 0 auto 10rem; /* kada se vrate linkovi iznad search bara, vratiti na: margin: 0 auto*/
        width: 100%;
        max-width: 60rem; /* stari sajt 650px*/
        /*text-align: center;*/
    }

    .SearchBar_SearchBarActionMenuWrapper__3Cb0N {
        width: 30.5rem; /* 592px */
        z-index: 22;
    }

    .SearchBar_SearchBarMainPage__2OPjE {
        height: 3rem;
    }

    .SearchBar_SearchBarActionMenu__3WCXW {
        height: 44px;
    }
}

@media screen and (max-width: 699px) {
    .SearchBar_SearchBarMainPage__2OPjE input {
        padding-left: .5rem;
    }

    .SearchBar_SearchBarMainPageWrapper__hLczV:focus-within {
        position: absolute !important;
    }
}

@media (min-width: 1640px) {
    .SearchBar_SearchBarActionMenuWrapper__3Cb0N {
        width: 40.5rem; /* 592px */
    }
}

@media (min-width: 1900px) {
    .SearchBar_SearchBarActionMenuWrapper__3Cb0N {
        width: 40.5rem; /* 592px */
    }
}

.SearchBar_SearchBarActionMenu__3WCXW .SearchBar_ShopName__YVx_T {
    background-color: #416CAA;
    font-size: 1rem;
    max-width: 30%;
    position: absolute;
    height: 100%;
    left: 0;
    top: 0;
    border-radius: 20px 0 0 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.SearchBar_SearchBarShopPage__2jzPg {
    width: 100%;
    border: none;
}

/* placeholder u search baru */
.SearchBar_SearchBarActionMenu__3WCXW .SearchBar_SearchBarShopPage__2jzPg {
    padding-left: 2rem;
}

[type=input] {
    background-image: url(/static/media/search_icon.6c0ccbac.svg);
    background-position: calc(100% - 16px)  center;
    background-repeat: no-repeat;
    background-size: 16px 16px;
    padding: 1em 16px 1em 1em;
    border: 1px solid #8b8989;
}

[type=input]:not(:-ms-input-placeholder) {
    background-image: none;
}

[type=input]:not(:placeholder-shown) {
    background-image: none;
}
.SearchItem_SearchItemMainPage__1yZcE {
    display: flex;
    flex-direction: column;
    height: auto;
    justify-content: left;
    padding-bottom: 0.313rem;
    padding-top: 0.313rem;
}

.SearchItem_SearchItemAppPage__6bxZD {

}

.SearchItem_SearchItemMainPage__1yZcE a:hover {
    background-color: #dfdfdf;
}
.SearchItem_Selected__3LyyB {
    background-color: #E2E8EC;
    width: 100%;
}

.SearchItem_SearchItemMainPage__1yZcE a.SearchItem_span__3kxef{
    font-weight: bold;
    color: inherit;
    font-family: inherit;
    font-size: 1rem;
    line-height: 2;
    white-space: nowrap;
    cursor: pointer;
    text-align: left;
}

.SearchItem_SearchItemMainPage__1yZcE strong{
    font-weight: bold;
    /*color: orangered;*/
    /*=== nova boja trazenog stringa ===*/
    /*color: #F58300;*/
    color: #219EBC;
    /*color: #FFAE00;*/
}

.SearchItem_SearchItemMainPage__1yZcE a {
    font-size: 0.875rem;
    color: #333333;
    line-height: 1.5;
    outline: none;
    overflow: hidden;
    padding-bottom: 0.5rem;
    -webkit-padding-end: 1rem;
            padding-inline-end: 1rem;
    /*padding-inline-start: 3.125rem;*/
    padding-top: 0.5rem;
    position: relative;
    text-decoration: none;
    text-overflow: ellipsis;
    white-space: normal;
    text-align: left;
}

.SearchItem_SearchItemMainPage__1yZcE:focus {
    background-color: #61dafb;
}

.SearchItem_SearchItemMainPage__1yZcE .SearchItem_SearchItemImage__2_whC img {
    border-radius: 0.5rem;
    max-height: 3.25rem;
    max-width: 3.25rem;
    padding-left: 0.938rem;
}
.SearchItem_SearchItemMainPage__1yZcE .SearchItem_SearchItemImage__2_whC{
    border-radius: 0.5rem;
    bottom: 0;
    display: flex;
    height: 2rem;
    justify-content: center;
    align-items: center;
    /*left: 0.75rem;*/
    margin: auto;
    position: absolute;
    top: 0;
    width: 1rem;
    /*===== prebacena lupica na desnu stranu ======*/
    /*right: 0.05rem;*/
    right: .15rem;
}

.SearchItem_ShoppingCartFromShopSearchBar__1TlN7:hover {
    cursor: pointer;
}

@media screen and (min-width: 700px) {

    .SearchItem_SearchItemMainPage__1yZcE .SearchItem_SearchItemImage__2_whC{
        width: 1.5rem;
        padding-right: 1rem;
    }

    .SearchItem_SearchItemMainPage__1yZcE a {
        /*padding-inline-start: 4rem;*/
        white-space: nowrap;
        /*===== deo (ispod) dodat zbog horizontalnog scroll-a teksta, tj. duzih naziva artikala ======*/
        -webkit-padding-start: 2rem;
                padding-inline-start: 2rem;
        background-clip: padding-box;
    }

    /*-------- deo (ispod) dodat zbog horizontalnog scroll-a teksta, tj. duzih naziva artikala --------*/
    .SearchItem_ScrollLongArticleName__25APq {
        overflow: visible;

        width: 150%;
        cursor: pointer;
        -webkit-animation: SearchItem_horizontalScroll__1qd71 5s ease-in-out 1;
                animation: SearchItem_horizontalScroll__1qd71 5s ease-in-out 1;
        background: #dfdfdf;
    }

    .SearchItem_ScrollLongArticleNameHover__2GBiw {
        overflow: visible;
    }
    .SearchItem_ScrollLongArticleNameHover__2GBiw:hover {
        overflow: visible;
        width: 150%;
        cursor: pointer;
        -webkit-animation: SearchItem_horizontalScroll__1qd71 5s ease-in-out 1;
                animation: SearchItem_horizontalScroll__1qd71 5s ease-in-out 1;
        background: #dfdfdf;
    }

    @-webkit-keyframes SearchItem_horizontalScroll__1qd71 {
        0% {
            background: #dfdfdf;
        }
        90% {
            -webkit-transform: translateX(calc(-100% + 85%));
                    transform: translateX(calc(-100% + 85%));
        }
        100% {
            -webkit-transform: translateX(0);
                    transform: translateX(0);
        }
    }

    @keyframes SearchItem_horizontalScroll__1qd71 {
        0% {
            background: #dfdfdf;
        }
        90% {
            -webkit-transform: translateX(calc(-100% + 85%));
                    transform: translateX(calc(-100% + 85%));
        }
        100% {
            -webkit-transform: translateX(0);
                    transform: translateX(0);
        }
    }

    .SearchItem_ShortArticleName__377nP {
        overflow: visible;
        width: 150%;
    }
    .SearchItem_ShortArticleName__377nP:hover {
        overflow: visible;
        width: 150%;
        cursor: pointer;
    }
    /*-------- deo (iznad) dodat zbog horizontalnog scroll-a teksta, tj. duzih naziva artikala --------*/
}
.SearchItems_SearchItems__1LA-z{
    width: 100%;
    background-color: white;
    /*border-radius: 2.188rem;*/
    display: flex;
    flex-direction: column;
    padding-left: .5rem;
    position: absolute;
    z-index: 21;
    box-shadow: 0 0 0.625rem -0.25rem #535358;
    max-height: 50vh;
    top: 2.5rem;
    box-sizing: border-box;
    border-radius: 0 0 3px 3px;
}

@media screen and (min-width: 700px) {
    .SearchItems_SearchItemsMainPage__3kli0 {
        width: 100%;
        padding: 1.6rem 0 0.125rem 0;
        top: 2rem;
    }

    .SearchItems_SearchItemsActionMenu__3WThk {
        width: 100%;
        top: 2rem;
    }

    .SearchItems_SearchItems__1LA-z{
        padding: 1.6rem 0 0.125rem 0;
        max-height: 39vh;
        top: 2rem;
    }
}

.SearchItems_SearchItems__1LA-z .SearchItems_Bottom__36xmb{
    float: bottom;
    overflow-y: scroll;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    /*  deo (ispod) dodat zbog horizontalnog scroll-a za duge nazive artikala  */
    overflow-x: hidden;
    -webkit-padding-end: 2rem;
            padding-inline-end: 2rem;
}

.SearchItems_SearchItems__1LA-z .SearchItems_Bottom__36xmb::-webkit-scrollbar-thumb{
    background-color: #dfdfdf;
    border: 0.25rem solid transparent;
    border-radius: 0.5rem;
    background-clip: padding-box;
}

.SearchItems_SearchItems__1LA-z .SearchItems_Bottom__36xmb::-webkit-scrollbar{
    width: 0.938rem;
}

.NoMatchSearchItem_NoMatchSearchItemMainPage__e0we7 {
    display: flex;
    flex-direction: column;
    height: auto;
    justify-content: left;
    padding-bottom: 0.313rem;
    padding-top: 0.313rem;
}

.NoMatchSearchItem_NoMatchSearchItemAppPage__2t6oy {

}

.NoMatchSearchItem_NoMatchSearchItemMainPage__e0we7 a:hover {
    background-color: #dfdfdf;
}

.NoMatchSearchItem_NoMatchSearchItemMainPage__e0we7 a.NoMatchSearchItem_span__2IXej{
    font-weight: bold;
    color: inherit;
    font-family: inherit;
    font-size: 1rem;
    line-height: 2;
    white-space: nowrap;
    cursor: pointer;
    text-align: left;
}

.NoMatchSearchItem_NoMatchSearchItemMainPage__e0we7 a {
    font-size: 0.875rem;
    color: #333333;
    line-height: 1.5;
    outline: none;
    overflow: hidden;
    padding-bottom: 0.5rem;
    -webkit-padding-end: 1rem;
            padding-inline-end: 1rem;
    -webkit-padding-start: 4rem;
            padding-inline-start: 4rem;
    padding-top: 0.5rem;
    position: relative;
    text-decoration: none;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: left;

}
.NoMatchSearchItem_NoMatchSearchItemMainPage__e0we7:focus {
    background-color: #61dafb;
}

.NoMatchSearchItem_NoMatchSearchItemMainPage__e0we7 .NoMatchSearchItem_NoMatchSearchItemImage__3knDE img {
    border-radius: 0.5rem;
    max-height: 3.25rem;
    max-width: 3.25rem;
    padding-left: 0.938rem;
}
.NoMatchSearchItem_NoMatchSearchItemMainPage__e0we7 .NoMatchSearchItem_NoMatchSearchItemImage__3knDE{
    align-items: center;
    border-radius: 0.5rem;
    bottom: 0;
    display: flex;
    height: 2rem;
    justify-content: center;
    left: 0.75rem;
    margin: auto;
    position: absolute;
    top: 0;
    width: 1.5rem;
}

/*!*.Loader {*!*/
/*!*    font-size: 0.625rem;*!*/
/*!*    margin: 3.125rem auto;*!*/
/*!*    text-indent: -9999rem;*!*/
/*!*    width: 11rem;*!*/
/*!*    height: 11rem;*!*/
/*!*    border-radius: 50%;*!*/
/*!*    background: #e0e2e4;*!*/
/*!*    background: -moz-linear-gradient(left, #e0e2e4 10%, rgba(224,226,228, 0) 42%);*!*/
/*!*    background: -webkit-linear-gradient(left, #e0e2e4 10%, rgba(224,226,228, 0) 42%);*!*/
/*!*    background: -o-linear-gradient(left, #e0e2e4 10%, rgba(224,226,228, 0) 42%);*!*/
/*!*    background: -ms-linear-gradient(left, #e0e2e4 10%, rgba(224,226,228, 0) 42%);*!*/
/*!*    background: linear-gradient(to right, #e0e2e4 10%, rgba(224,226,228, 0) 42%);*!*/
/*!*    position: relative;*!*/
/*!*    -webkit-animation: load3 1.4s infinite linear;*!*/
/*!*    animation: load3 1.4s infinite linear;*!*/
/*!*    -webkit-transform: translateZ(0);*!*/
/*!*    -ms-transform: translateZ(0);*!*/
/*!*    transform: translateZ(0);*!*/
/*!*}*!*/
/*!*.Loader:before {*!*/
/*!*    width: 50%;*!*/
/*!*    height: 50%;*!*/
/*!*    background: #e0e2e4;*!*/
/*!*    border-radius: 100% 0 0 0;*!*/
/*!*    position: absolute;*!*/
/*!*    top: 0;*!*/
/*!*    left: 0;*!*/
/*!*    content: '';*!*/
/*!*}*!*/
/*!*.Loader:after {*!*/
/*!*    background: white;*!*/
/*!*    width: 75%;*!*/
/*!*    height: 75%;*!*/
/*!*    border-radius: 50%;*!*/
/*!*    content: '';*!*/
/*!*    margin: auto;*!*/
/*!*    position: absolute;*!*/
/*!*    top: 0;*!*/
/*!*    left: 0;*!*/
/*!*    bottom: 0;*!*/
/*!*    right: 0;*!*/
/*!*}*!*/
/*!*@-webkit-keyframes load3 {*!*/
/*!*    0% {*!*/
/*!*        -webkit-transform: rotate(0deg);*!*/
/*!*        transform: rotate(0deg);*!*/
/*!*    }*!*/
/*!*    100% {*!*/
/*!*        -webkit-transform: rotate(360deg);*!*/
/*!*        transform: rotate(360deg);*!*/
/*!*    }*!*/
/*!*}*!*/
/*!*@keyframes load3 {*!*/
/*!*    0% {*!*/
/*!*        -webkit-transform: rotate(0deg);*!*/
/*!*        transform: rotate(0deg);*!*/
/*!*    }*!*/
/*!*    100% {*!*/
/*!*        -webkit-transform: rotate(360deg);*!*/
/*!*        transform: rotate(360deg);*!*/
/*!*    }*!*/
/*!*}*!*/

/*.LoaderHolder {*/
/*    display: flex;*/
/*    flex-direction: column;*/
/*    align-items: center;*/
/*    padding-top: 1.25rem;*/
/*}*/

/*.Loader {*/
/*    animation: rotate 1600ms infinite;*/
/*    height: 50px;*/
/*    width: 50px;*/
/*}*/

/*.LoaderSmall {*/
/*    animation: rotateSmall 1600ms infinite;*/
/*    height: 50px;*/
/*    width: 50px;*/
/*}*/

/*.Loader:before,*/
/*.Loader:after,*/
/*.LoaderSmall:before,*/
/*.LoaderSmall:after {*/
/*    border-radius: 50%;*/
/*    content: '';*/
/*    display: block;*/
/*    height: 20px;*/
/*    width: 20px;*/
/*}*/

/*.Loader:before,*/
/*.LoaderSmall:before {*/
/*    animation: ball1 1s infinite;*/
/*    background-color: #E63133;*/
/*    box-shadow: 30px 0 0 #F09200;*/
/*    margin-bottom: 10px;*/
/*}*/

/*.Loader:after,*/
/*.LoaderSmall:after {*/
/*    animation: ball2 1s infinite;*/
/*    background-color: #416caa;*/
/*    box-shadow: 30px 0 0 #94C11F;*/
/*}*/

/*@keyframes rotate {*/
/*    0% {*/
/*        -webkit-transform: rotate(0deg) scale(1.8);*/
/*        -moz-transform: rotate(0deg) scale(1.8);*/
/*    }*/
/*    50% {*/
/*        -webkit-transform: rotate(360deg) scale(2.2);*/
/*        -moz-transform: rotate(360deg) scale(2.2);*/
/*    }*/
/*    100% {*/
/*        -webkit-transform: rotate(720deg) scale(1.8);*/
/*        -moz-transform: rotate(720deg) scale(1.8);*/
/*    }*/
/*}*/

/*@keyframes rotateSmall {*/
/*    0% {*/
/*        -webkit-transform: rotate(0deg) scale(1);*/
/*        -moz-transform: rotate(0deg) scale(1);*/
/*    }*/
/*    50% {*/
/*        -webkit-transform: rotate(360deg) scale(1.4);*/
/*        -moz-transform: rotate(360deg) scale(1.4);*/
/*    }*/
/*    100% {*/
/*        -webkit-transform: rotate(720deg) scale(1);*/
/*        -moz-transform: rotate(720deg) scale(1);*/
/*    }*/
/*}*/

/*@keyframes ball1 {*/
/*    0% {*/
/*        box-shadow: 30px 0 0 #f8b334;*/
/*    }*/
/*    50% {*/
/*        box-shadow: 0 0 0 #f8b334;*/
/*        margin-bottom: 0;*/
/*        -webkit-transform: translate(15px, 15px);*/
/*        -moz-transform: translate(15px, 15px);*/
/*    }*/
/*    100% {*/
/*        box-shadow: 30px 0 0 #f8b334;*/
/*        margin-bottom: 10px;*/
/*    }*/
/*}*/

/*@keyframes ball2 {*/
/*    0% {*/
/*        box-shadow: 30px 0 0 #97bf0d;*/
/*    }*/
/*    50% {*/
/*        box-shadow: 0 0 0 #97bf0d;*/
/*        margin-top: -20px;*/
/*        -webkit-transform: translate(15px, 15px);*/
/*        -moz-transform: translate(15px, 15px);*/
/*    }*/
/*    100% {*/
/*        box-shadow: 30px 0 0 #97bf0d;*/
/*        margin-top: 0;*/
/*    }*/
/*}*/


/*loader three dots*/
.Spinner_Loader__1twK-,
.Spinner_Loader__1twK-:before,
.Spinner_Loader__1twK-:after {
    border-radius: 50%;
    width: 2.5em;
    height: 2.5em;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation: Spinner_load7__3lEXz 1.8s infinite ease-in-out;
    animation: Spinner_load7__3lEXz 1.8s infinite ease-in-out;
    color: #00B2B1;
}
.Spinner_Loader__1twK- {
    color: #00B2B1;
    font-size: 8px;
    margin: 30px auto;
    position: relative;
    text-indent: -9999em;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
    top: -20px;
}
.Spinner_Loader__1twK-:before,
.Spinner_Loader__1twK-:after {
    content: '';
    position: absolute;
    top: 0;
}
.Spinner_Loader__1twK-:before {
    left: -3.5em;
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
}
.Spinner_Loader__1twK-:after {
    left: 3.5em;
}
@-webkit-keyframes Spinner_load7__3lEXz {
    0%,
    80%,
    100% {
        box-shadow: 0 2.5em 0 -1.3em;
    }
    40% {
        box-shadow: 0 2.5em 0 0;
    }
}
@keyframes Spinner_load7__3lEXz {
    0%,
    80%,
    100% {
        box-shadow: 0 2.5em 0 -1.3em;
    }
    40% {
        box-shadow: 0 2.5em 0 0;
    }
}
/*end loader three dots*/
.Backdrop_Backdrop__3j6VK {
    width: 100vw;
    height: 100vh;
    position: fixed;
    z-index: 23;
    left: 0;
    top: 0;
    background-color: rgba(0,0,0,0.20);
    /*background-color: rgba(0, 0, 0, 0.75);*/
}
/*
position: fixed;
z-index: 1;
background-color: rgba(0, 0, 0, 0.75);
width: 100%;
height: 100vh;
top: 0;
left: 0;
 */
/*
LOGO BOJE
narandzasta: f39200
braon: a48a7a
crvena: e63133
zelena: 94c11f
plava: 416cab

*/

.MainPageButtons_MainPageButtonsWrapper__2CQTC {
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 110px;

}

.MainPageButtons_MainPageButtonsDropdown__3_SII {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 6px;
}

.MainPageButtons_MainPageButtonsSelectionBox__36re8 {
    width: 100%;
    height: 2.52rem;
    background-color: white;
    border-radius: 6px 0 0 6px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    box-sizing: border-box;
    position: relative;
    cursor: pointer;
    border: 1px solid #e6e6e6;
    border-right: none;
}

.MainPageButtons_DropdownChevron__30Kb- {
    position: absolute;
    bottom: 14px;
    right: 6px;
    width: .75rem;
    stroke: #4D5055;
}

.MainPageButtons_MainPageSingleButtonWrapper__1gJA0 {

}

.MainPageButtons_MainPageButtonsDropdownClosed__3H8E1 {
    visibility: hidden;
}

.MainPageButtons_MainPageButtonsDropdownOpen__iYe5n {
    visibility: visible;
}

.MainPageButtons_MainPageButtonsText__1ZmZu {
    padding-left: 0;
    font-size: 13px;
}

@media screen and (min-width: 700px) {

    .MainPageButtons_MainPageButtonsWrapper__2CQTC {
        z-index: 2;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        width: 160px;
        margin-right: 10px;
    }

    .MainPageButtons_MainPageButtonsSelectionBox__36re8 {
        height: 3rem;
        background-color: #00B1B0;
        border-radius: 5px;
        border: none;
    }

    .MainPageButtons_MainPageButtonsText__1ZmZu {
        padding-left: 8px;
        font-size: 16px;
    }

    .MainPageButtons_DropdownChevron__30Kb- {
        width: 1rem;
        stroke: white;
    }
}
.MainPageButton_MainPageButton__3RKfc button {
    background-color: white;
    padding: 0.3rem .5rem;
    color: #535358;
    display: inline-flex;
    font-size: 1rem;
    align-items: center;
    justify-content: flex-start;
    border: none;
    z-index: 20;
    height: 2.52rem;
    width: 100%;
    border-radius: 6px;
}

.MainPageButton_MainPageButtonActive__2ap8f button {
    background-color: white;
    padding: 0.3rem .5rem;
    color: #4D5055 !important;
    display: inline-flex;
    font-size: 1rem;
    align-items: center;
    justify-content: flex-start;
    border: none;
    z-index: 20;
    fill: white;
    /*height: 2.52rem;*/
    width: 100%;
    cursor: pointer;
    border-radius: 6px;
}

.MainPageButton_ActiveInDropdown__eWcFo button {
    color: #00B1B0 !important;
    background-color: white !important;
    border-radius: 0;
}

.MainPageButton_InDropdown__2Hyvd button {
    border-radius: 0;
}

/*.MainPageButtonActive a {*/
/*    color: white;*/
/*    text-decoration: none;*/
/*}*/

.MainPageButton_MainPageButton__3RKfc a.MainPageButton_active__2gB12 {
    cursor: pointer;
    color: white !important;
    text-decoration: none;
}

.MainPageButton_MainPageButton__3RKfc button:hover {
    background-color: #c4eaf5;
    cursor: pointer;
}

@media screen and (min-width: 700px) {
    .MainPageButton_MainPageButton__3RKfc button {
        padding: 0.3rem 1rem;
        height: 2.7rem;
    }

    .MainPageButton_MainPageButtonActive__2ap8f button {
        padding: 0.3rem 1rem;
        height: 2.7rem;
        width: 100%;
        background-color: #00B1B0;
        color: white !important;
    }
}
.MainPageSubtitle_MainPageSubtitleWrapper__2ytpi {
    /*font-family: "Montserrat", sans-serif;*/
    color: black;
    display: flex;
    flex-flow: column;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    height: 126px;
    padding: 3% 3% 3% 3%;
    box-sizing: border-box;
}

.MainPageSubtitle_MainPageSubtitleWrapper__2ytpi .MainPageSubtitle_Big__289Zs {
    font-weight: bold;
    box-sizing: border-box;
    text-align: left;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    font-size: 24px;
    margin: 0;
    padding: .5rem .5rem;
}

.MainPageSubtitle_AnimatedText__jdFhz {
    min-height: 60px;
    margin: -20px 0 0 -9px;
    font-size: 1rem;
    display: flex;
    align-items: center;
    font-weight: bolder;
    padding: 0.5rem 1rem 0 0.5rem;
}
@media screen and (min-width: 700px) {

    .MainPageSubtitle_MainPageSubtitleWrapper__2ytpi {
        height: 126px;
        width: 500px;
        padding: 0;
    }

    .MainPageSubtitle_MainPageSubtitleWrapper__2ytpi .MainPageSubtitle_Big__289Zs {
        height: 126px;
        font-size: 3rem;
        padding: 0 0 70px 0;
    }

    .MainPageSubtitle_AnimatedText__jdFhz {
        margin-top: -50px;
        font-size: 1.5rem;
        padding: 0;
        width: -webkit-max-content;
        width: max-content;
    }
}
.Toolbar_Toolbar__ApScI {
    width: 100vw;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
    z-index: 30;
    left: 0;
    background-color: white;
}

.Toolbar_ToolbarHolder__3uS8P {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100vw;
    box-sizing: border-box;
}

@media only screen and (min-width: 700px) {
    .Toolbar_Toolbar__ApScI {
        width: 100%;
        height: 70px;
        /*flex-direction: row;*/
        justify-content: space-between;
        align-items: center;
    }

    .Toolbar_ToolbarHolder__3uS8P {
        width: 1366px;
        padding: 0.5rem 2rem 0.5rem;
    }

    .Toolbar_PositionAbsolute__2p4UW {
        position: absolute;
        /*background: linear-gradient(.50turn, white 18%, transparent 100%);*/
    }
}



.Toolbar_PositionFixed__1mQkF {
    position: fixed;
    border-bottom: 1px solid #e2e8ecff;
}

.Toolbar_ToolbarWhite__1EvFH {
    background-color: white;
    /*box-shadow: 0 0 0.8rem #cccccc;*/
}

.Toolbar_ToolbarBlue__qAzZJ {
    background-color: #416CAA;
}

.Toolbar_ToolbarIcon___GUYt {
    padding-right: 1.25rem;
}

.Toolbar_Toolbar__ApScI img {
    /*max-width: 8.125rem;*/
}

.Toolbar_Toolbar__ApScI .Toolbar_SearchBar__2VgtO {
    display: flex;
    align-content: flex-start;
    /*width: 50%;*/
    padding-bottom: 1.25rem;
    /*margin: auto 0 auto 0.625rem;*/
}

.Toolbar_Toolbar__ApScI .Toolbar_SearchBarWrapper__LcnWU {
    width: 100%;
    display: flex;
    max-height: 2.5rem;
}

.Toolbar_SearchBarHidden__3liqo {
    display: none;
}

.Toolbar_ToolbarRightContent__2Ccc3 {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;
}

.Toolbar_SearchBarOnToolbar__3I7UZ {
    padding: 0 1rem;
}

.NavigationItems_NavigationItems__1fnFX {
    list-style: none;
    display: flex;
    align-items: center;
    height: 100%;
    position: relative;
}

.NavigationItems_IconStyle__1i7Lw {
    color: #4a4f54;
    height: 24px;
    width: 24px;
    stroke-width: 1;
    display: flex;
    align-content: center;
    padding-right: 12px;
    padding-left: 6px;
}

.NavigationItems_IconStyle__1i7Lw:hover {
    color: grey;
}

.NavigationItems_UserLoginBox__3nqmo {
    border-radius: 50%;
    color: white;
    font-size: 1rem;
    font-weight: bold;
    height: 32px;
    width: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.NavigationItems_LoginText__3H831 {
    cursor: pointer;
    color: #4a4f54;
    font-size: 1rem;
    text-decoration: underline;
    white-space: nowrap;
}

.NavigationItems_LoginText__3H831:hover {
    color: grey;
}

/*.UserLoginBox:hover {*/
/*    color: #416CAA;*/
/*}*/

.NavigationItems_AddToCartAnimation__2ELHr {
    /*color: #4a4f54;*/
    /*height: 24px;*/
    /*width: 24px;*/
    /*stroke-width: 1;*/
    /*display: flex;*/
    /*align-content: center;*/
    /*padding-right: 0.5rem;*/
    /*padding-left: 0.5rem;*/
    -webkit-animation: NavigationItems_wiggle__2KTPf 200ms 200ms 3;
            animation: NavigationItems_wiggle__2KTPf 200ms 200ms 3;
}

@-webkit-keyframes NavigationItems_wiggle__2KTPf {
    0% {
        -webkit-transform: translateX(0);
                transform: translateX(0);
    }
    50% {
        -webkit-transform: translateX(-4px);
                transform: translateX(-4px);
    }
    100% {
        -webkit-transform: translateX(4px);
                transform: translateX(4px);
    }
}

@keyframes NavigationItems_wiggle__2KTPf {
    0% {
        -webkit-transform: translateX(0);
                transform: translateX(0);
    }
    50% {
        -webkit-transform: translateX(-4px);
                transform: translateX(-4px);
    }
    100% {
        -webkit-transform: translateX(4px);
                transform: translateX(4px);
    }
}

.NavigationItems_MainPageShoppingCartIcon__34FeI {
    position: relative;
    border: 1px solid #4D5055;
    padding: 4px 0;
    border-radius: 6px;
    margin-left: 0.5rem;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    height: 38px;
}

@media screen and (min-width: 700px){
    .NavigationItems_MainPageShoppingCartIcon__34FeI {
        margin-left: 1rem;
    }
}

/*targeting not safari (samo Chrome i Mozzila)*/
/*@supports (contain: paint) {*/
/*    .MainPageShoppingCartIcon {*/
/*        padding: 6px 0;*/
/*    }*/
/*}*/

/*@media not all and (min-resolution:.001dpcm) { @media {*/

/*    .MainPageShoppingCartIcon {*/

/*        padding: 0;*/

/*    }*/
/*}}*/


.NavigationItems_YouHaveActiveCartBalloon__3c8DB {
    top: 35px;
    right: 6px;
    width: 200px;
    height: 55px;
    font-size: 14px;
    border-radius: 8px;
    /*opacity: .8;*/
    cursor: pointer;
}

.NavigationItems_YouHaveActiveCartBalloon__3c8DB::after {
    display: none;
}

.NavigationItems_MainPageShoppingCartIcon__34FeI:hover .NavigationItems_YouHaveActiveCartBalloon__3c8DB {
    visibility: visible;
    opacity: 0.8;
    transition: 600ms ease-out;
}
.NavigationItem_NavigationItem__2SpXc {
    font-size: 16px;
    display: flex;
    justify-content: center;
    box-sizing: border-box;
    align-items: center;
}

.NavigationItem_NavigationItem__2SpXc li {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.NavigationItem_NavigationItem__2SpXc a {
    color: #535358; /* bilo je white */
    text-decoration: none;
    height: 100%;
    padding: 0 0.25rem;
    box-sizing: border-box;
    display: block;

}

.NavigationItem_NavigationItemDark__BsqLJ a {
    color: #535358;
    text-decoration: none;
    height: 100%;
    padding: 0 0.625rem;
    box-sizing: border-box;
    display: block;

}

.NavigationItem_Disabled__2wpox {
    text-decoration: none;
    cursor: default;
    color: #8b8989 !important;
}

/*.NavigationItem a:active,*/
/*.NavigationItem a:hover,*/
/*.NavigationItem a.active {*/
/*    border-bottom: 4px solid #61dafb;*/
/*    color: white;*/
/*}*/

.NavigationItem_NavigationItem__2SpXc .NavigationItem_ItemsInCartCounter__115NZ,
.NavigationItem_NavigationItemDark__BsqLJ .NavigationItem_ItemsInCartCounter__115NZ {
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    /*padding: 6px;*/
    background-color: #00B2B1;
    color: white;
    font-size: 16px;
    font-weight: bold;
    border-radius: 3px;
}
.UserMenu_UserMenu__VvAL6 {
    position: absolute;
    top: 2.5rem;
    right: 21%;
    width: 7rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border: 1px solid #888888;
    background-color: white;
    padding: 0.8em;
    font-size: 1rem;
    border-radius: 10px;
    z-index: 25;

}

.UserMenu_UserMenuOption__188KD {
    cursor: pointer;
    width: 100%;
    display: flex;
    flex-direction: column;
}
.UserMenu_UserMenuOption__188KD a {
    color: #535358;
}

.UserMenu_UserMenuOption__188KD a:visited {
    color: #535358;
}

.UserMenu_UserMenuOption__188KD a:hover {
    color: #416CAA;
}

.UserMenu_UserMenuLink__3GAJU {
    color: #535358;
    display: flex;
    justify-content: center;
    margin: auto;
    text-align: center;
}

.UserMenu_UserMenuLink__3GAJU:hover {
    color: #416CAA;
}

.UserMenu_UserMenuLinkDisabled__30kNS {
    color: grey;
    cursor: auto;
    display: flex;
    justify-content: center;
    margin: auto;
    text-align: center;
}
.TooltipComponent_Tooltip__5h45z {
    visibility: hidden;
    display: flex;
    justify-content: center;
    align-self: center;
    /*width: content-box;*/
    font-size: 0.875rem;
    font-weight: bold;
    background-color: rgb(74,79,84);
    color: white;
    border-radius: 5px 5px 5px 5px;
    padding: 14px 14px;
    box-shadow: 0 0 0.8rem #cccccc;
    box-sizing: border-box;
    width: 14rem;
    z-index: 12;

    /* Position the tooltip */
    position: absolute;
    /*left: 3rem;*/
    /*right: 20%;*/
    /*top: 2.7rem;*/
    /*margin-left: -60px;*/
    align-items: center;
}

.TooltipComponent_Tooltip__5h45z::after {
    content: "";
    position:absolute;
    z-index: 12;

    /* position tooltip correctly */
    /*left:10%;*/
    margin-left:-1px;

    /* vertically center */
    /*bottom: 94%;*/

    /* the arrow */
    border:10px solid;
    border-color: transparent transparent rgb(74,79,84) transparent;

    /*display:none;*/
}

.RFZOButton_RFZOButtonWrapper__3Wu9F {
    display: flex;
    align-items: center;
    border: 1px solid #4D5055;
    border-radius: 6px;
    padding: 4px 6px;
    text-decoration: none;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    color: #4D5055;
    cursor: pointer;
}

.RFZOButton_RFZOMobileButtonWrapper__26LNV {
    display: flex;
    align-items: center;
    border: 1px solid #4D5055;
    border-radius: 50%;
    padding: 5px;
    text-decoration: none;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    color: #4D5055;
    cursor: pointer;
}
.ModalWithHeader_ModalWithHeader__2RElj {
    box-sizing: border-box;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    height: 540px;
    width: 440px;
    background-color: white;
    z-index: 35;
    position: fixed;
    left: 5vw;
    top: 15%;
    box-shadow: 0 0 1.2rem #acaaaa;
    max-width: 90vw;
}

@media screen and (min-width: 700px){
    .ModalWithHeader_ModalWithHeader__2RElj {
        top: 200px;
        left: calc(50% - 220px);
    }
}

.ModalWithHeader_ModalWithHeader__2RElj .ModalWithHeader_Header__2c3vc {
    width: 100%;
    height: 60px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 10px;
    box-sizing: border-box;
    background-color: #00B2B1;
}

/*.ModalWithHeader .CloseButton {*/
/*    height: 32px;*/
/*    width: 32px;*/
/*    border: 1px solid white;*/
/*    border-radius: 50%;*/
/*    box-sizing: border-box;*/
/*    position: absolute;*/
/*    top: 1rem;*/
/*    right: 1rem;*/
/*    margin: 0;*/
/*    padding: 0;*/
/*    background-color: #00B2B1;*/
/*    display: flex;*/
/*    cursor: pointer;*/
/*}*/

.ModalWithHeader_ModalWithHeader__2RElj .ModalWithHeader_CloseIcon__3k8AR {
    width: 32px;
    height: 32px;
    /*margin: auto;*/
    color: white;
    cursor: pointer;
}

/*.ModalWithHeader .HalfCircle {*/
/*    width: 120px;*/
/*    height: 60px;*/
/*    background-color: transparent;*/
/*    border-top-left-radius: 100px;*/
/*    border-top-right-radius: 100px;*/
/*    border: 3px solid #00B2B1;*/
/*    border-bottom: 0;*/
/*    margin: -60px auto 0;*/
/*    position: relative;*/

/*    -webkit-box-sizing: border-box;*/
/*    -moz-box-sizing: border-box;*/
/*    box-sizing: border-box;*/
/*}*/

/*.ModalWithHeader .Circle {*/
/*    height: 100px;*/
/*    width: 100px;*/
/*    border-radius: 50%;*/
/*    background-color: white;*/
/*    margin: 7px auto;*/
/*    border: 1px solid #036e6e;*/
/*    display: flex;*/
/*    align-items: center;*/
/*    justify-content: center;*/
/*    color: #00B2B1;*/
/*}*/

.ModalWithHeader_ModalWithHeader__2RElj .ModalWithHeader_Content__353Iq {
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    padding: 2rem;
    height: calc(100% - 60px);
    /*margin: auto;*/
    justify-content: flex-end;
    color: #5b6977ff;
}

.ModalWithHeader_ModalWithHeader__2RElj .ModalWithHeader_ActionWrapper__15D9i {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: auto;
    width: 100%;
}
.Button_BlueButton__oRAgC {
    background-color: #00B2B1;
    color: white;
    border-radius: 6px;
    font-weight: bold;
    border: none;
    cursor: pointer;
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    /*display: inline-block;*/
    text-align: center;
}

.Button_BlueButton__oRAgC:hover {
    background-color: #16CBCA;
}

.Button_LightBlueButton__1bzTY {
    background-color: #219ebcff;
    color: white;
    border-radius: 6px;
    font-weight: bold;
    border: none;
    cursor: pointer;
    padding: 1rem;
}

.Button_LightBlueButton__1bzTY:hover,
.Button_LightBlueButton__1bzTY:focus {
    background-color: #1c8ba4;
}

.Button_WhiteBlueButton__3kEoi {
    background-color: white ;
    color: #00B2B1;
    border-radius: 6px;
    border: 1px solid #00B2B1;
    cursor: pointer;
    padding: 0.5rem 1rem;
}

.Button_WhiteBlueButton__3kEoi:hover,
.Button_WhiteBlueButton__3kEoi:focus {
    color: #103A5E;
    border: 1px solid #103A5E;
}

.Button_YellowButton__1fPhu {
    background-color: #FFAE00 ;
    color: white;
    border-radius: 6px;
    border: 1px solid #FFAE00;
    cursor: pointer;
    padding: 0.5rem 1rem;
}

.Button_YellowButton__1fPhu:hover,
.Button_YellowButton__1fPhu:focus {
    background-color: #ECA002FF;
    border: 1px solid #ECA002FF;
}

.Button_WhiteDarkBlueButton__3vb1p {
    background-color: white ;
    color: #103A5E;
    border-radius: 3px;
    border: 1px solid #103A5E;
    cursor: pointer;
    padding: 0 2rem;
    height: 37px;
    stroke: #103A5E;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.Button_WhiteDarkBlueButton__3vb1p:hover,
.Button_WhiteDarkBlueButton__3vb1p:focus {
    color: #219ebcff;
    border: 1px solid #219ebcff;
    stroke: #219ebcff;
    fill: #219EBC;
}

.Button_WhiteBrownButton__2auPZ {
    background-color: white ;
    color: #9a877aff;
    border-radius: 6px;
    border: 1px solid #9a877aff;
    cursor: pointer;
    padding: 0 2rem;
    height: 37px;
}

.Button_WhiteBrownButton__2auPZ:hover,
.Button_WhiteBrownButton__2auPZ:focus {
    color: #103A5E;
    border: 1px solid #103A5E;
}

.Button_Link__18alO {
    background-color: transparent;
    color: #5b6977ff;
    text-decoration: underline;
    font-weight: bold;
    border: none;
    cursor: pointer;
    padding: 1rem;
    display: flex;
    align-items: center;
}

.Button_DisabledButton__2S54m {
    background-color: #888888;
    color: white;
    border-radius: 6px;
    font-weight: bold;
    border: none;
    cursor: auto;
    padding: 1rem;
    /*border: 1px solid #416caa;*/
}
.StoreInModal_StoreInModalWrapper__1ABSm {
    display:flex;
    justify-content: space-between;
    border-bottom:1px solid #E2E8EC;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    padding-bottom: 0.5rem;
    position: relative;
    align-items: center;
    margin-right: 1rem;
}

.StoreInModal_StoreInModalWrapper__1ABSm .StoreInModal_SubWrapper__2zRmM {
    width: 90%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.StoreInModal_StoreInModalRadioButtonWrapper__110MM {
    position: absolute;
    right: 0;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    border: 1px solid #00B1B0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.StoreInModal_StoreInModalRadioButton__PWp9N {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    border: 1px solid #00B1B0;
}

.StoreInModal_StoreInModalRadioButtonWrapperSelected__3Rucf {
    border: 1px solid #00B1B0;
}

.StoreInModal_StoreInModalRadioButtonSelected__9aNLP {
    background-color: #00B1B0;
    border: 1px solid #00B1B0;
}
.CNCModal_HeaderText__1t6iF {
    margin: 0;
    font-size: 24px;
}

.CNCModal_ArticleInfoWrapper__pxkU9 {
    border-top: 1px solid #E2E8EC;
    border-bottom: 1px solid #E2E8EC;
    margin-top: 1rem;
}

.CNCModal_ArticleInfoWrapper__pxkU9 .CNCModal_Name__1dI5n {
    font-weight: 600;
}

.CNCModal_Message__2P1tn {
    font-weight: 600;
    font-size: 18px;
}

.CNCModal_BottomActionsWrapper__5nlIj {
    display: flex;
    justify-content: space-between;
    width: 100%;
}
.RFZOTherapyModal_RFZOTherapyModalOnToolbar__bjpa0 {
    height:auto;
    top:10%;
}

.RFZOTherapyModal_RFZOTherapyModalContainer__3TzqX {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
}

.RFZOTherapyModal_RFZOTherapyModalTitle__1UqU6 {
    margin: 8px;
    padding-bottom: 8px;
    width: 95%;
    border-bottom: 1px solid #E2E6EA;
}

@media screen and (max-width:699px) {
    .RFZOTherapyModal_RFZOTherapyModalOnToolbar__bjpa0 {
        /*height: 98vh !important;*/
        top: 10px;
    }
    .RFZOTherapyModal_RFZOTherapyModalContainer__3TzqX {
        overflow-y: auto;
    }
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
}
.LBOInputField_LBOInputField__2CsQE {
    border: 1px solid #e2e8ecff;
    font-size: 18px;
    border-radius: 6px !important;
    height: 2.375rem !important;
    width: 206px !important;
    padding-left: 16px;
}

.LBOInputField_LBOInputField__2CsQE:hover {
    border: 1px solid #00B2B1;
}

.LBOInputField_LBOInputField__2CsQE:focus {
    border: 1px solid #00B2B1;
}

.LBOInputField_LBOLabel__1JXql {
    margin: 0.5rem 0 !important;
    color: #535358;
    font-size: 14px;
}

@media screen and (max-width: 699px) {
    .LBOInputField_LBOLabel__1JXql {
        margin: 0.5rem 0 !important;
    }
}

.LBOInputField_ErrorMessage__3KidW {
    color: red;
    font-size: 12px;
    width: 90%;
    margin-top: 0.2rem;
    height: 12px;
}

.LBOInputField_LBOInputFieldWrapper__eZXBg {
    display: flex;
    flex-direction: column;
    width:100%;
}

.LBOInputField_SaveLBOCheckbox__9ZJgC {
    opacity: 1 !important;
    border: 1px solid grey;
    position: relative !important;
    height: auto !important;
    width: auto !important;
}

.ChooseLocation_ChooseLocationWrapper__36DJU {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 100%;
}

.ChooseLocation_ChosenAddress__2Iup7 {
    color: #00b1b0;
    display: flex;
    align-items: center;
    width:100%;
    justify-content:space-between;
}

.ChooseLocation_RemoveAddress__3w00c {
    cursor: pointer;
    width: 18px;
    color: rgb(255, 174, 0);
}
.GoogleAutocomplete_Input__CDDJG {
    height: 30px;
    width:80%;
    border-bottom: 1px solid grey;
    border-top: none;
    border-left: none;
    border-right: none;
    padding-left: 1rem;
}

.GoogleAutocomplete_Input__CDDJG:hover {
    border-top: none;
    border-left: none;
    border-right: none;
    padding-left: 1rem;
}
.MainPageCounters_MainPageCountersWrapper__1Yq4s {
    /*width: 50%;*/
    /*margin-bottom: 5rem;*/
    box-sizing: border-box;
    background-color: rgba(255, 255, 255, 0.5);
    display: flex;
    flex-direction: row;
    padding-left: 0.5rem;
    box-shadow: 2px 2px 6px rgb(0 0 0 / 10%);
    border-radius: 9px;
}

.MainPageCounters_MainPageCountersWrapper__1Yq4s .MainPageCounters_CounterText__3w36W {
    margin: 0;
    font-weight: 600;
    font-size: 18px;
}

h4,
.MainPageCounters_Numbers__2KqOM {
    width: 100%;
    text-align: left;
    font-weight: 600;
}

.MainPageCounters_Numbers__2KqOM {
    font-size: 18px;
    color: #4D5055;
}

@media screen and (max-width: 699px){

    .MainPageCounters_MainPageCountersWrapper__1Yq4s {
        display: flex;
        justify-content: space-between;
        width: inherit;
        margin: 0 1rem;
        box-sizing: border-box;
        padding: 0 1rem;
    }

    h4{
        font-size: 16px;
        font-weight: 600;
        margin: 0;
    }

    .MainPageCounters_Numbers__2KqOM {
        font-weight: 600;
        font-size: 16px;
    }
}
.MainPageCounter_CounterWrapper__1zyrJ {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    box-sizing: border-box;
    padding: 0.5rem;
    margin: 0;
    width: 130px;
    height: 6.875rem;
    position: relative;
}

.MainPageCounter_ArrowRight__2MZ2p {
    position: absolute;
    width: 24px;
    bottom: 14px;
    right: 8px;
}

@media screen and (max-width: 699px) {
    .MainPageCounter_CounterWrapper__1zyrJ {
        /*box-shadow: 2px 2px 0.5rem #a1a0a06b;*/
        /*border: 1px solid #a1a0a06b;*/
        /*border-radius: 6px;*/
        width: 100px;
        height: 85px;
    }

    .MainPageCounter_ArrowRight__2MZ2p {
        width: 18px;
        bottom: 7px;
        right: 6px;
    }
}

.MainPageCounter_CounterSubtitle__13OGS {
    font-size: 0.875rem;
    text-align: center;
    width: 100%;
}

.MainPageCounter_Clickable__K98dj {
    cursor: pointer;
}

.MainPageCounter_Clickable__K98dj:hover {
    color: #888888;
}
.VerticalLine_VerticalLine__3qp4Z {
    margin: auto;
    border-left: 1px solid #4a4f54;
}
.Background_Background__B0tdY {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: -1;
}
.HederWithImage_HeaderWithImage__1SMf- {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    max-width: 100vw;
    position: relative;
}

.HederWithImage_MobileHeaderImage__1b2j8 {
    /*height: 42vh;*/
    max-width: inherit;
}

.HederWithImage_Children__3WCdY {
    /*position: absolute;*/
    width: 90vw;
}

.HederWithImage_Image__1JEA2 {
    height: inherit;
    max-width: inherit;
}


.MainPage_MainPage__3jgPh {
    display: flex;
    flex-direction: column;
}

.MainPage_BackToTop__3lTIY {
    bottom: 6.25rem;
    margin: 0 50%;
    position: -webkit-sticky;
    position: sticky;
    z-index: 999;
}

.MainPage_BackToTop__3lTIY button {
    border-radius: 3.125rem;
    border: none;
    background-color: #416CAA;
    color: white;
    width: 2.5rem;
    height: 2.5rem;
    font-size: 1.5rem;
}

.MainPage_BackToTop__3lTIY button:hover {
    background-color: rgb(94, 140, 219);
}

.MainPage_SectionThreeWrapper__27bai {
    display: flex;
    flex-direction: row;
}

.MainPage_SectionThreeImageWrapper__Fnlmt {
    margin-top: 2rem;
    border-radius: 0 200px 100px 0;
    background-image: url(/static/media/crop_nurse-2141808_960_720.2238897d.jpg);
    background-repeat: no-repeat;
    background-size: 100%;
    height: 100vh;
    width: 40vw;
    z-index: 10;
}

.MainPage_SectionThreeTextWrapper__3p3rG {
    height: 100vh;
    width: 50%;
    padding-left: 15%;
    box-sizing: border-box;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #535358;
}

.MainPage_SectionAboutUs__20r-I {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 64px;
    position: relative;
    height: 100%;
    box-sizing: border-box;
}
.RateTransactionModal_Rating__38Wcb {
    border: 1px solid #E2E8EC;
    padding: 1rem;
    width: 65%;
    border-radius: 3px;
    margin: auto;
    display: flex;
    justify-content: flex-start;
}
.PlayStoreBoard_PlayStoreBoard__1vw3l {
    background-repeat: no-repeat;
}

.PlayStoreBoard_PlayStoreBoard__1vw3l .PlayStoreBoard_TextPart__CWEsQ {
    display: flex;
    flex-direction: column;
    width: 40%;
    padding: 64px;
}

.PlayStoreBoard_PlayStoreBoard__1vw3l .PlayStoreBoard_TextPart__CWEsQ .PlayStoreBoard_Title__1qyjZ {
    color: #454952;
    font-size: 44px;
}

.PlayStoreBoard_PlayStoreBoard__1vw3l .PlayStoreBoard_TextPart__CWEsQ .PlayStoreBoard_Description__FBNPA {
    font-size: 24px;
    padding-bottom: 32px;
}
.Section_Section__2FiEL {
    background-color: #F8F8F8;
    height: 550px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-radius: 6px;
}

.BulletinBoard_Billboard__3h0_U {
    /*min-height: 400px;*/
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding-top: 4vh;
    width: 75rem; /*1200px*/
    margin: auto;
}

.Bullet_Bullet__10LA7 {
    margin: 1.5rem 0.75rem;
    max-width: -webkit-min-content;
    max-width: min-content;
    background-color: #F8F8F8;
    display: flex;
    flex-direction: column;
    text-align: left;
    height: 460px;
    cursor: pointer;
}

.Bullet_Bullet__10LA7:hover {
    box-shadow: 0 0.3rem 0.3rem 0 rgba(0,0,0,0.10);
}

.Bullet_BulletText__1X3dl {
    color: #535358;
    padding: 0 1.25rem;
    margin: 1rem 0;
    display: flex;
    flex-direction: column;
}

.Bullet_Bullet__10LA7 img {
    width: 21.25rem;
    height: 40%;
}

.Bullet_Bullet__10LA7 p {
    font-size: 0.938rem;
}
.MasterArtikalForm_MasterArtikalForm__2n2xB {
    margin: 1.25rem auto;
    width: 80%;
    text-align: center;
    box-shadow: 0 0.125rem 0.188rem #ccc;
    border: 0.125rem solid #eee;
    padding: 0.625rem;
    box-sizing: border-box;
}
.MasterArtikalForm_ComboBoxOption__1gGsQ{
    padding: 5px;
}

.MasterArtikalForm_Selected__1q40E {
    border-color: #416CAA;

}

.MasterArtikalForm_MasterArtikalForm__2n2xB div {
    display: flex;
    align-items: baseline;
}

.MasterArtikalForm_MasterArtikalForm__2n2xB p {
    width: 12%;
    color: #888888;
    text-align: left;
}

.MasterArtikalForm_MasterArtikalForm__2n2xB input {
    width: 85%;
}

.MasterArtikalForm_MasterArtikalForm__2n2xB textarea {
    width: 85%;
}


.Input_Input__3r5Ke {
    /*width: 100%;*/
    padding: 0.45rem;
    box-sizing: border-box;
}

.Input_Label__1qyHr {
    /*font-weight: bold;*/
    display: block;
    margin-bottom: 0.5rem;
}

.Input_InputElement__2m88K {
    outline: none;
    border: 0.063rem solid #ccc;
    background-color: white;
    font: inherit;
    padding: 0.375rem 0.625rem;
    display: block;
    width: 100%;
    box-sizing: border-box;
}

.Input_InputElement__2m88K:focus {
    outline: none;
    background-color: #ccc;
}

.CMSPage_CMSPage__1-ILq {
    display: flex;
    padding-top: 100px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

/*.CMSButton {*/
/*    background-color: white;*/
/*    color: #4a76af;*/
/*    border: 1px solid #4a76af;*/
/*    border-radius: 8px;*/
/*    padding: 8px 14px;*/
/*}*/

/*.CMSButton:hover {*/
/*    background-color: #4a76af;*/
/*    color: white;*/
/*    border: 1px solid #4a76af;*/
/*}*/

/*.CMSButton:focus {*/
/*    background-color: #4a76af;*/
/*    color: white;*/
/*    border: 1px solid #4a76af;*/
/*}*/

.CMSPage_ErrorMsg__3Z5Re {
    color: #721c24 !important;
    background-color: #f8d7da;
    border-color: #f5c6cb;
    padding: 0.75rem;
    margin: auto;
}

.CMSPage_SuccessMsg__3xU2t {
    color: #155724 !important;
    background-color: #d4edda;
    border-color: #c3e6cb;
    border-radius: 5px;
    padding: 0.75rem;
    margin: 1rem auto;
}

.CMSPage_ActionButtonsHolder__3zM8E {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.MasterArtikalCMS_MasterArtikalCMS__3aBRx {
    width: 100%;
    padding-bottom: 3.125rem;
    margin-top: 6.25rem;
    background-color: white;
}

.ProdajnoMestoCMS_ProdajnoMestoCMS__1eZMk {
    width: 94vw;
    background-color: white;
    display: flex;
    padding: 3vh 3vw 0.625rem;
}

.ProdajnoMestoCMS_Right__1bTpr{
    /*flex-grow: 1;*/
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    position: -webkit-sticky;
    position: sticky;
    align-self: flex-start;
    top: 0;
    max-width: 55vw;
}

.ProdajnoMestoCMS_Updated__nwH_7 {
    background-color: #E0FFFF;
}

.ProdajnoMestoCMS_Selected__1Ci7P {
    background-color: #0F9D58;
}

.ProdajnoMestoCMS_Left__2G_9V {
    width: 42vw;
}

.ProdajnoMestoCMS_Left__2G_9V div {
    border: 0.125rem solid #8b8989;
    padding-left: 0.5rem;
}

.ProdajnoMestoCMS_ProdajnoMestoCMS__1eZMk p {
    font-size: 0.875rem;
}

.ProdajnoMestoCMS_ProdajnoMestoCMS__1eZMk .ProdajnoMestoCMS_NameOfCurrent__OMGN2 {
    color: #0F9D58;
    font-size: 1.563rem;
    margin: 0.5rem;
}

.ProdajnoMestoCMS_ImagesUploadHolder__3cyio {
    display: flex;

}
.ProdajnoMestoCMS_ImageUploader__1hjUr {
    margin: 1rem;
    box-shadow: 0 0.125rem 0.188rem #ccc;
    border: 0.063rem solid #eee;
}


.ProdajnoMestoForm_ProdajnoMestoForm__1GNff {
    width: 97%;
    text-align: center;
    box-shadow: 0 0.125rem 0.188rem #ccc;
    border: 0.063rem solid #eee;
    padding: 0.625rem;
    box-sizing: border-box;
    overflow-y: scroll;
    height: 55vh;
}

.ProdajnoMestoForm_ProdajnoMestoForm__1GNff div {
    display: flex;
    align-items: baseline;
}

.ProdajnoMestoForm_ProdajnoMestoForm__1GNff p {
    color: #888888;
    width: 30%;
    text-align: left;
}

.ProdajnoMestoRadnoVremeForm_ProdajnoMestoForm__3DSWr {
    position: fixed;
    top: 15%;
    left: calc(50% - 160px);
    padding: 2rem;
    background-color: white;
    border: 1px solid #416CAA;
}

.ProdajnoMestoRadnoVremeForm_ProdajnoMestoRadnoVreme__2ZvdR input {
    padding: 2px;
    margin: 0 0 2px;
}

.ProdajnoMestoRadnoVremeForm_ProdajnoMestoRadnoVreme__2ZvdR Input {
    margin: 0;
}

.ProdajnoMestoRadnoVremeForm_ProdajnoMestoRadnoVreme__2ZvdR p {
    font-size: 14px;
}

.ProdajnoMestoRadnoVremeForm_ProdajnoMestoRadnoVreme__2ZvdR .ProdajnoMestoRadnoVremeForm_XButton__3SeMG {
    float: right;
    width: 24px;
    height: 24px;
}

.ProdajnoMestoRadnoVremeForm_ProdajnoMestoRadnoVreme__2ZvdR .ProdajnoMestoRadnoVremeForm_XButton__3SeMG:hover {
    color: #416CAA;
}
.Modal_Modal__1IRS4 {
    position: fixed;
    z-index: 500;
    background-color: white;
    /*width: 70%;*/
    border: 1px solid #e2e8ecff;
    padding: 16px;
    left: 15%;
    box-sizing: border-box;
    transition: all 0.3s ease-out;
    border-radius: 6px;
}

@media screen and (min-width: 700px){
    .Modal_Modal__1IRS4 {
        padding: 0;
        /*top: 30%;*/
    }

    .Modal_ModalHeader__1mOcs {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        background-color: #00B1B0;
        height: 60px;
        padding-right: 1rem;
    }

}
.ArticleInfoPage_ArticleInfoPageContainer__14dTi {
    display: flex;
    justify-content: center;
    width: 90vw;
    margin: auto;
}
@media screen and (min-width: 700px){
    .ArticleInfoPage_ArticleInfoPageContainer__14dTi {
        width: 100%;
    }
}

.ArticleInfoPage_ArticleInfoPageContentWrapper__3Tlyu {
    margin-top: 7rem;
    /*width: 75rem;*/
    width: 1366px;
}

.ArticleInfoPage_PictureAndGeneralInfoBox__uHM2A  {
    /*height: 100%;*/
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    box-shadow: 0 0 0.8rem #cccccc;
    /*width: 100%;*/
    margin: 32px 32px 32px 32px;
    width: auto;
    height: 420px;
    /*border: 6px solid #ffffff;*/
    background-color: white;
    position: relative;
}

.ArticleInfoPage_PictureAndGeneralInfoBox__uHM2A .ArticleInfoPage_ArticleInfoPageArticleTextBox__2PedI {
    width: 50%;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    flex-flow: wrap;
    align-content: space-between;
    background-color: white;
}

.ArticleInfoPage_ArticleCategoryBreadcrumbs__2oPJJ {
    font-size: small;
    margin-top: 16px;
}

.ArticleInfoPage_TabHolder___A3im {
    background-color: #fff;
    height: 4.375rem;
    position: relative;
    justify-content: flex-start;
    border-radius: 6px;
    box-shadow: 0 8px 0.8rem -12px #ccc;
    margin: 0 32px;
    display: flex;
    flex-direction: row;
    align-items: center;

    color: #4A4F54;
    padding: 0 1rem;
}

.ArticleInfoPage_TabList__p6BDU {
    border: none !important;
}

.ArticleInfoPage_ActiveTab__2PKaI {
    color:#00B2B1;
    border: none !important;
    box-shadow: none;
}

.ArticleInfoPage_ActiveTab__2PKaI:focus {
    box-shadow: none !important;
}

.ArticleInfoPage_BuyingActions__jBOow {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: -webkit-max-content;
    height: max-content;
}

@media screen and (min-width: 700px) {
    .ArticleInfoPage_BuyingActions__jBOow {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: -webkit-max-content;
        height: max-content;
        border-top: 1px solid #E2EBEC;
        padding-top: 1rem;
    }
    .ArticleInfoPage_ArticleCategoryBreadcrumbs__2oPJJ {
        margin-left: 32px;
        font-size: medium;
        font-size: initial;
    }
}

.ArticleInfoPage_ArticlePriceInfo__39vOv {
    height: 70px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
    width: 100%;
}

.ArticleInfoPage_Amount__3Jx-_ {
    color: rgb(1, 79, 134);
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: end;
}

.ArticleInfoPage_QuantityInCart__1-qD3 {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: -11px;
    right: -11px;
    border-radius: 50%;
    background-color: #103A5E;
    color: white;
    width: 24px;
    height: 24px;
}

.ArticleInfoPage_CheckAvailability__35v0i {
    display: flex;
    justify-content: flex-end;
    padding: 1rem 1rem 0 0;
}

.ArticleInfoPage_CheckAvailability__35v0i p {
    color: #219EBC;
    cursor: pointer;
    text-decoration: underline;
}

.ArticleInfoPage_ArticleInfoPageMobileWrapper__h8077 {
    width: 90vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: .5rem;
}
.ArticleInfoPage_OriginalPrice__2akU7 {
    display: flex;
    color: #88959E;
    font-size: 16px;
    text-decoration: line-through;
    justify-content: center;
}

.ArticleInfoPage_ButtonWrapper__1tHtw {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.ArticleInfoPage_SqBox__1UwJv {
    position: relative;
}

.ArticleInfoPage_TooltipText__11_ll {
    position: absolute;
    visibility: hidden;
    right: -20px;
    top: 38px;
}

.ArticleInfoPage_TooltipText__11_ll::after {
    right:10%;
    bottom: 98%;
}

.ArticleInfoPage_SqBox__1UwJv:hover .ArticleInfoPage_TooltipText__11_ll,
.ArticleInfoPage_SqBox__1UwJv:hover .ArticleInfoPage_TooltipText__11_ll:after
{
    visibility: visible;
    opacity: 1;
    transition: 600ms ease-out;
}
.ArticleInfoLek_ArticleInfoPageWrapper__gYuj0 {
    width: 75rem;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 auto;
}

.ArticleInfoLek_ArticleInfoName__18ApD {
    width: 100%;
    color: #416CAA;
    display: flex;
    justify-content: flex-start;
    font-size: 1.5rem;
}

.ArticleInfoLek_BasicInfoContainer__3vKg6 {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    box-shadow: 0 0 0.8rem #cccccc;
    margin-top: 1.5rem;
}

.ArticleInfoLek_BasicInfoLeftSide__1rhu0 {
    /*width: 24.5%;*/
    width: 27%;
    border: 6px solid white;
    border-radius: 6px 0 0 6px;
    background: white;
    margin-right: 2px;
    padding: 0 2rem;
}

.ArticleInfoLek_BasicInfoRightSide__1uz4m {
    /*width: 74.5%;*/
    width: 73%;
    border: 4px solid white;
    border-radius: 0 6px 6px 0;
    background: white;
    margin-left: 2px;
    padding: 0 2rem;
    color: #4a4f54;
}

.ArticleInfoLek_ParagraphTitleWrapper__1Z64h {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid #E2E8EC;
    padding: 0.5rem 0;
    cursor: pointer;
}

.ArticleInfoLek_ParagraphTitleWrapper__1Z64h:hover {
    color: #00B2B1 !important;
}

@media screen and (min-width: 700px){

    .ArticleInfoLek_BasicInfoContainer__3vKg6 {
        width: auto;
        margin: 0 32px;
        background: #F3F6F8;
        height: 465px;
    }

    .ArticleInfoLek_ParagraphTitleWrapper__1Z64h {
        padding: 0;
    }
}

.ArticleInfoLek_ParagraphTitle__1LpQM {
    padding: 0.5rem 0.5rem;
    /*font-size: 1.1rem;*/
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.ArticleInfoLek_ParagraphTitleActive__3xGNH {
    color: #00B2B1;
    padding: 0.5rem 0.5rem;
    /*font-size: 1.1rem;*/
    /*font-weight: bold;*/
    display: flex;
    justify-content: flex-start;
    align-items: center;
}




.ArticleImageHolder_ArticleInfoPictureHolder__3Ck_H {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    border: 6px solid #ffffff;
    border-radius: 6px 0 0 6px;
    padding: 12px 0 0;
    box-sizing: border-box;
    width: 65%;
    height: 100%;
    position: relative;
}

.ArticleImageHolder_ArticleImageWrapper__xmCYb {
    width:100%;
    height:80%;
    position: relative;
}

.ArticleImageHolder_ArticleImage__V2c_i {
    object-fit: contain;
    width:inherit;
    height:inherit;
}

.ArticleImageHolder_BackWrapper__tiYGG {
    display: none;
}

/*treba da ostane cak i ako je prazno jer ovako gazi predefinisanu klasu u samoj ItemAvailability komponenti*/
.ArticleImageHolder_ItemAvailability__2bl-- {

}

.ArticleImageHolder_BadgesWrapper__v7Unx {
    display: flex;
    justify-content: space-between;
    position: absolute;
    align-items: center;
    z-index: 10;
    top: 0;
    width: 100%;
    box-sizing: border-box;
}

@media screen and (min-width: 700px) {
    .ArticleImageHolder_ArticleInfoPictureHolder__3Ck_H {
        padding: 1rem;
        width: 36%;
    }

    .ArticleImageHolder_BackWrapper__tiYGG {
        cursor: pointer;
        position: absolute;
        top: 0;
        left: 0;
        margin:12px;
        display: flex;
        align-items: center;
        font-size: 20px;
    }

    .ArticleImageHolder_BadgesWrapper__v7Unx {
        padding: 1rem;
    }
}
.ArticleItem_ArticleItem__1ZLdl {
    /*box-shadow: 0 0 0.8rem #cccccc;*/
    /*box-shadow: rgb(239, 239, 239) 0.313rem 0.313rem 1.313rem 0.375rem;*/
    background-color: white;
    background-blend-mode: overlay;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    /*align-items: center;*/
    margin-bottom: 30px;
    padding: 0.2rem 0.3rem 1rem 0.3rem;
    border: 1px solid #e2e8ecff;
    position: relative;
    border-radius: 6px;
    width: 43vw;
    margin-right: 0.75rem;
    margin-left: 0.75rem;
}

.ArticleItem_SearchedArticle__5LeaL {
    /*border: 2px solid #88959eff;*/  /* ff na kraju verovatno greskom dodato - treba #88959e */
    border: 2px solid #abb4bb;  /* postavljena malo svetlija nijansa, na zahtev Ferenca */
}

.ArticleItem_ArticleItem__1ZLdl:hover{
    /*border: 1px solid #adadad;*/
    box-shadow: 0 0 1.2rem #acaaaa;
    transition: 400ms ease-out;
}

/*.ArticleItem:hover {*/
/*    !*border: 1px solid #416CAA;*!*/
/*    !*border: 2px solid rgba(65, 108, 170, 0.5);*!*/
/*    background-color: #faf9f1;*/
/*    transition: 400ms ease-out;    */
/*}*/

.ArticleItem_Selected__1Tk4z {
    background-color: #f5f5f6;
    /*border: 1px solid #416CAA;*/
    box-shadow: 0 0 1.2rem #898787;
}

.ArticleItem_ItemPicture__2kvoD {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
}

.ArticleItem_ItemPicture__2kvoD img {
    max-height: 100%;
    max-width: 100%;
}

.ArticleItem_ArticleNameWrapper__1AD7p {
    height: 3.58rem;
    width: 100%;
    margin: 0 auto;
}


.ArticleItem_ArticleItem__1ZLdl .ArticleItem_ArticleName__1qVJn {
    color: black;
    font-size: 12px;
    margin-bottom: 0.5rem;
    text-align: left;
    padding: 0 0.5rem 0;
    /*cursor: pointer;*/
}

/*.StoreItemListView h2 {*/
/*    !*color:  #416CAA;*!*/
/*    color: black;*/
/*    font-size: large;*/
/*    !*font-size: 1.125rem;*!*/
/*    white-space: nowrap;*/
/*    width: 17rem;*/
/*}*/

.ArticleItem_ArticleItem__1ZLdl .ArticleItem_ItemPrice__3OWAo {
    color: #121010;
    font-size: 14px;
    margin: 0;
}

.ArticleItem_ArticleItem__1ZLdl .ArticleItem_OriginalPrice__3DZia {
    color: #88959E;
    text-decoration: line-through;
    font-size: 14px;
    margin: 0;
    padding-left: 8px;
}

.ArticleItem_ArticleItem__1ZLdl .ArticleItem_RsdSpan__3m6Pm {
    font-size: 12px;
    margin: 0
}

.ArticleItem_ArticleItemPriceRegulativa__3srPl {
    font-size: 12px;
    color: #4D5055;
}

.ArticleItem_BottomLineWrapper__1a5Ni {
    width: 82%;
    display: flex;
    flex-direction: row;
    align-items: center;
    bottom: 1rem;
    position: absolute;
    justify-content: center;
    height: 2rem;
    border-top: 1px solid lightgray;
    padding-top: 1rem;
}

.ArticleItem_ArticleItem__1ZLdl .ArticleItem_MoreDetails__GxyOm {
    /*font-size: 12px;*/
    display: none;
}

.ArticleItem_StoreItemListView__raeGb p {
    color: #535358;
    font-size: 1rem;
}

.ArticleItem_StoreItem__1_vUu .ArticleItem_StoreItemGridView__2rGIQ .ArticleItem_DisplaySpaceBetweenGridView__XYlOo {
    display: flex;
    vertical-align: center;
}

.ArticleItem_StoreItem__1_vUu .ArticleItem_StoreItemListView__raeGb .ArticleItem_DisplaySpaceBetweenListView__2ppJ4 {
    /*display: flex;*/
    /*flex-direction: row;    */
    /*align-items: center;*/
}


.ArticleItem_AdditionalInfo__oD9P3 {
    width: 100%;
    box-sizing: border-box;
    padding: 0 0.5rem;
    margin-top: auto;
}

.ArticleItem_AdditionalInfoListView__2iCwe {
    width: 15rem;
    /*position: relative;*/
    display: flex;
    flex-direction: row;
    /*justify-content: flex-end;*/
    justify-content: space-between;
    align-items: center;
}

.ArticleItem_LocationAndAddress__3zDsV {
    width: 25rem;
}

.ArticleItem_ListViewPhoneNumber__341YU {
    width: 12rem;
}

/*.AdditionalInfo .OpenClose {*/
/*    display: flex;*/
/*    justify-content: flex-start;*/
/*}*/

/*.AdditionalInfo .Stanje {*/
/*    display: flex;*/
/*    justify-content: flex-end;*/
/*}*/


.ArticleItem_AdditionalInfoGridView__2AFf3 .ArticleItem_Access__1o9Hc,
.ArticleItem_AdditionalInfoListView__2iCwe .ArticleItem_Access__1o9Hc {
    color: #008D36;
    /*font-size: 0.875rem;*/
    /*margin-top: 2px;*/


    /*margin: 8px 0;*/
}

.ArticleItem_AdditionalInfo__oD9P3 .ArticleItem_Denied__1goTB {
    color: #E53132;
    /*font-size: 0.875rem;*/
    /*margin-top: 2px;*/
}

/*.AdditionalInfo .VerticalLine {*/
/*    color: #008D36;*/
/*    margin-left: 0.313rem;*/
/*    margin-right: 0.313rem;*/
/*    font-weight: bold;*/
/*}*/

.ArticleItem_StoreItemListView__raeGb i,
.ArticleItem_StoreItemGridView__2rGIQ i{
    line-height: 0.4;
}

.ArticleItem_StoreItemGridView__2rGIQ:hover .ArticleItem_TooltipText__3OaHD {
    visibility: visible;
    opacity: 1;
    transition: 600ms ease-out;
}

.ArticleItem_IconStyle__3Kie7 {
    /*fill: white;*/
    height: 16px;
    width: 16px;
    stroke-width: 1;
    padding-right: 0.25rem;
    /*margin: auto;*/
}

.ArticleItem_ArticleItem__1ZLdl .ArticleItem_BottomHolder__A23XR {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    padding-bottom: 1rem;
    margin-bottom: 1rem;
}

.ArticleItem_ArticleInfoIcon__3Xk8D:hover {
    cursor: pointer;
}

.ArticleItem_ArticleItem__1ZLdl  .ArticleItem_PriceButton__m5qGU {
    color: #416CAA;
    font-size: 1.125rem;
    min-height: 3.58rem;
    text-decoration: underline;
    cursor: pointer;
}

.ArticleItem_ItemAvailability__34g0F {
    position: absolute;
    left: 14px;
    top: 14px;
}

.ArticleItem_LoyaltyPointsIcon__2BTlF {
    position: absolute;
    top: 10px;
    right: 10px;
    display: flex;
}


@media screen and (min-width: 700px) {
    .ArticleItem_ArticleItem__1ZLdl {
        width: 18.938rem;
        height: 408px;
        margin-right: 30px;
        margin-left: 0;
        padding: 1rem 1.3rem 1rem 1.3rem;
    }

    .ArticleItem_ItemPicture__2kvoD {
        height: 13rem;
        padding: 1rem;
    }

    .ArticleItem_ArticleItem__1ZLdl .ArticleItem_ArticleName__1qVJn {
        font-size: 16px;
        font-weight: 700;
    }

    .ArticleItem_ArticleItem__1ZLdl .ArticleItem_ItemPrice__3OWAo {
        font-size: 1.125rem;
    }

    .ArticleItem_ArticleItemPriceRegulativa__3srPl {
        font-size: 16px;
    }

    .ArticleItem_BottomLineWrapper__1a5Ni {
        justify-content: flex-start;
    }

    .ArticleItem_ArticleItem__1ZLdl .ArticleItem_MoreDetails__GxyOm {
        cursor: pointer;
        font-size: 16px;
        color: #219EBC;
    }
    .ArticleItem_ArticleItem__1ZLdl .ArticleItem_RsdSpan__3m6Pm {
        font-size: 16px;
        margin: 0
    }

    .ArticleItem_ArticleItem__1ZLdl .ArticleItem_LoyaltyPointsIcon__2BTlF {
        top: 14px;
        right: 14px;
    }
}

.ArticleItem_ItemAvailabilityTooltipText__3bhtP {
    /* Position the tooltip */
    left: -11rem;
    top: 2.2rem;
}

.ArticleItem_ItemAvailabilityTooltipText__3bhtP::after {
    /* position tooltip correctly */
    left:80%;

    /* vertically center */
    bottom: 94%;
}

.ArticleItem_ItemAvailability__34g0F:hover .ArticleItem_ItemAvailabilityTooltipText__3bhtP,
.ArticleItem_ItemAvailability__34g0F:hover .ArticleItem_ItemAvailabilityTooltipText__3bhtP:after
{
    visibility: visible;
    opacity: 1;
    transition: 600ms ease-out;
}


.ArticleItem_PriceAndDetailsHolder__1KTKH {
    /*position: absolute;*/
    /*bottom: 3rem;*/
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 24px;
    /*border-bottom: 1px solid #e2e8ecff;*/
}

.ArticleItem_RpBadge__2n-IV {
    position: absolute;
    right: 0;
    top: 0;
    width: 0;
    height: 0;
    border-top: 60px solid transparent;
    border-bottom: 60px solid transparent;
    border-left: 60px solid #fc6d6dff;
    /*transform: rotate(-45deg);*/
}

.ArticleItem_QuantityInCart__ymfK1 {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: -11px;
    right: -11px;
    border-radius: 50%;
    background-color: #219EBC;
    color: white;
    width: 22px;
    height: 22px;
}

.ArticleItem_SqBox__2waLd {
    position: relative;
}

.ArticleItem_TooltipText__3OaHD {
    position: absolute;
    visibility: hidden;
    right: -20px;
    /*left: -10.65rem;*/
    top: 38px;
}

.ArticleItem_TooltipText__3OaHD::after {
    /* position tooltip correctly */
    right:10%;
    /* vertically center */
    bottom: 98%;
}

.ArticleItem_SqBox__2waLd:hover .ArticleItem_TooltipText__3OaHD,
.ArticleItem_SqBox__2waLd:hover .ArticleItem_TooltipText__3OaHD:after
{
    visibility: visible;
    opacity: 1;
    transition: 600ms ease-out;
}
.SquareBadge_SquareBadge__1spiU {
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    cursor: default;
    border-radius: 3px;
    width: 26px;
    height: 26px;
}
.LoyaltyBadge_LoyaltyBadge__xgvN_ {
    display: flex;
    justify-content: flex-end;
    align-items: baseline;
    box-sizing: border-box;
    border-radius: 6px;
    border: 2px solid #FFAE00;
    position: relative;
    padding: 2px 4px;
    background-color: #121010;
    width: 50px;
    height: 25px;
    cursor: pointer;
}

.LoyaltyBadge_LoyaltyBadge__xgvN_ .LoyaltyBadge_Number__2YVU9 {
    font-family: "DigitalFont", sans-serif;
    color: white;
    font-size: 18px;
    font-weight: bold;
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.LoyaltyBadge_TooltipWrapper__3Oel6 {
    position: relative;
}

.LoyaltyBadge_TooltipText__28hw5 {
    /* Position the tooltip */
    right: -15px;
    top: 2.3rem;
}

.LoyaltyBadge_TooltipText__28hw5::after {
    /* position tooltip correctly */
    right:10%;
    /* vertically center */
    bottom: 98%;
}

.LoyaltyBadge_LoyaltyBadge__xgvN_:hover .LoyaltyBadge_TooltipText__28hw5,
.LoyaltyBadge_LoyaltyBadge__xgvN_:hover .LoyaltyBadge_TooltipText__28hw5:after
{
    visibility: visible;
    opacity: 1;
    transition: 600ms ease-out;
}

@media screen and (min-width: 700px){
    .LoyaltyBadge_LoyaltyBadge__xgvN_ {
        /*border-radius: 6px;*/
        /*height: 95px;*/
        width: 60px;
        height: 30px;
    }

    .LoyaltyBadge_LoyaltyBadge__xgvN_ .LoyaltyBadge_Number__2YVU9 {
        font-size: 20px;
    }
}

.LoyaltyBadge_TooltipModal__1_k3L {
    width: 90vw;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
}
.ArticleDetailsHolder_ArticleInfoPageArticleTextBox__20qhb {
    /*width: 470px;*/
    width:36%;
    /*height: 450px;*/
    height: 100%;
    padding: 2rem;
    box-sizing: border-box;
    display: flex;
    flex-flow: wrap;
    align-content: space-between;
    background-color: white;
    margin: 0 12px;

    color: #4a4f54;
}

.ArticleDetailsHolder_ArticleInfoPageArticleTextBox__20qhb p {
    font-size: 1rem;
}
.ArticleActionsHolder_ArticleActionsHolder__3m22J {
    /*width: 50%;*/
    width: 23%;
    height: 100%;
    box-sizing: border-box;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: white;
    /*border: 6px solid #ffffff;*/
    border-radius: 0 6px 6px 0;
    color: #88959E;
}
.ArticleAction_ArticleAction__1P31P {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.ArticleAction_TooltipWrapper__e75ST {
    position: relative;
}

.ArticleAction_TooltipText__3KvLU {
    /* Position the tooltip */
    left: 3rem;
    top: 2.7rem;
}

.ArticleAction_TooltipText__3KvLU::after {
    /* position tooltip correctly */
    left:10%;
    /* vertically center */
    bottom: 94%;
}

.ArticleAction_TooltipWrapper__e75ST:hover .ArticleAction_TooltipText__3KvLU,
.ArticleAction_TooltipWrapper__e75ST:hover .ArticleAction_TooltipText__3KvLU:after
{
    visibility: visible;
    opacity: 1;
    transition: 600ms ease-out;
}
.TherapyForModal_TherapyForModal__3VXq8 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 90%;
    height: 2.875rem;
    border: 1px solid #4D5055;
    border-radius: 6px;
    padding-left: 1rem;
    margin-bottom: 1rem;
    box-sizing: border-box;
}

.TherapyForModal_TherapyForModalClickable__2SrLg {
    cursor: pointer;
}

.TherapyForModal_TherapyForModalDisabled__3g2pG {
    border: 1px solid lightgrey !important;
}
.TherapiesForModal_NewTherapyBox__10pqj {
    border-top: 1px solid lightgrey;
    box-sizing: border-box;
}

.TherapiesForModal_TherapyList__1RhSL {
    width: 100%;
    margin: 1rem 0;
    box-sizing: border-box;
}

.TherapiesForModal_NewTherapyBox__10pqj .TherapiesForModal_TherapyNameInputField__1Q6r2 {
    width: 90%;
    height: 2.875rem;
    border: 1px solid #4D5055;
    border-radius: 6px;
    padding-left: 1rem;
    box-sizing: border-box;
}
.LiveChatButton_LiveChatButtonContainer__3r8TS {
    position: fixed;
    display: flex;
    justify-content: space-between;
    bottom: 40px;
    right: 40px;
    overflow: hidden;
    z-index: 23;
}

.LiveChatButton_LiveChatButton__32czH {
    width: 80px;
    height: 80px;
    border-radius: 20px;
    background-color: #00B2B1;
    position: fixed;
    bottom: 40px;
    right: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 23;
    cursor: pointer;
}

@media screen and (max-width: 699px){
    .LiveChatButton_LiveChatButton__32czH {
        display: none;
    }
    .LiveChatButton_TextStripe__12ia7 {
        display: none !important;
    }
}

.LiveChatButton_TextStripe__12ia7 {
    order:-1;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background-color: #fbfbfb;
    white-space:nowrap; /*Keep text always one line*/
    overflow:hidden;
    width:0;
    height:80px;
    bottom: 40px;
    margin-right: 40px;
    padding-left: 22px;
    border-radius: 20px;
    transition: width 1s;
}

.LiveChatButton_LiveChatButton__32czH:hover + .LiveChatButton_TextStripe__12ia7 {
    width: 386px;
}
.LiveChatModal_ContentWrapper__Mha1x {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
}

.LiveChatModal_ContentWrapper__Mha1x .LiveChatModal_Title__1gasV {
    margin: 0;
    font-size: 20px;
    font-weight: bold;
    text-align: center;
}

.LiveChatModal_ContentWrapper__Mha1x .LiveChatModal_Text__1F7Fh {
    text-align: center;
    font-size: 14px;
    line-height: 1.7;
}

.HeaderWithTitleAndBackChevron_CartHeader__3HP-h {
    padding: 8px 24px;
    border-bottom: 1px solid #e2e8ecff;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 45px;
    box-sizing: border-box;
    align-items: center;
}

.HeaderWithTitleAndBackChevron_CartHeader__3HP-h p {
    font-size: 18px;
    font-weight: bold;
    color: #4a4f54ff
}

.HeaderWithTitleAndBackChevron_IconStyle__jGAI3 {
    color: #4a4f54;
    height: 24px;
    width: 24px;
    stroke-width: 1;
    display: flex;
    align-content: center;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
}

.RelatedArticles_CarouselContainer__K_WOU {
    margin: 16px auto;
    width: 1302px;
    display: flex;
    border-left: 1px solid #ebebeb;
    border-right: 1px solid #ebebeb;
}

.RelatedArticles_Slider__1nTSQ {
    width: 100%;
}

.RelatedArticles_CarouselContainer__K_WOU .RelatedArticles_ButtonLeft__zdUH7, .RelatedArticles_CarouselContainer__K_WOU .RelatedArticles_ButtonRight__23VIk {
    background-color: transparent ;
    border: none;
}


.RelatedArticles_CarouselContainer__K_WOU .RelatedArticles_ButtonLeft__zdUH7:hover, .RelatedArticles_CarouselContainer__K_WOU .RelatedArticles_ButtonRight__23VIk:hover {
    color: #888888;
    font-weight: bold;
    background-color: #ebebeb;
    transition: all 200ms ease-in-out;
}

.RelatedArticles_CarouselContainer__K_WOU .RelatedArticles_ButtonLeft__zdUH7:active, .RelatedArticles_CarouselContainer__K_WOU .RelatedArticles_ButtonRight__23VIk:active {
    color: #888888;
    font-weight: bold;
    background-color: #eeee;
    transition: all 200ms ease-in-out;

}

.RelatedArticles_CarouselContainer__K_WOU .RelatedArticles_slide__lekMx {
    display: flex;
    justify-content: space-between;
}

.RelatedArticles_CarouselContainer__K_WOU  .RelatedArticles_Arrows__95Er7 {
    color: #d0d0d0;
    width: 24px;
    height: 24px;
}

.RelatedArticles_CarouselContainer__K_WOU  .RelatedArticles_Arrows__95Er7:hover {
    color: #888888;
    font-weight: bold;
    transition: all 200ms ease-in-out;
}

.RelatedArticles_CarouselContainer__K_WOU .RelatedArticles_Arrows__95Er7:active {
    color: #416CAA;
    font-size: 18px;
    font-weight: bold;
    transition: all 200ms ease-in-out;
}


.AuthPage_AuthPageHolder__d-Na7 {
    height: 100vh;
    width: 100vw;
    display: flex;
    background-color: white;
}

.AuthPage_AuthPageContainer__3OlOv {
    display: flex;
    z-index: 2;
    background-color: white;
    position: relative;
    width: 100%;
}

.AuthPage_AuthPageContent__3iRw6 {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    box-sizing: border-box;
    padding: 1rem 10% 0;
    z-index: 2;
    background-color: white;
    width: 100%;
}

.AuthPage_AuthPageContent__3iRw6 .AuthPage_Form__2FsCL {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 10px;
    width: 100%;
}

.AuthPage_AuthPageContent__3iRw6 .AuthPage_Form__2FsCL input {
    width: 90%;
    margin-top: 0.85rem;
    border: 1px solid #e5e5e5;
    border-radius: 3px;
    padding: 2%;
    font-size: 14px
}

.AuthPage_ErrorMessage__3iCQ4 {
    color: red;
    font-size: 12px;
    width: 90%;
    margin-top: 0.2rem;
}

.AuthPage_AuthPageLogo__197g7 {
    cursor: pointer;
    width: 250px;
    height: auto;
}

.AuthPage_GoogleButton__1b97H {
    /*background-color: red !important;*/
    color: #4a4f54 !important;
    border-radius: 6px !important;
    box-shadow: none !important;
    font-family: 'Lexend', sans-serif !important;
    border: 1px solid #e2e8ecff !important;
    padding: 3px 6px !important;
    width: 100%;
    margin-top: 1.5rem;
}

@media screen and (min-width: 700px){
    .AuthPage_AuthPageLogo__197g7 {
        /*width: auto;*/
    }
    .AuthPage_GoogleButton__1b97H {
        width: auto;
    }

    .AuthPage_AuthPageContent__3iRw6 {
        padding: 7rem 25% 0;
    }
}

.AuthPage_TopContainer__2h5no {
    /*height: 250px;*/
    display: flex;
    flex-direction: column;
    padding: 5rem 1.8rem 1rem;
    align-items: center;
}

.AuthPage_HeaderContainer__2Q2tF {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.AuthPage_HeaderText__19JKm {
    font-size: 30px;
    font-weight: 600;
    line-height: 1.24;
    color: #4a4f54ff;
    z-index: 10;
    margin: 0;
}

.AuthPage_SmallText__1GuGZ {
    color: #4a4f54ff;
    font-weight: 500;
    font-size: 12px;
    z-index: 10;
    margin: 8px 0 0;
}

.AuthPage_Links__zyo_O {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 12px;
    justify-content: center;
}

.AuthPage_Links__zyo_O p {
    font-size: 14px;
    color: #535358;
}

.AuthPage_Links__zyo_O a {
    color: #416caa;
    cursor: pointer;
}

.AuthPage_Links__zyo_O a:hover {
    text-decoration: underline;
}

/*.ConfirmButton {*/
/*    background-color: #416caa;*/
/*    color: white;*/
/*    padding: 12px 30px;*/
/*    border-radius: 25px;*/
/*    font-weight: bold;*/
/*    border: none;*/
/*    cursor: pointer;*/
/*    margin-top: 10px;*/
/*}*/

/*.ConfirmButton:hover {*/
/*    background-color: #4a76af;*/
/*}*/

.AuthPage_Legal__29KSC, .AuthPage_Legal__29KSC:hover, .AuthPage_Legal__29KSC:link, .AuthPage_Legal__29KSC:visited, .AuthPage_Links__zyo_O:active {
    cursor: pointer;
    text-decoration: underline;
    color: #013a63ff;
}

.AuthPage_Checkbox__1_skr {
    display: flex;
}

/*.Checkbox.Input {*/
/*    width: 2rem;*/
/*}*/

.AuthPage_AuthPageCommercial__k4rPV{
    display: flex;
    width: 55%;
    height: 100%;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    position: relative;
}

.AuthPage_Grad__1vtU4 {
    background: linear-gradient(to right,  rgba(255,255,255,1) 0%,rgba(255,255,255,0.75) 25%,rgba(255,255,255,0.5) 50%,rgba(255,255,255,0.25) 75%,rgba(255,255,255,0) 100%);

    /*background: -moz-linear-gradient(left,  rgba(255,255,255,0) 0%, rgba(255,255,255,0.25) 25%, rgba(255,255,255,0.5) 50%, rgba(255,255,255,0.75) 75%, rgba(255,255,255,1) 100%);*/
    /*background: -webkit-linear-gradient(left,  rgba(255,255,255,0) 0%,rgba(255,255,255,0.25) 25%,rgba(255,255,255,0.5) 50%,rgba(255,255,255,0.75) 75%,rgba(255,255,255,1) 100%);*/
    /*background: linear-gradient(to right,  rgba(255,255,255,0) 0%,rgba(255,255,255,0.25) 25%,rgba(255,255,255,0.5) 50%,rgba(255,255,255,0.75) 75%,rgba(255,255,255,1) 100%);*/

    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#00ffffff',GradientType=1 );
    width: 200px;
    height: 100vh;
    position: absolute;
    left: 0;
    z-index: 1;
}

.AuthPage_ModalContentWrapper__20G6c {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.LogInPane_LogInPage__15ZU2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 1rem;
}

.LogInPane_LogInPage__15ZU2 a{
    color: #416caa;
    cursor: pointer;
    padding-top: 20px;
}

.LogInPane_LogInPage__15ZU2 a:hover {
    text-decoration: underline;
}

.LogInPane_LogInPage__15ZU2 form {
    padding: 2rem;
}

.LogInPane_ErrorMessage__2NRfV {
    color: red;
}
.LogInPane_Shake__26_IA {
    -webkit-animation: LogInPane_shake-animation__1WPVY 4.72s ease infinite;
            animation: LogInPane_shake-animation__1WPVY 4.72s ease infinite;
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
}

.LogInPane_EyeIcon__19RJm {
    width: 24px;
    stroke: #4D5055;
    position: absolute;
    top: 38.5%;
    right: -12px;
    cursor: pointer;
}

@-webkit-keyframes LogInPane_shake-animation__1WPVY {
    0% { -webkit-transform:translate(0,0); transform:translate(0,0) }
    1.78571% { -webkit-transform:translate(5px,0); transform:translate(5px,0) }
    3.57143% { -webkit-transform:translate(0,0); transform:translate(0,0) }
    5.35714% { -webkit-transform:translate(5px,0); transform:translate(5px,0) }
    7.14286% { -webkit-transform:translate(0,0); transform:translate(0,0) }
    8.92857% { -webkit-transform:translate(5px,0); transform:translate(5px,0) }
    10.71429% { -webkit-transform:translate(0,0); transform:translate(0,0) }
    100% { -webkit-transform:translate(0,0); transform:translate(0,0) }
}

@keyframes LogInPane_shake-animation__1WPVY {
    0% { -webkit-transform:translate(0,0); transform:translate(0,0) }
    1.78571% { -webkit-transform:translate(5px,0); transform:translate(5px,0) }
    3.57143% { -webkit-transform:translate(0,0); transform:translate(0,0) }
    5.35714% { -webkit-transform:translate(5px,0); transform:translate(5px,0) }
    7.14286% { -webkit-transform:translate(0,0); transform:translate(0,0) }
    8.92857% { -webkit-transform:translate(5px,0); transform:translate(5px,0) }
    10.71429% { -webkit-transform:translate(0,0); transform:translate(0,0) }
    100% { -webkit-transform:translate(0,0); transform:translate(0,0) }
}

.SearchResults_SearchResults__1jJjk {
    /*background-color: #f3f6f8ff;*/
    display: flex;
    justify-content: center;
    /*margin: 0 auto;*/
    z-index: 0;
}

.SearchResults_SearchResultsRes__3Bpdd {
    background-color: #f3f6f8ff;
    display: flex;
    justify-content: center;
}

.SearchResults_SearchResultsWrapper__1WydD {
    width:  85.375rem;
    box-sizing: border-box;
    padding: 0;
    /*z-index: 0;*/
}

.SearchResults_SearchResultsWrapperRes__1mcJh {
    /*margin-top: 13rem;*/
    width: 100vw;
    box-sizing: border-box;
    padding: 0;
}

/*.LeftPane {*/
/*    width: 75rem;!* TODO 30 mapa*!*/
/*    display: flex;*/
/*    float: left;*/
/*    !*flex-direction: column;*!*/
/*    align-items: flex-start;*/
/*    justify-content: flex-start;*/
/*    !*margin-left: 18%;*!*/
/*    !*margin-top: 100px;*!*/
/*    !*padding-left: 12%; !* TODO 3 mapa*!*!*/
/*    margin-right: 2%;*/
/*    z-index: 10;*/

/*    transition: padding-left ease-out 1000ms, width 1000ms ease-out;*/
/*    padding-left: calc(1rem + 150px);*/
/*}*/

/*.LeftPaneOpen {*/
/*    !*padding-left: 3%;*!*/
/*    width: 30%;*/
/*    transform: scale(1);*/
/*}*/

/*.LeftPaneClosed {*/
/*    !*padding-left: 12%;*!*/
/*    width: 50%;*/
/*    transform: scale(1);*/
/*}*/

/*@keyframes moveLeftPane {*/
/*    0% {*/
/*        opacity: 1;*/
/*        transform: translateX(100%);*/
/*    }*/
/*    !*50% {*!*/
/*    !*    opacity: 1;*!*/
/*    !*    transform: translateY(90%);*!*/
/*    !*}*!*/
/*    100% {*/
/*        opacity: 1;*/
/*        transform: translateX(0);*/
/*    }*/
/*}*/

/*@keyframes returnLeftPane {*/
/*    0% {*/
/*        opacity: 1;*/
/*        transform: translateY(0);*/
/*    }*/
/*    50% {*/
/*        opacity: 0.8;*/
/*        transform: translateY(60%);*/
/*    }*/
/*    100% {*/
/*        opacity: 0;*/
/*        transform: translateY(-100%);*/
/*    }*/
/*}*/

/*.LeftPaneDataHolder {*/
/*    width: 100%;*/
/*    background: #eaeff8;*/
/*    !*margin-left: 20%;*!*/
/*}*/
.SearchResults_LeftPane__yb2oh::-webkit-scrollbar{
    display: none;
}



.StoreItems_StoreItems__3Wjxk {
    width: 85.375rem;
    /*background-color: #e0e2e4;*/
    /*display: inline-flex;*/
    /*justify-content: space-between;*/
    /*flex-direction: row;*/
    /*flex-wrap: wrap;*/
    /*left: 0.188rem;*/
    /*padding: 1rem 0px 0.625rem 0px;*/
}

.StoreItems_ElementInGridCell__3sPDL {
    display: flex;
    justify-content: center;
}

.StoreItems_Grid__qoPry {
    width: 100vw;
    /*margin-left: -1.7rem;*/
    box-sizing: border-box;
    padding: 0;
    margin: 0.5rem auto 2rem;
}

@media screen and (min-width: 700px) {
    .StoreItems_ElementInGridCell__3sPDL {
        z-index: 10;
    }

    .StoreItems_Grid__qoPry {
        width: 1340px;
    }
}

.StoreItems_ListView__sDQUz {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-top: 1rem;
    padding: 1rem 0;
}

.StoreItems_ReklamaListView__10Pm7 {
    width: 66rem;
    background-color: lightgreen;
    height: 4rem;
    display: flex;
    align-items: center;
    /*margin-bottom: 0.8rem;*/
    padding: 0 2rem;
    /*border-radius: 15px;*/
    box-shadow: 0 0 0.8rem #cccccc;


}

.StoreItems_ListViewSoftEnter__MRYxL,
.StoreItems_GridViewSoftEnter__3Y-Dx {
    -webkit-animation: StoreItems_soft-enter__2KgDh 300ms ease-in;
            animation: StoreItems_soft-enter__2KgDh 300ms ease-in;
}

@-webkit-keyframes StoreItems_soft-enter__2KgDh {
    0% { opacity: 0}
    100% { opacity: 1}
}

@keyframes StoreItems_soft-enter__2KgDh {
    0% { opacity: 0}
    100% { opacity: 1}
}


.StoreItems_PagesHolder__6378L {
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: flex-end;
}

.StoreItem_StoreItem__1MDxZ {
    border: 1px solid #e2e8ecff;
    background-color: white;
    box-sizing: border-box;
}

.StoreItem_StoreItemGridView__3te0y {
    border-radius: 0.375rem;
    width: 90%;
    height: 230px;
    display: flex;
    /*line-height: 0.8rem;*/
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 1rem;
}

/*.StoreItemListView {*/
/*    width: 70rem;*/
/*    height: 4rem;*/
/*    display: inline-flex;*/
/*    justify-content: center;*/
/*    align-items: center;*/
/*    flex-direction: row;*/
/*    !*margin-bottom: 0.8rem;*!*/
/*    padding-left: 1.5rem;*/
/*    padding-right: 0;*/
/*    border-top: 1px solid #e8e7e7;*/
/*    border-bottom: 1px solid #e8e7e7;*/
/*}*/

.StoreItem_StoreItemListView__2UCM1:hover,
.StoreItem_StoreItemGridView__3te0y:hover {
    transition: 400ms ease-out;
    box-shadow: 0 0 0.75rem #A1A0A0;
    border: 1px solid #a1a0a0;
}

.StoreItem_StoreItemListView__2UCM1.StoreItem_Selected__3IFM4 {
    background-color: #f5f5f6;
}


.StoreItem_StoreItemGridView__3te0y h2 {
    color:  #4a4f54ff;
    /*font-size: large;*/
    font-size: 18px;
    font-weight: bold;
    /*line-height: 1rem;*/
    margin-bottom: 0.1rem;
    white-space: nowrap;
    margin-top: 0.1rem;
}

.StoreItem_StoreItemListView__2UCM1 h2 {
    color:  #4a4f54ff;
    /*font-size: large;*/
    font-size: 1.125rem;
    white-space: nowrap;
    width: 17rem;
}

.StoreItem_StoreItemGridView__3te0y p {
    color: #5b6977ff;
    font-size: 0.875rem;
    -webkit-text-size-adjust: auto;
    margin-bottom: 0.1rem;
    margin-top: 0.1rem;
}


.StoreItem_StoreItemListView__2UCM1 p {
    color: #535358;
    font-size: 1rem;
}

.StoreItem_QuantityInfoGridView__2VSoc {
    display: flex;
    vertical-align: center;
    padding-left: 1rem;
}

.StoreItem_StoreItem__1MDxZ .StoreItem_StoreItemListView__2UCM1 .StoreItem_QuantityInfoListView__3Kb6H {
    /*display: flex;*/
    /*flex-direction: row;    */
    /*align-items: center;*/
}


.StoreItem_AdditionalInfoGridView__1xxfG {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

}

.StoreItem_AdditionalInfoListView__1cdz2 {
    width: 15rem;
    /*position: relative;*/
    display: flex;
    flex-direction: row;
    /*justify-content: flex-end;*/
    justify-content: space-between;
    align-items: center;
}

.StoreItem_LocationAndAddress__27n_j {
    width: 25rem;
}

.StoreItem_ListViewPhoneNumber__2iJx9 {
    width: 12rem;
}

/*.AdditionalInfo .OpenClose {*/
/*    display: flex;*/
/*    justify-content: flex-start;*/
/*}*/

/*.AdditionalInfo .Stanje {*/
/*    display: flex;*/
/*    justify-content: flex-end;*/
/*}*/


.StoreItem_StoreItem__1MDxZ .StoreItem_OpenHoursAccess__1wPeO,
.StoreItem_StoreItem__1MDxZ .StoreItem_OnStockAccess__3sJNi{
    color: #4a4f54ff;
    /*margin: auto;*/
    /*font-size: 0.875rem;*/
    /*margin-top: 2px;*/
    /*margin: 8px 0;*/
    display: flex;
    align-items: center;
}

.StoreItem_StoreItem__1MDxZ .StoreItem_OpenHoursDenied__1HYAi,
.StoreItem_StoreItem__1MDxZ .StoreItem_OnStockDenied__1AAts{
    color: #E53132;
    /*margin: auto;*/
    /*font-size: 0.875rem;*/
    /*margin-top: 2px;*/
    display: flex;
    align-items: center;
}

.StoreItem_StoreItem__1MDxZ .StoreItem_One__3rLG2 {
    /*display: none;*/
    color: #F58300;
    margin: auto;
    font-size: 12px;
}

.StoreItem_StoreItem__1MDxZ .StoreItem_Few__3ClTP {
    /*display: none;*/
    color: #FFAE00;
    margin: auto;
    font-size: 12px;
}

.StoreItem_StoreItem__1MDxZ .StoreItem_Many__4aMkB {
    /*display: none;*/
    color: #00A4B4;
    margin: auto;
    font-size: 12px;
}

/*.StoreItemGridView.Selected .One,*/
/*.StoreItemGridView.Selected .Few,*/
/*.StoreItemGridView.Selected .Many {*/
/*    color: white;*/
/*}*/


/*.AdditionalInfo .VerticalLine {*/
/*    color: #008D36;*/
/*    margin-left: 0.313rem;*/
/*    margin-right: 0.313rem;*/
/*    font-weight: bold;*/
/*}*/

.StoreItem_StoreItemListView__2UCM1 i,
.StoreItem_StoreItemGridView__3te0y i{
    line-height: 0.4;
}

.StoreItem_SqBox1__360wE,
.StoreItem_SqBox3__1L3gW, .StoreItem_SqBox4__3ETjk {
    position: relative;
}

.StoreItem_TooltipText__24nd1 {
    position: absolute;
    visibility: hidden;
    left: -10.5rem;
    top: 2.2rem;
}

.StoreItem_TooltipText__24nd1::after {
    left:79%;
    bottom: 94%;
}

.StoreItem_SqBox1__360wE:hover .StoreItem_TooltipText__24nd1,
.StoreItem_SqBox1__360wE:hover .StoreItem_TooltipText__24nd1:after,
.StoreItem_SqBox3__1L3gW:hover .StoreItem_TooltipText__24nd1,
.StoreItem_SqBox3__1L3gW:hover .StoreItem_TooltipText__24nd1:after,
.StoreItem_SqBox4__3ETjk:hover .StoreItem_TooltipText__24nd1,
.StoreItem_SqBox4__3ETjk:hover .StoreItem_TooltipText__24nd1:after
{
    visibility: visible;
    opacity: 1;
    transition: 600ms ease-out;
}

.StoreItem_StoreItemGridView__3te0y:hover .StoreItem_One__3rLG2,
.StoreItem_StoreItemGridView__3te0y:hover .StoreItem_Few__3ClTP,
.StoreItem_StoreItemGridView__3te0y:hover .StoreItem_Many__4aMkB
{
    display: block;
}

/*.StoreItemGridView:hover  .OnStockAccess {*/
/*    display: none;*/
/*}*/


/*.StoreItemGridView.Selected .CncIcon {*/
/*    fill: #416CAA;*/
/*}*/
.StoreItem_BoxHeader__2HroN {
    height: 2.5rem;
    width: 100%;
    border-bottom: 1px solid #e2e8ecff;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.StoreItem_WorkingTime__IcKxO {
    width: 50%;
    display: flex;
    padding-left: 1rem;
}

.StoreItem_BoxHeaderBadges__3i0PW {
    width: 50%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding-right: 0.375rem;
}

.StoreItem_BoxMiddle__3Hyyr {
    height: 150px;
    width: 92%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.StoreItem_BoxFooter__B6GxV {
    height: 3.5rem;
    width: 100%;
    border-top: 1px solid #e2e8ecff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 0 0.5rem;
}

.StoreItem_RateBadgeText__1zptS {
    color: white;
    font-weight: 800;
    font-size: 14px;
}

@media screen and (min-width: 700px){
    .StoreItem_StoreItemGridView__3te0y {
        width: 18.875rem;
    }

    .StoreItem_QuantityInfoGridView__2VSoc {
        /*width: 60%;*/
        padding-left: 8px;
    }

    .StoreItem_RateBadgeText__1zptS {
        font-size: 16px;
    }

    /*.BoxFooter {*/
    /*    height: 2.5rem;*/
    /*    padding: 0;*/
    /*}*/
}

.StoreItem_CnCBadge__14hLJ {
    padding-right: .375rem;
}

.StoreItem_PharmacyName__2xdbH {
    padding-left: 1rem;
    margin-top: .5rem;
    cursor: pointer;
    display: flex;
    align-items: flex-start;
}

.StoreItem_PharmacyContactInfo__2dCku {
    margin-bottom: 1.25rem;
    padding-left: 1rem;
}


.StoreItemsError_StoreItemsError__FAH12 {
    display: flex;
    flex-direction: column;
    font-size: x-large;
    color: #535358;
    /*background-color: palegoldenrod;*/
}

.StoreItemsError_SearchResultError__2-64u {
    padding: 0 30px;
    display: flex;
    justify-content: space-between;
}

.StoreItemsError_SearchResultErrorMessage__1w3O_{
    padding: 0 30px;
    display: flex;
    background-color: #f5f5f6;
}

.StoreItemsError_SearchResultErrorMessage__1w3O_ .StoreItemsError_SearchResultErrorIcon__3Tp-H {
    width: 24px;
    height: 24px;
    margin: auto 10px;
}

.StoreItemsError_SearchResult0__cnfcE{
    justify-content: flex-start;
    color: #416CAA;
}

.StoreItemsError_SearchResultUnavailable__EmH4_{
    justify-content: flex-end;
    color: #E53132;
}

/*.StoreItemsError .EmailInputField {*/
/*    display: flex;*/
/*}*/

/*.StoreItemsError .EmailInputField::placeholder {*/
/*    color: #535358;*/
/*    opacity: 0.4;*/
/*    padding-left: 10px;*/
/*}*/

.StoreItemsError_StoreItemsError__FAH12 p {
    font-size: 14px;
}

.StoreItemsError_AdditionalActionsHolder__2ExX1 {
    display: flex;
    flex-direction: row;
    padding: 30px;
    /*justify-content: center;*/
    /*margin: auto;*/
}

.StoreItemsError_StoreItemsError__FAH12 .StoreItemsError_SupportHolder__336dn {
    background-color: white;
    border-radius: 6px;
    box-sizing: border-box;
    width: 15rem;
    height: 15rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid #e2e8ecff;
    text-align: center;
    margin-right: 1rem;
}

.StoreItemsError_LiveChatDisplay__3zdJT {
    display: none !important;
}

@media screen and (min-width: 700px) {
    .StoreItemsError_LiveChatDisplay__3zdJT {
        display: block !important;
    }
}
.SearchInputRequest_UnknownItemMessageHolder__OthBr {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
}

.SearchInputRequest_UnknownItemMessage__avmoj {
    padding: 0 30px;
    display: flex;
    justify-content: center;
}

.SearchInputRequest_UnknownItemMessage__avmoj .SearchInputRequest_UnknownItemIcon__3aFGS {
    width: 24px;
    height: 24px;
    margin: auto 10px;
}

.SearchInputRequest_AdditionalActionsHolder__nqzzm {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.SearchInputRequest_SupportHolder__2QdBn {
    background-color: white;
    border-radius: 6px;
    box-sizing: border-box;
    width: 372px;
    height: 60px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid #e2e8ecff;
    text-align: center;
}

@media screen and (min-width: 700px) {
    .SearchInputRequest_UnknownItemMessageHolder__OthBr {
        width: 50%;
        align-self: center;
    }
    .SearchInputRequest_AdditionalActionsHolder__nqzzm {
        padding: 30px;
        justify-content: center;

    }
}

.Pagination_Pages__313fI {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    padding: 1rem;
}

.Pagination_Pages__313fI button {
    /*!*background-color: transparent;*!*/
    /*!*border-color: transparent;*!*/
    /*color: #416CAA;*/
    font-size: 1rem;
    /*width: 3rem;*/
    /*==============================================*/
    color: #88959E;
    width: 2.5rem;
}

.Pagination_Pages__313fI button:hover {     /*  ovde izmeniti nesto, ako treba (mozda samo boju!?) */
    /*cursor: pointer;*/
    /*background-color: #faf9f1;*/
    /*transition: 400ms ease-out;*/
}

.Pagination_Pages__313fI .Pagination_PaginationButton__3d57c:hover {
    cursor: pointer;
    background-color: #faf9f1;
    transition: 400ms ease-out;
}

.Pagination_Pages__313fI p {
    /*color: #416CAA;*/
    font-size: 1.125rem;
    /*==============================================*/
    color: #88959E;
}

.Pagination_Pages__313fI .Pagination_Arrow__2EnNq button {     /*  ovde izmeniti nesto, ako treba (mozda samo boju!?) */
    color: #4285F4;
    font-size: 1.563rem;
}

.Pagination_PaginationButton__3d57c {
    background-color: white;
    /*border: 1px solid lightgray;*/
    /*border-radius: 7px;*/
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.5rem;
    /*box-shadow: 0 0 0.8rem #cccccc;*/
    margin-right: 0.3rem;
    margin-left: 0.3rem;
    /*==============================================*/
    border: 2px solid #E2E8EC;
    border-radius: 6px;
    /*box-shadow: 0 0 0.8rem #cccccc;*/


}

.Pagination_PaginationButtonPressed__3kbVg {
    /*background-color: #f5f5f6;*/
    /*box-shadow: 0 0 0;*/
    /*==============================================*/
    background-color: #E2E8EC;
    /*box-shadow: 0 0 0;*/
}

.Pagination_Arrow__2EnNq {                   /*  ovde izmeniti nesto, ako treba (mozda samo boju!?) */
    color: #4285F4;
}


.Pagination_PaginationArrow__3abxK {
    background: transparent;
    border: none;
}

.Pagination_PaginationArrow__3abxK:hover {
    cursor: pointer;
    transition: 400ms ease-out;
}

.Pagination_ArrowIcon__1QOXG {
    height: 16px;
    width: 16px;
}

.SearchResultsHeader_SearchResultHeader__134yM {
    z-index: 10;
    box-sizing: border-box;
    height: 450px;
    margin: 0 32px;
}

.SearchResultsHeader_SearchResultHeaderSticky__3ccfr {
    /*top: 5.25rem;*/
    padding: 1rem 1.5rem 2rem;
    width: 100%;
    position: fixed;
    box-sizing: border-box;
    /*margin-top: 5rem;*/
    background-color: white;
    box-shadow: 0 0 0.8rem #cccccc;
    z-index: 10;
}

.SearchResultsHeader_SearchResultHeaderHolder__2_HZ2 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: auto;
    /*width: 75rem;*/
    height: 100%;
    align-items: center;
    /*transform: scaleX(-1);  !* skinuti kada se skine i scale sa .SearchResultHeader, gore *!*/
    position: relative;
}

.SearchResultsHeader_RightHeaderPane__1Tf1T{
    display: flex;
    flex-direction: column;
}

.SearchResultsHeader_TextHeader__Lx1EW{
    color: #535358;
}

.SearchResultsHeader_TextHeader__Lx1EW h1{
    font-size: 2rem;
}

.SearchResultsHeader_TextHeader__Lx1EW p{
    font-size: 1.4rem;
    white-space: pre-line;
}

.SearchResultsHeader_TextHeader__Lx1EW a {
    font-size: 0.9rem;
    box-sizing: border-box;
    margin-top: 2rem;
    padding: 0.5rem 1.6rem;
    border-radius: 25px;
    border: 1px solid #535358;
    background-color: white;
    color: #535358;
    display: flex;
    justify-content: center;
    align-items: center;
    width: -webkit-max-content;
    width: max-content;
}

.SearchResultsHeader_TextHeader__Lx1EW a:hover {
    cursor: pointer;
}

.SearchResultsHeader_TextHeader__Lx1EW .SearchResultsHeader_TextHeaderIcon__3VGnw {
    fill: #535358;
    margin-right: 0.3rem;
    vertical-align: top;
}

.SearchResultsHeader_TextHeaderDissolve__17a_X {
    overflow: hidden;
    height: 0;
    opacity: 0;
    transition:  opacity 200ms, height 0ms;
}

.SearchResultsHeader_TextHeaderDisappear__3-JkK {
    display: none;
}

.SearchResultsHeader_SearchResultHeaderHolder__2_HZ2 .SearchResultsHeader_CommercialImage__39e6X {
    display: flex;
    flex-direction: column;
    height: 100%;
    /*max-width: 480px;*/
    cursor: pointer;
}
.Article_Article__3kktW {
    position: relative;
    display: flex;
    flex-direction: column;
    /*justify-content: center;*/
    /*align-items: center;*/
    font-size: 0.875rem;
    background-color: white;
    border-radius: 6px;
    padding: 1rem 1.5rem;
    color: #4a4f54ff;
}

.Article_ArticleInModal__28d3p {
    position: relative;
    display: flex;
    flex-direction: column;
    font-size: 0.875rem;
    background-color: white;
    padding: 1rem 1.5rem;
    margin-top: -1rem;
}

.Article_Article__3kktW .Article_MiniBox__3OGyv {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-radius: 6px;
    height: 9.25rem;
    width: 100%;
}

.Article_ArticleHeader__1xpRG {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0.375rem 0;
}

.Article_ArticleInModal__28d3p .Article_ArticleHeaderInMobile__1-QWP {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.Article_ArticleInModal__28d3p .Article_ArticleTitleInModal__29lpq {
    color: #416caa;
    font-size: 1rem;
    display: flex;
    justify-content: center;
    word-break: break-word;

}

.Article_ArticleImageHolder__qdpEM {
    box-sizing: border-box;
    height: auto;
    max-width: 70vw;
    /*margin: auto;*/
    display: flex;
    justify-content: center;
    align-items: center;
}

.Article_ArticleImage__1fbrr {
    /*border-radius: 0.5rem;*/
    max-width: 100%;
    max-height: 100%;
    display: block;
    border-radius: 8px;
    object-fit: contain;
}

.Article_ArticleInModal__28d3p .Article_ArticleImageInModalHolder__1wC8l {
    margin: 5% auto;
    display: flex;
    justify-content: center;
    align-items: center;
    max-height: 15rem;
}


.Article_ArticleInModal__28d3p .Article_ArticleImageInModal__1GtB1 {
    max-height: 100%;
    max-width: 90%;
    border-radius: 8px;
}

.Article_ArticleBigImageHolder__2W5mQ {
    position: absolute;
    /*right: 1.438rem;*/
    right: -0.5rem;
    width: 25rem;
    height: 25rem;
    border-radius: 8px;
    z-index: 999;
    -webkit-animation: Article_growingImage__22RYG 500ms ease-in;
            animation: Article_growingImage__22RYG 500ms ease-in;
    background-color: white;
}

.Article_ArticleBigImage__1Kfff {
    position: absolute;
    max-width: 100%;
    /*height: 100%;*/
    box-shadow: 0 0 0.8rem #cccccc;
}

@-webkit-keyframes Article_growingImage__22RYG {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes Article_growingImage__22RYG {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.Article_ArticleTitle__3ajmp {
    color: #4D5055;
    font-size: 1rem;
    font-weight: bold;
    word-break: break-word;
    text-align: center;
    margin-top: 0;
    text-decoration: underline;
    cursor: pointer;
    width: 100%;
}

.Article_ArticleInfoActions__JV7l0 {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    padding: 0 0 1rem;
    width: 100%;
}

.Article_Article__3kktW .Article_MoreDetails__2O2yE {
    display: none;
}

.Article_Article__3kktW .Article_PriceRange__2i9LM {
    font-weight: normal;
    font-size: 0.75rem;
    margin: 0;
}

@media only screen and (min-width: 700px) {
    .Article_Article__3kktW {
        padding: 1rem 1rem;
        box-sizing: border-box;
        height: 100%;
    }

    .Article_Article__3kktW .Article_MiniBox__3OGyv {
        height: 100%;
    }

    .Article_Article__3kktW .Article_ArticleHeader__1xpRG {
        padding: 0.8rem 0;
    }

    .Article_Article__3kktW .Article_ArticleImageHolder__qdpEM {
        position: relative;
        height: auto;
        width: 50%;
        margin: 1rem;
    }

    .Article_Article__3kktW .Article_ArticleTitle__3ajmp {
        color: #4a4f54ff;
        font-size: 1.125rem;
        text-decoration: none;
        margin-top: 18px;
    }

    .Article_Article__3kktW .Article_MoreDetails__2O2yE {
        color: #4a4f54ff;
        display: flex;
        font-size: 1.125rem;
        /*text-align: end;*/
        cursor: pointer;
        padding-right: 1rem;
        margin: 0 0 0.625rem;
    }

    .Article_Article__3kktW .Article_PriceRange__2i9LM {
        font-size: 0.875rem;
    }

    .Article_ArticleInfo__1fhzu {
        width: 55%;
        justify-content: space-between !important;
    }
}

.Article_Article__3kktW .Article_PriceAndDetailsWrapper__qMruE {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;
    /*margin-top: -0.93rem;*/
}

.Article_PriceAndDetailsWrapperInModal__2RKmR {
    display: flex;
    justify-content: center;
}

.Article_Article__3kktW .Article_ArticleBody__3yRok {
    display: block;
    margin-top: 1rem;
}

.Article_Article__3kktW .Article_ArticleBody__3yRok button {
    float: right;
    border-radius: 25px;
    padding: 0.3rem;
    border: 1px solid #535358;
    color: #4a4f54ff;
    background-color: transparent;
}

.Article_Article__3kktW .Article_ArticleBody__3yRok button:hover {
    color: #4a4f54ff;
    background-color: #eeeeee;
    cursor: pointer;
    transition: 400ms ease-in-out;
}

.Article_ArticleInfo__1fhzu {
    display: flex;
    flex-direction: column;
    /*justify-content: space-between;*/
    /*width: 55%;*/
    margin-left: 1rem;
    margin-right: 1rem;
    align-items: center;
    justify-content: center;
    /*display: flex;*/
    /*flex-direction: column;*/
}

.Article_Article__3kktW .Article_ArticleInfoActions__JV7l0 {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid #e2e8ec;
    padding-top: 1rem;
}

.Article_ArticleInfoHandler__1loFq {
    cursor: pointer;
    color: #00B2B1;
    display: flex;
    align-items: center;
}

.Article_Article__3kktW .Article_LoyaltyPointsIcon__37XcI {
    position: absolute;
}

@media screen and (max-width: 699px){
    .Article_LoyaltyPointsIcon__37XcI {
        position: absolute;
        top: 20%;
        left: 0;
    }
}



.InfoBoxTemplate_InfoBox__3odJe {
    position: relative;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    /*box-shadow: 0 0 0.8rem #cccccc;*/
    border-radius: 6px;
    background-color: white;
    box-sizing: border-box;
}

@media screen and (min-width: 700px){
    .InfoBoxTemplate_InfoBox__3odJe {
        width: 39.875rem;
        height: 15.75rem;
        margin-top: 32px;
    }

    .InfoBoxTemplate_InfoBox__3odJe:hover {
        transition: 400ms ease-out;
        box-shadow: 0 0 0.75rem #a1a0a0;
        /*border: 1px solid #a1a0a0;*/
        cursor: pointer;
    }
}
.SearchedInfo_Title__1FIyj {
    margin: 0.5rem 0 0;
    padding: 0 0 1rem 0 ;
    font-size: 18px;
    border-bottom: 1px solid #e2e8ecff;
    color: #00375cff;
}

.SearchedInfo_Text__Nzv0A {
    padding: 0.5rem 0;
    margin: 0;
}

@media screen and (min-width: 700px){
    .SearchedInfo_Text__Nzv0A {
        padding: 1rem 0 0;
    }
}
.Store_ShopInfoBox__2pjHV {
    background-color: white;
    border-radius: 6px;
    padding: 1rem 2rem;
    box-sizing: border-box;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    position: relative;

}

.Store_ShopInfoBox__2pjHV .Store_UpperPart__1uyes {
    height: 130px;
}

.Store_ShopHQ__P8rzr {

}

.Store_ShopInfoBoxHeader__3VfIR {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;

}

.Store_ShopName__3C-NS {
    width: 80%;
}

.Store_ShopName__3C-NS p {
    font-size: 18px;
    font-weight: bold;
    line-height: 24px;
    text-decoration: underline;
    margin-top: 0;
    color: #4a4f54ff;
}

.Store_ShopName__3C-NS p:hover {
    cursor: pointer;
}

.Store_ShopExitIcon__1nDRW {
    width: 20%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: -1rem;
}

.Store_ShopExitIcon__1nDRW:hover {
    cursor: pointer;
}

.Store_ShopAddress__spLfc {
    margin-top: 0;
    color: #4a4f54ff;
    font-size: 14px;
}

.Store_ShopPhoneNumber__2CZGa {
    color: #4a4f54ff;
    font-size: 14px;
    display: flex;
    align-items: center;
    margin: 0;
}

/*.ShopInfoBox .LowerPart {*/
/*    width: 100%;*/
/*    height: 48px;*/
/*    display: flex;*/
/*    align-items: center;*/
/*    border-top: 1px solid #bdccd4ff;*/
/*    margin-bottom: -20px;*/
/*    !*margin-top: -.5rem;*!*/
/*}*/

.Store_LowerPart__lOqrW {
    display: flex;
    border-top: 1px solid #bdccd4;
    align-items: center;
    margin-top: 1rem;
    padding-top: 1rem;
}

.Store_LowerPart__lOqrW .Store_WorkingHours__2GcUh {
    width: 40%;
    display: flex;
    align-items: center;
    color: #4a4f54ff;
}

.Store_LowerPart__lOqrW .Store_ShopBadges__2zkrA {
    width: 60%;
    display: flex;
    justify-content: flex-end;
}

.Store_XButton__3INNu {
    max-height: 1.688rem;
    border: none;
    padding: 0;
    border-radius: 1.563rem;
    background-color: white;
    /*border: 1px solid #80808099;*/
    width: 24px;
    height: 24px;
    cursor: pointer;
    font-weight: bold;
}

.Store_SqBox5__2LFwG {
    cursor: pointer;
}

.Store_SqBox1__3GaW0, .Store_SqBox2__1dn-S,
.Store_SqBox3__1T2yK, .Store_SqBox4__6SA6m,
.Store_SqBox5__2LFwG, .Store_ShopExitIcon__1nDRW,
.Store_XButton__3INNu {
    position: relative;
}
.SearchResultsActionMenu_SearchResultsAM__3kVRw {
    display: flex;
    flex-direction: row;
    margin: auto 0;
    align-items: center;
}

.SearchResultsActionMenu_SearchResultsAM__3kVRw .SearchResultsActionMenu_IconStyle__2Kt51 {
    width: 24px;
    height: 24px;
    stroke: #88959eff;
}

.SearchResultsActionMenu_SearchResultsAM__3kVRw .SearchResultsActionMenu_IconStyleClicked__3dKRc {
    width: 24px;
    height: 24px;
    stroke: #00B2B1;
}

.SearchResultsActionMenu_SearchResultsAM__3kVRw .SearchResultsActionMenu_IconStyle__2Kt51:hover {
    stroke: #00B2B1;
}

.SearchResultsActionMenu_FilterButton__2fo7Z {
    color: #88959eff;
    background-color: white;
    width: 114px;
    height: 44px;
    font-size: 14px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-around;
    border-radius: 3px;
}

.SearchResultsActionMenu_FilterButton__2fo7Z:hover {
    border: 1px solid #013a63ff;
}

.SearchResultsActionMenu_FilterButtonUnselected__1G1mv {
    border: 1px solid #e2e8ecff;
}

.SearchResultsActionMenu_FilterButtonSelected__pmDJW {
    border: 1px solid #00B2B1;
    color: #00B2B1;
}

.SearchResultsActionMenu_QuickFilter__3zAEH:hover {
    position: relative;
}

.SearchResultsActionMenu_TooltipText__37L9r {
    /* Position the tooltip */
    left: -171px;
    top: 44px;
}

.SearchResultsActionMenu_TooltipText__37L9r::after {
    /* position tooltip correctly */
    left:79%;
    /* vertically center */
    bottom: 94%;
}

.SearchResultsActionMenu_QuickFilter__3zAEH:hover .SearchResultsActionMenu_TooltipText__37L9r,
.SearchResultsActionMenu_QuickFilter__3zAEH:hover .SearchResultsActionMenu_TooltipText__37L9r:after
{
    visibility: visible;
    opacity: 1;
    transition: 600ms ease-out;
}

.SearchResultsActionMenu_FilterIconMobile__3eDsQ{
    height: 22px;
    width: 22px;
    margin: auto 1rem;
    cursor: pointer;
}
.ActionMenu_ActionMenu__146S4 {
    background-color: white;
    height: 86px;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    border-radius: 6px;
    box-shadow: 0 8px 0.8rem -12px #cccccc;
    margin: -43px 32px 0;
}

.ActionMenu_ActionMenuHolder__1wixT {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 1366px;
    padding: 0.5rem 30px 0.5rem;
    box-sizing: border-box;
    color: #4a4f54ff;
}

/*.ActionMenu p{*/
/*    box-sizing: border-box;*/
/*    align-items: center;*/
/*    align-content: center;*/
/*    padding: 0;*/
/*    font-weight: bold;*/
/*}*/

.ActionMenu_PositionSticky__YM6n8 {
    top: 70px;
    position: fixed;
    background-color: white;
    /*border-bottom: 1px solid #888888;*/
    border-radius: 0;
    z-index: 15;
    left: 0;
    margin: 0;
    box-sizing: border-box;
    width: 100%;
    justify-content: center;
}
.StoreFilter_StoreFilter__3WWI5 {
    display: flex;
    flex-direction: column;
    width: 100vw;
    max-height: 600px;
    cursor: default;
}

@media screen and (min-width: 700px) {

    .StoreFilter_StoreFilter__3WWI5 {
        width: 270px;
    }

    .StoreFilter_StoreFilter__3WWI5::after {
        /* the arrow */
        content: "";
        border: 10px solid rgb(74, 79, 84);
        border-color: transparent transparent white transparent;
        position: absolute;
        top: -22px;
        left: 50px;
    }
}

.StoreFilter_StoreFilter__3WWI5 .StoreFilter_RootCategoryWrapper__1sclR {
    flex-grow: 1;
    background-color: rgb(246, 248, 249);
    display: flex;
    flex-direction: column;
}

.StoreFilter_StoreFilter__3WWI5 .StoreFilter_RootCategoryList__36GhZ {
    padding-top: 12px;

}

.StoreFilter_StoreFilter__3WWI5 .StoreFilter_RootCategoryList__36GhZ p {
    margin: 4px 0 0 0;
    padding: 8px 16px;
    max-height: 36px;
    font-weight: normal;
    background-color: white;
}

.StoreFilter_StoreFilter__3WWI5 .StoreFilter_SubCategoriesWrapper__21pj7 {
    /*flex-grow: 2;*/
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
}

.StoreFilter_StoreFilter__3WWI5 .StoreFilter_SubCategoryWrapper__3vEaM {
    /*width: 300px;*/
    margin-bottom: 8px;
    margin-top: 8px;
    text-align: left;
    padding-left: 24px;
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: 14px;
    font-weight: normal;
    box-sizing: border-box;
}

.StoreFilter_StoreFilter__3WWI5 .StoreFilter_SubCategoryTitle__100VU {
    font-weight: bold;
    font-size: 16px;
    padding: 16px;
    display: flex;
    align-items: center;
}

.StoreFilter_StoreFilter__3WWI5 .StoreFilter_SubCategoryList__-1qla {
    display: flex;
    flex-direction: column;
    /*flex-flow: row wrap;*/
    font-weight: bold;
    height: 100%;
    width: 100%;
    align-content: flex-start;
    justify-content: center;
}

.StoreFilter_StoreFilter__3WWI5 .StoreFilter_ActiveFilter__1zWwa {
    color: #00A4B4;
}

.StoreFilter_StoreFilter__3WWI5 .StoreFilter_CitySearch__1Y2Bs {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin-right: 8rem;
}


.ActionMenuRes_ActionMenuRes__17eq3 {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0 0.5rem 0.5rem;
    position: relative;
    border-top: 1px solid #e2e8ecff;
}

.ActionMenuRes_PositionSticky__1AV8n {
    top: 44px;
    position: fixed;
    background-color: white;
    border-radius: 0;
    z-index: 6;
    left: 0;
    margin: 0;
    box-sizing: border-box;
    width: 100%;
    border-bottom: 1px solid #e2e8ecff;
}
.ArticleItems_ResultsContainer__11KjW {
    width: 100vw;
    /*background-color: #f3f6f8ff;*/
}
.ArticleItems_ArticleItemsWrapper__3LJe7 {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 0;
    width: 100vw;
}

@media screen and (min-width: 700px) {

    .ArticleItems_ResultsContainer__11KjW {
        width: 100%;
    }

    .ArticleItems_ArticleItemsWrapper__3LJe7 {
        width: 100%;
        margin-left: 2rem;
        margin-right: 0;
        justify-content: flex-start;
    }

    .ArticleItems_ShopSelectionMenu__22Bx2 {
        height: 2rem;
    }
}

.ArticleItems_ListOrGridToggleButtons__15CM2 {
    width: 100%;
    height: 3rem;
    /*border: 1px solid black;*/
    display: inline-flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 1.5rem;
}

.ArticleItems_ListOrGridToggleButton__1teSy {
    width: 2.5rem;
    height: 2.5rem;
    /*border: 1px solid beige;*/
    border-radius: 7px;
    box-shadow: 3px 3px 0.4rem #585757;
    margin-right: 0.5rem;
    color: #535358;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    border-style: none;
}

.ArticleItems_ListOrGridToggleButton__1teSy:hover {
    cursor: pointer;
}

.ArticleItems_ToggleButtonSelected__304_Q {
    background-color: #f5f5f6;
    border: 2px solid transparent;
    box-shadow: 0 0 0.4rem #585757;
}


.ArticleItems_ListView__1kBPJ {
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
}

.ArticleItems_ReklamaListView__1aIds {
    width: 71rem;
    background-color: lightgreen;
    height: 4rem;
    display: flex;
    align-items: center;
    margin-bottom: 0.8rem;
    padding: 0 2rem;
    border-radius: 15px;
    box-shadow: 0 0 0.8rem #cccccc;


}

.ArticleItems_ListViewSoftEnter__3Xi0B,
.ArticleItems_GridViewSoftEnter__2sUjH {
    -webkit-animation: ArticleItems_soft-enter__iBAYl 300ms ease-in;
            animation: ArticleItems_soft-enter__iBAYl 300ms ease-in;
}

@-webkit-keyframes ArticleItems_soft-enter__iBAYl {
    0% { opacity: 0}
    100% { opacity: 1}
}

@keyframes ArticleItems_soft-enter__iBAYl {
    0% { opacity: 0}
    100% { opacity: 1}
}

.ArticleItems_ShopSelectionMenu__22Bx2 {
    height: 1rem;
    width: 75rem;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}

.ArticleItems_ShopArticleSelect__20xNR {
    min-width: 10rem;
    /*width: max-content;*/
    padding: 0.3rem;
    background-color: white;
    border-radius: 10px;
    height: 2.1rem;
    font-size: 0.875rem;
}


/*grid/list toggle buttons*/
.ArticleItems_ListOrGridToggleButtons__15CM2 {
    width: 6rem;
    height: 1rem;
    display: inline-flex;
    justify-content: flex-start;
    align-items: center;
    /*padding-left: 1.5rem;*/
}

.ArticleItems_ListOrGridToggleButton__1teSy {
    width: 2rem !important;
    height: 2rem !important;
    /*border: 1px solid beige;*/
    border-radius: 7px;
    box-shadow: 3px 3px 0.4rem #585757;
    margin-right: 0.5rem;
    color: #535358;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    border-style: none;
}

.ArticleItems_ListOrGridToggleButton__1teSy:hover {
    cursor: pointer;
    opacity: 0.6;
}

.ArticleItems_ToggleButtonSelected__304_Q {
    background-color: #f5f5f6;
    border: 2px solid transparent;
    box-shadow: 0 0 0.2rem #585757;
}

.ArticleItems_SearchResultErrorMessage__2AMrf{
    margin-right: 64px;
    padding: 0 32px;
    display: flex;
    background-color: #f5f5f6;
    font-size: x-large;
    color: #535358;
}
.SearchedArticleItem_SearchedArticleItem__T4cMP {
    width: 100%;
    height: 320px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 2rem;
    margin-right: 64px;
    box-sizing: border-box;
    background-color: white;
    border-radius: 6px;
    border: 1px solid #e2e8ecff;
}

.SearchedArticleItem_SearchedArticleItem__T4cMP:hover {
    /*border: 1px solid #013a63ff;*/
    box-shadow: 0 0 1.2rem #acaaaa;
    transition: 400ms ease-out;
}

.SearchedArticleItem_SearchedArticleItem__T4cMP .SearchedArticleItem_ArticleImageHolder__3VZUn {
    width: 30%;
    display: flex;
    align-items: center;
    position: relative;
}

.SearchedArticleItem_ArticleImageHolder__3VZUn .SearchedArticleItem_ArticleImage__xDyhG {
    width: 100%;
}

.SearchedArticleItem_SearchedArticleItemTextBox__3xokx {
    width:45%;
    height: 100%;
    padding: 2rem;
    box-sizing: border-box;
    display: flex;
    flex-flow: wrap;
    align-content: space-between;
    background-color: white;
    margin: 0 12px;

    color: #4a4f54;
}

.SearchedArticleItem_SearchedArticleItemTextBox__3xokx p {
    font-size: 1rem;
    width: 100%;
}

.SearchedArticleItem_SearchedArticleItemTextBox__3xokx .SearchedArticleItem_Title__3Vmut {
    font-size: 20px;
    padding: 0 1rem;
}

.SearchedArticleItem_SearchedArticleItemTextBox__3xokx .SearchedArticleItem_MoreDetailsWrapper__1O9oM {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    border-top: 1px solid #E2EBEC;
    margin-top: 1rem;
    padding-top: 1rem;
}

.SearchedArticleItem_MoreDetailsWrapper__1O9oM .SearchedArticleItem_MoreDetailsAction__T5Dl2 {
    cursor: pointer;
    padding: 0 1rem;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    display: flex;
    align-items: center;
    color: #00B2B1;
}

.SearchedArticleItem_SearchedArticleItem__T4cMP .SearchedArticleItem_ActionsHolder__1n2H6 {
    display: flex;
    flex-direction: column;
    width: 25%;
    padding: 2rem;
    box-sizing: border-box;
    position: relative;
}

.SearchedArticleItem_QuantityInCart__2_s1d {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: -11px;
    right: -11px;
    border-radius: 50%;
    background-color: #219EBC;
    color: white;
    width: 22px;
    height: 22px;
}

.SearchedArticleItem_ItemAvailability__3AiHS {
    position: absolute;
    left: 50px;
    top: 50px;
}

.SearchedArticleItem_AddSelectedArticleToCartButton__3MFGR {
    display:flex;
    width: 100%;
    position: relative;
    align-items: center;
    justify-content: space-between;
    border-Top: 1px solid #E2EBEC;
    height: 52px;
    padding-top: 1rem;
}

.SearchedArticleItem_AddSelectedArticleToCartButton__3MFGR .SearchedArticleItem_ShoppingCartIcon__2NSMm {
    width: 24px;
    color: inherit;
    left: 24px;
}

.SearchedArticleItem_ActionsHolder__1n2H6 .SearchedArticleItem_SelectedArticlePrice__3h9Mq {
    margin-top: auto;
    display: flex;
    justify-content:space-between;
    align-items: end;
    margin-bottom: 1rem;
}


.SearchedArticleItem_TooltipWrapper__PMR3a {
    position: relative;
}

.SearchedArticleItem_TooltipText__2iPHC {
    /* Position the tooltip */
    left: 3rem;
    top: 2.7rem;
}

.SearchedArticleItem_TooltipText__2iPHC::after {
    /* position tooltip correctly */
    left:10%;
    /* vertically center */
    bottom: 94%;
}

@media screen and (max-width: 699px){
    .SearchedArticleItem_ItemAvailability__3AiHS {
        top: -10rem;
    }
}

.SearchedArticleItem_ItemAvailabilityTooltipText__IV6gP {
    /* Position the tooltip */
    left: -11rem;
    top: 2.2rem;
}

.SearchedArticleItem_ItemAvailabilityTooltipText__IV6gP::after {
    /* position tooltip correctly */
    left:80%;

    /* vertically center */
    bottom: 94%;
}

.SearchedArticleItem_TooltipWrapper__PMR3a:hover .SearchedArticleItem_TooltipText__2iPHC,
.SearchedArticleItem_TooltipWrapper__PMR3a:hover .SearchedArticleItem_TooltipText__2iPHC:after,
.SearchedArticleItem_ItemAvailability__3AiHS:hover .SearchedArticleItem_ItemAvailabilityTooltipText__IV6gP,
.SearchedArticleItem_ItemAvailability__3AiHS:hover .SearchedArticleItem_ItemAvailabilityTooltipText__IV6gP:after
{
    visibility: visible;
    opacity: 1;
    transition: 600ms ease-out;
}

.SearchedArticleItem_SearchedArticleItem__T4cMP .SearchedArticleItem_LoyaltyPointsIcon__X-lqm {
    position: absolute;
    top: 50px;
    right: 50px;
}

.SearchedArticleItem_OriginalPrice__FXXbP {
    display: flex;
    color: #88959E;
    font-size: 16px;
    text-decoration: line-through;
    justify-content: center;
}

.SearchedArticleItem_SqBox__-1rEh {
    position: relative;
}

.SearchedArticleItem_TooltipSBText__1M8sL {
    position: absolute;
    visibility: hidden;
    right: -20px;
    /*left: -10.65rem;*/
    top: 38px;
}

.SearchedArticleItem_TooltipSBText__1M8sL::after {
    /* position tooltip correctly */
    right:10%;
    /* vertically center */
    bottom: 98%;
}

.SearchedArticleItem_SqBox__-1rEh:hover .SearchedArticleItem_TooltipSBText__1M8sL,
.SearchedArticleItem_SqBox__-1rEh:hover .SearchedArticleItem_TooltipSBText__1M8sL:after
{
    visibility: visible;
    opacity: 1;
    transition: 600ms ease-out;
}
.SearchedArticleItemMobile_SearchedArticleItemMobile__1AKct {
    /*box-shadow: 0 0 0.8rem #cccccc;*/
    /*box-shadow: rgb(239, 239, 239) 0.313rem 0.313rem 1.313rem 0.375rem;*/
    background-color: white;
    background-blend-mode: overlay;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    /*align-items: center;*/
    margin-bottom: 30px;
    padding: 0.2rem 0.3rem 1rem 0.3rem;
    border: 1px solid #e2e8ecff;
    position: relative;
    border-radius: 6px;
    width: 43vw;
    margin-right: 0.75rem;
    margin-left: 0.75rem;
}

.SearchedArticleItemMobile_SearchedArticleMobile__3SZcG {
    /*border: 2px solid #88959eff;*/  /* ff na kraju verovatno greskom dodato - treba #88959e */
    border: 2px solid #abb4bb;  /* postavljena malo svetlija nijansa, na zahtev Ferenca */
}

.SearchedArticleItemMobile_SearchedArticleItemMobile__1AKct:hover{
    /*border: 1px solid #adadad;*/
    box-shadow: 0 0 1.2rem #acaaaa;
    transition: 400ms ease-out;
}

/*.ArticleItem:hover {*/
/*    !*border: 1px solid #416CAA;*!*/
/*    !*border: 2px solid rgba(65, 108, 170, 0.5);*!*/
/*    background-color: #faf9f1;*/
/*    transition: 400ms ease-out;    */
/*}*/

.SearchedArticleItemMobile_SearchedArticleItemMobile__1AKct .SearchedArticleItemMobile_Selected__3chWx {
    background-color: #f5f5f6;
    box-shadow: 0 0 1.2rem #898787;
}

.SearchedArticleItemMobile_SearchedArticleItemMobile__1AKct .SearchedArticleItemMobile_ItemPicture__1D-lt {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
}

.SearchedArticleItemMobile_SearchedArticleItemMobile__1AKct .SearchedArticleItemMobile_ItemPicture__1D-lt img {
    max-height: 100%;
    max-width: 100%;
}

.SearchedArticleItemMobile_SearchedArticleItemMobile__1AKct .SearchedArticleItemMobile_ArticleNameWrapper__WHXpR {
    height: 3.58rem;
    width: 100%;
    margin: 0 auto;
}


.SearchedArticleItemMobile_SearchedArticleItemMobile__1AKct .SearchedArticleItemMobile_ArticleName__24vLN {
    color: black;
    font-size: 14px;
    margin-bottom: 0.5rem;
    text-align: left;
    padding: 0 0.5rem 0;
    /*cursor: pointer;*/
}

.SearchedArticleItemMobile_SearchedArticleItemMobile__1AKct .SearchedArticleItemMobile_ItemPrice__1-lEt {
    color: #121010;
    font-size: 14px;
    margin: 0;
}

.SearchedArticleItemMobile_SearchedArticleItemMobile__1AKct .SearchedArticleItemMobile_RsdSpan__1m7g9 {
    font-size: 12px;
    margin: 0
}

.SearchedArticleItemMobile_SearchedArticleItemMobile__1AKct .SearchedArticleItemMobile_ArticleItemPriceRegulativa__1n547 {
    font-size: 12px;
    color: #4D5055;
}

.SearchedArticleItemMobile_SearchedArticleItemMobile__1AKct .SearchedArticleItemMobile_BottomLineWrapper__29uBw {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    bottom: 1rem;
    justify-content: center;
    height: 2rem;
    border-top: 1px solid lightgray;
    padding-top: 1rem;
}

.SearchedArticleItemMobile_SearchedArticleItemMobile__1AKct .SearchedArticleItemMobile_MoreDetails__1KHs1 {
    /*font-size: 12px;*/
    display: none;
}

.SearchedArticleItemMobile_SearchedArticleItemMobile__1AKct .SearchedArticleItemMobile_StoreItemListView__29H1Q p {
    color: #535358;
    font-size: 1rem;
}

.SearchedArticleItemMobile_SearchedArticleItemMobile__1AKct .SearchedArticleItemMobile_StoreItem__SPh49 .SearchedArticleItemMobile_StoreItemGridView__2N5A- .SearchedArticleItemMobile_DisplaySpaceBetweenGridView__-aWk0 {
    display: flex;
    vertical-align: center;
}

.SearchedArticleItemMobile_SearchedArticleItemMobile__1AKct .SearchedArticleItemMobile_AdditionalInfo__26s4w {
    width: 100%;
    box-sizing: border-box;
    padding: 0 0.5rem;
    margin-top: auto;
}

.SearchedArticleItemMobile_SearchedArticleItemMobile__1AKct .SearchedArticleItemMobile_AdditionalInfoListView__2PzQR {
    width: 15rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.SearchedArticleItemMobile_SearchedArticleItemMobile__1AKct .SearchedArticleItemMobile_LocationAndAddress__198Cl {
    width: 25rem;
}

.SearchedArticleItemMobile_SearchedArticleItemMobile__1AKct .SearchedArticleItemMobile_ListViewPhoneNumber__2oZF3 {
    width: 12rem;
}

.SearchedArticleItemMobile_SearchedArticleItemMobile__1AKct .SearchedArticleItemMobile_AdditionalInfoGridView__1nwfg .SearchedArticleItemMobile_Access__2vLT8,
.SearchedArticleItemMobile_SearchedArticleItemMobile__1AKct .SearchedArticleItemMobile_AdditionalInfoListView__2PzQR .SearchedArticleItemMobile_Access__2vLT8 {
    color: #008D36;
}

.SearchedArticleItemMobile_SearchedArticleItemMobile__1AKct .SearchedArticleItemMobile_AdditionalInfo__26s4w .SearchedArticleItemMobile_Denied__3wHFs {
    color: #E53132;
}

.SearchedArticleItemMobile_SearchedArticleItemMobile__1AKct .SearchedArticleItemMobile_StoreItemListView__29H1Q i,
.SearchedArticleItemMobile_SearchedArticleItemMobile__1AKct .SearchedArticleItemMobile_StoreItemGridView__2N5A- i{
    line-height: 0.4;
}

.SearchedArticleItemMobile_SearchedArticleItemMobile__1AKct .SearchedArticleItemMobile_TooltipText__2YjTk {
    visibility: hidden;
    display: flex;
    justify-content: center;
    align-self: center;
    /*width: content-box;*/
    font-size: 0.875rem;
    font-weight: bold;
    background-color: rgba(65,108,170,0.8);
    color: white;
    border-radius: 15px 15px 0 15px;
    padding: 14px 14px;
    box-shadow: 0 0 0.8rem #cccccc;
    box-sizing: border-box;


    /* Position the tooltip */
    position: absolute;
    left: 0;
    top: -1.6rem;
    z-index: 999;
    opacity: 0;
    align-items: center;
}

.SearchedArticleItemMobile_SearchedArticleItemMobile__1AKct .SearchedArticleItemMobile_StoreItemGridView__2N5A-:hover .SearchedArticleItemMobile_TooltipText__2YjTk {
    visibility: visible;
    opacity: 1;
    transition: 600ms ease-out;
}

.SearchedArticleItemMobile_SearchedArticleItemMobile__1AKct .SearchedArticleItemMobile_IconStyle__2_cZG {
    /*fill: white;*/
    height: 16px;
    width: 16px;
    stroke-width: 1;
    padding-right: 0.25rem;
    /*margin: auto;*/
}

.SearchedArticleItemMobile_SearchedArticleItemMobile__1AKct .SearchedArticleItemMobile_ArticleItem__18jUF .SearchedArticleItemMobile_BottomHolder__9Q7Ov {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    padding-bottom: 1rem;
    margin-bottom: 1rem;
}

.SearchedArticleItemMobile_SearchedArticleItemMobile__1AKct .SearchedArticleItemMobile_ArticleInfoIcon__1qPqh:hover {
    cursor: pointer;
}

.SearchedArticleItemMobile_SearchedArticleItemMobile__1AKct .SearchedArticleItemMobile_ArticleItem__18jUF  .SearchedArticleItemMobile_PriceButton__39aB6 {
    color: #416CAA;
    font-size: 1.125rem;
    min-height: 3.58rem;
    text-decoration: underline;
    cursor: pointer;
}

.SearchedArticleItemMobile_SearchedArticleItemMobile__1AKct .SearchedArticleItemMobile_ItemAvailability__1-Rdw {
    position: absolute;
    right: 0;
}


@media screen and (min-width: 700px) {
    .SearchedArticleItemMobile_SearchedArticleItemMobile__1AKct {
        width: 18.938rem;
        height: 408px;
        margin-right: 30px;
        margin-left: 0;
        padding: 1rem 1.3rem 1rem 1.3rem;
    }

    .SearchedArticleItemMobile_SearchedArticleItemMobile__1AKct .SearchedArticleItemMobile_ItemPicture__1D-lt {
        height: 13rem;
        padding: 1rem;
    }

    .SearchedArticleItemMobile_SearchedArticleItemMobile__1AKct .SearchedArticleItemMobile_ArticleName__24vLN {
        font-size: 16px;
        font-weight: 700;
    }

    .SearchedArticleItemMobile_SearchedArticleItemMobile__1AKct .SearchedArticleItemMobile_ItemPrice__1-lEt {
        font-size: 1.125rem;
    }

    .SearchedArticleItemMobile_SearchedArticleItemMobile__1AKct .SearchedArticleItemMobile_ArticleItemPriceRegulativa__1n547 {
        font-size: 16px;
    }

    .SearchedArticleItemMobile_SearchedArticleItemMobile__1AKct .SearchedArticleItemMobile_BottomLineWrapper__29uBw {
        justify-content: flex-start;
    }

    .SearchedArticleItemMobile_SearchedArticleItemMobile__1AKct .SearchedArticleItemMobile_MoreDetails__1KHs1 {
        cursor: pointer;
        font-size: 16px;
        color: #219EBC;
    }
    .SearchedArticleItemMobile_SearchedArticleItemMobile__1AKct .SearchedArticleItemMobile_RsdSpan__1m7g9 {
        font-size: 16px;
        margin: 0
    }
}

@media screen and (max-width: 699px){
    .SearchedArticleItemMobile_SearchedArticleItemMobile__1AKct .SearchedArticleItemMobile_ItemAvailability__1-Rdw {
        top: -10rem;
    }
}

.SearchedArticleItemMobile_SearchedArticleItemMobile__1AKct .SearchedArticleItemMobile_ItemAvailabilityTooltipText__zTPgt {
    /* Position the tooltip */
    left: -11rem;
    top: 2.2rem;
}

.SearchedArticleItemMobile_SearchedArticleItemMobile__1AKct .SearchedArticleItemMobile_ItemAvailabilityTooltipText__zTPgt::after {
    /* position tooltip correctly */
    left:80%;

    /* vertically center */
    bottom: 94%;
}

.SearchedArticleItemMobile_SearchedArticleItemMobile__1AKct .SearchedArticleItemMobile_ItemAvailability__1-Rdw:hover .SearchedArticleItemMobile_ItemAvailabilityTooltipText__zTPgt,
.SearchedArticleItemMobile_SearchedArticleItemMobile__1AKct .SearchedArticleItemMobile_ItemAvailability__1-Rdw:hover .SearchedArticleItemMobile_ItemAvailabilityTooltipText__zTPgt:after
{
    visibility: visible;
    opacity: 1;
    transition: 600ms ease-out;
}

.SearchedArticleItemMobile_SearchedArticleItemMobile__1AKct .SearchedArticleItemMobile_LoyaltyPointsIcon__1ncOB {
    position: absolute;
    right: 10px;
    top: 10px;
}

.SearchedArticleItemMobile_SearchedArticleItemMobile__1AKct .SearchedArticleItemMobile_PriceAndDetailsHolder__2S_vT {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 8px;
}

.SearchedArticleItemMobile_SearchedArticleItemMobile__1AKct .SearchedArticleItemMobile_RpBadge__dvjpd {
    position: absolute;
    right: 0;
    top: 0;
    width: 0;
    height: 0;
    border-top: 60px solid transparent;
    border-bottom: 60px solid transparent;
    border-left: 60px solid #fc6d6dff;
}

.SearchedArticleItemMobile_SearchedArticleItemMobile__1AKct .SearchedArticleItemMobile_QuantityInCart__2Vs4x {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: -11px;
    right: -11px;
    border-radius: 50%;
    background-color: #219EBC;
    color: white;
    width: 22px;
    height: 22px;
}

.SearchedArticleItemMobile_SqBox__3HeEy {
    position: relative;
}

.SearchedArticleItemMobile_TooltipSBText__mJUVf {
    position: absolute;
    visibility: hidden;
    right: -20px;
    /*left: -10.65rem;*/
    top: 38px;
}

.SearchedArticleItemMobile_TooltipSBText__mJUVf::after {
    /* position tooltip correctly */
    right:10%;
    /* vertically center */
    bottom: 98%;
}

.SearchedArticleItemMobile_SqBox__3HeEy:hover .SearchedArticleItemMobile_TooltipSBText__mJUVf,
.SearchedArticleItemMobile_SqBox__3HeEy:hover .SearchedArticleItemMobile_TooltipSBText__mJUVf:after
{
    visibility: visible;
    opacity: 1;
    transition: 600ms ease-out;
}
.ShopFilter_ShopFilter__2i0Ev {
    display: flex;
    flex-direction: column;
    width: 100vw;
    max-height: 600px;
    cursor: default;
}

@media screen and (min-width: 700px){
    .ShopFilter_ShopFilter__2i0Ev {
        width: 270px;
    }

    .ShopFilter_ShopFilter__2i0Ev::after {
        /* the arrow */
        content: "";
        border:10px solid rgb(74,79,84);
        border-color: transparent transparent white transparent;
        position: absolute;
        top: -22px;
        left: 50px;
    }
}

.ShopFilter_ShopFilter__2i0Ev .ShopFilter_RootCategoryWrapper__3Zvhk {
    flex-grow: 1;
    background-color: rgb(246, 248, 249);
    display: flex;
    flex-direction: column;
}

.ShopFilter_ShopFilter__2i0Ev .ShopFilter_RootCategoryList__103GY {
    padding-top: 12px;

}

.ShopFilter_ShopFilter__2i0Ev .ShopFilter_RootCategoryList__103GY p {
    margin: 4px 0 0 0;
    padding: 8px 16px;
    max-height: 36px;
    font-weight: normal;
    background-color: white;
}

.ShopFilter_ShopFilter__2i0Ev .ShopFilter_SubCategoriesWrapper__22u7h {
    flex-grow: 4;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
}

.ShopFilter_ShopFilter__2i0Ev .ShopFilter_SubCategoryWrapper__abyFi {
    /*width: 20%;*/
    margin-bottom: 16px;
    text-align: left;
    padding-left: 24px;
    display: block;
    cursor: pointer;
    font-size: 14px;
    box-sizing: border-box;
}

.ShopFilter_ShopFilter__2i0Ev .ShopFilter_SubCategoryTitle__1a_2M {
    font-weight: bold;
    font-size: 16px;
    padding: 16px;
}

.ShopFilter_ShopFilter__2i0Ev .ShopFilter_SubCategoryList__1BvnR {
    display: flex;
    flex-flow: column wrap;
    /*font-weight: bold;*/
    height: 100%;
    width: 100%;
    align-content: flex-start;
}

.ShopFilter_ShopFilter__2i0Ev .ShopFilter_ActiveFilter__1IFiC {
    color: #00A4B4;
}

.ShopFilter_RadioButtonDiv__3tUYw {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    padding: 0.25rem 0;
    cursor: pointer;
}
.ArticleCategory_ArticleCategory__1eWUg {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 32px;
    color: #000000;
    stroke: #00B2B1;
    padding: 0 1rem;
    box-sizing: border-box;
    /*border-bottom: 1px solid #00B2B1;*/
    cursor: pointer;
}

.ArticleCategory_ArticleCategoryName__3us5n {
    font-size: 16px;
}

.ArticleCategory_ArticleCategorySelected__1WVKJ {
    background-color: #00B2B1;
    color: white;
    stroke: white;
}
.ArticleCategories_ArticleCategories__1WZqL {
    width: 340px;
    border: 1px solid #00B2B1;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    position: absolute;
    left: 144px;
    top: 8px;
    z-index: 31;
    background-color: white;
    font-size: 14px;
}

.ArticleCategories_ArticleSubcategories1__2lmaV {
    position: absolute;
    top: 8px;
    left: 483px;
    width: 340px;
    border: 1px solid #00B2B1;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    z-index: 31;
    background-color: white;
    font-size: 14px;
}

.ArticleCategories_ArticleSubcategories2__l1Vd7 {
    position: absolute;
    top: 8px;
    left: 822px;
    min-width: 340px;
    height: 560px;
    border: 1px solid #00B2B1;
    background-color: white;
    z-index: 31;
    font-size: 14px;
}
.ArticleSubcategories2_ArticleSubcategories2__oCQmD {
    display: flex;
    align-items: center;
    height: 32px;
    padding: 0 16px;
    box-sizing: border-box;
    cursor: pointer;
}

.ArticleSubcategories2_ArticleSubcategories2__oCQmD:hover {
    color: #00B1B0;
}
.ArticleCategoryRes_ArticleCategoryRes__1C_Vh {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /*width: 100%;*/
    height: 35px;
    color: #000000;
    stroke: #00B2B1;
    padding: 0 1rem;
    box-sizing: border-box;
    /*border-bottom: 1px solid #00B2B1;*/
    cursor: pointer;
}

.ArticleCategoryRes_ArticleCategoryName__12iov {
    font-size: 16px;
}

.ArticleCategoryRes_ArticleCategorySelected__1Uqyv {
    /*background-color: #00B2B1;*/
    color: #00B2B1;
    stroke: white;
}
.ArticleCategoriesRes_ArticleCategoriesRes__12aiT {
    position: fixed;
    top: 44px;
    left: 0;
    height: calc(100vh - 44px);
    width: 100%;
    z-index: 50;
    background-color: white;
}

.ArticleCategoriesRes_CategoryHeader__11Scz {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
    padding: 0 8px 0 16px;
    border-bottom: 1px solid lightgray;
}

.ArticleCategoriesRes_Selected__3Q5kQ {
    background-color: #00B2B1;
    color: white;
}
.UnknownItem_UnknownItem__1wHmS {
    display: flex;
    flex-direction: column;
    font-size: x-large;
    color: #535358;
}

.UnknownItem_UnknownItemSearchResult__3_LTp {
    padding: 0 30px;
    display: flex;
    justify-content: space-between;
}

.UnknownItem_SearchResult0__U51dc{
    justify-content: flex-start;
    color: #416CAA;
}

.UnknownItem_SearchResultUnavailable__1c7o6{
    justify-content: flex-end;
    color: #E53132;
}

.UnknownItem_UnknownItem__1wHmS p {
    font-size: 14px;
}
.ShopPage_ShopPage__2EG9I {

}

/*.DeleteIcon {*/
/*    fill: #E63133;*/
/*    height: 26px;*/
/*    width: 27px;*/
/*    !*fill: white;*!*/
/*}*/

/*.DeleteIcon:hover {*/
/*    fill: #ce0305;*/
/*}*/

.ShopPage_CartHeader__3jPzx {
    padding: 16px 24px;
    border-bottom: 1px solid #e2e8ecff;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 75px;
    box-sizing: border-box;
    background-color: #00B2B1;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
    color: white;
}

.ShopPage_CartHeader__3jPzx span {
    font-size: 26px;
}

.ShopPage_IconStyle__1Cd_L {
    height: 24px;
    width: 24px;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    stroke: white;
}

.ShopPage_CartActionsHolder__VDbss {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 0 24px;
    height: 94px;
    border-top: 1px solid #E2E8EC;
}

.ShopPage_ClearCart__2TFVf {
    cursor: pointer;
    color: #219ebcff;
    stroke: #219ebcff;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    border: none;
    background: none;
}

.ShopPage_ClearCartDisabled__1Bf3f {
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    color: #888888;
    stroke: #888888;
    border: none;
    background: none;
}

.ShopPage_Breadcrumbs__oz-LY {
    display: flex;
    color: #00B1B0;
    font-weight: bold;
}

.ShopPage_Breadcrumbs__oz-LY .ShopPage_BreadcrumbText__3p1jX {
    cursor: pointer;
}
.ResetPasswordPane_ResetPasswordPane__2OeUF {
    padding-top: 100px;
    height: calc(100% - 5.25rem);
    width: 100%;
    display: flex;
    justify-content: center;
}

.ResetPasswordPane_ResetPasswordPaneHolder__2UrxY {
    width: 500px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.ResetPasswordPane_Input__221jE {
    padding: 10px 0;
    width: 100%;
}

.ResetPasswordPane_ResetPasswordButtonHolder__N82nS {
    display: flex;
    justify-content: center;
}


.ResetPasswordPane_ResetPasswordButton__3pkQN:hover {
    background-color: #4a76af;
}

@media screen and (max-width: 699px) {
    .ResetPasswordPane_ResetPasswordPaneHolder__2UrxY {
        width: 100%;
        margin: 0 auto;
    }
    .ResetPasswordPane_Input__221jE {
        width: 90%;
    }
    form {
        width: 100%;
    }
}
.ForgotPasswordPane_ForgotPasswordPane___9A7S {
    padding-top: 100px;
    display: flex;
    justify-content: center;
}

.ForgotPasswordPane_ForgotPasswordPaneHolder__39Blr {
    width: 500px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.ForgotPasswordPane_InfoMessageHolder__3CSLO {
    height: 3rem;
}

.ForgotPasswordPane_InfoMessageHolder__3CSLO p {
    margin: 0;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #008D36;
}

@media screen and (max-width: 699px) {
    .ForgotPasswordPane_ForgotPasswordPane___9A7S {
        padding: 100px 16px 0;
        width: 90vw;
    }

    .ForgotPasswordPane_ForgotPasswordPaneHolder__39Blr {
        width: 100%;
    }
    .ForgotPasswordPane_ForgotPasswordPaneHolder__39Blr h2 {
        text-align: center;
    }

}
.SubNavigation_SubNavigationMenuWrapper__1GMCS {
    width: 90%;
    margin: auto;
    display: flex;
    justify-content: center;
}

.SubNavigation_SubNavigationMenuWrapper__1GMCS nav ul {
    width: 100%;
    display: flex;
    justify-content: center;
    list-style-type: none;
    margin-top: 1rem;
    padding: 0;
    overflow: hidden;
}

@media screen and (min-width: 700px) {
    .SubNavigation_SubNavigationMenuWrapper__1GMCS {
        width: 100%;
        height: 3rem;
        /*border-bottom: none;*/
    }

    .SubNavigation_SubNavigationMenuWrapper__1GMCS nav ul {
        width: 70rem;
        margin-top: 6rem;
    }
}

nav ul li a {
    display: block;
    color: grey !important;
    text-align: center;
    padding: 16px;
    text-decoration: none;
}

.SubNavigation_LinkTextWrapper__3FD6z {
    cursor: pointer;
    width: 100%;
    position: relative;
    /*z-index: 1;*/
    display: flex;
    justify-content: center;

}

.SubNavigation_SubNavigation__1f_E3 {
    cursor: pointer;
    width: 100%;
    position: relative;
    /*z-index: 1;*/
    display: flex;
    justify-content: center;
    border-bottom: 3px transparent;
}

.SubNavigation_SubNavigation__1f_E3:hover:after  {
    content: ""; /* This is necessary for the pseudo element to work. */
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    /*display: block; !* This will put the pseudo element on its own line. *!*/
    margin: 0 auto; /* This will center the border. */
    width: 5px; /* Change this to whatever width you want. */
    padding-top: 20px; /* This creates some space between the element and the border. */
    border-bottom: 3px solid grey; /* This creates the border. Replace black with whatever color you want. */
}

/* ovo se ucitava inicijalno, kada se pristupi strani /myprofile */
.SubNavigation_Selected__sbuDw:after {
    content: ""; /* This is necessary for the pseudo element to work. */
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0 auto; /* This will center the border. */
    width: 30px; /* Change this to whatever width you want. */
    padding-top: 20px; /* This creates some space between the element and the border. */
    border-bottom: 3px solid grey; /* This creates the border. Replace black with whatever color you want. */

}

.SubNavigation_Selected__sbuDw:focus:after {
    content: ""; /* This is necessary for the pseudo element to work. */
    position: absolute;
    bottom: 0;
    margin: 0 auto; /* This will center the border. */
    width: 30px; /* Change this to whatever width you want. */
    padding-top: 20px; /* This creates some space between the element and the border. */
    border-bottom: 3px solid grey; /* This creates the border. Replace black with whatever color you want. */
    transition: width .2s ease-out;
}

.MyProfilePaneContentHolder_MyProfilePaneHolder__1iLmj {
    padding: 0 1rem;
}

.MyProfilePaneContentHolder_MyProfilePane__kMwUq {
    display: flex;
    flex-direction: column;
    justify-content: left;
    background-color: white;
    border-radius: 6px;
    font-size: 14px;
}

@media screen and (min-width: 700px){
    .MyProfilePaneContentHolder_MyProfilePaneHolder__1iLmj {
        padding: 8.25rem 32px;
    }

    .MyProfilePaneContentHolder_MyProfilePane__kMwUq {
        padding: 2rem 70px;
        border: 1px solid #e2e8ecff;
        min-height: 40vh;
    }
}
.MyProfilePane_MyProfilePaneHeader__1TnhV {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 5rem;
}

.MyProfilePane_MyProfileInformation__2V0CB {
    display: flex;
    flex-direction: column;
    width: 50%;
    margin-right: 40px;
}

.MyProfilePane_MyProfileInformation__2V0CB h2 {
    font-weight: 500;
}

.MyProfilePane_MyProfileInformation__2V0CB .MyProfilePane_ProfileTitle__19r8D {
    display: flex;
    align-items: center;
}

.MyProfilePane_MyProfileInformation__2V0CB .MyProfilePane_UserInformation__3rKqO {
    background-color: #f1f4f6ff;
    padding: 48px;
    margin-top: 1rem;
    border-radius: 6px;
}

.MyProfilePane_MyProfileInformation__2V0CB .MyProfilePane_DeleteProfile__ji9DV {
    color: #535358;
    margin-top: 3rem;
}

.MyProfilePane_MyProfilePaneHeaderMobile__y_x9Z .MyProfilePane_DeleteProfile__ji9DV, .MyProfilePane_DeleteProfile__ji9DV h2, .MyProfilePane_DeleteProfile__ji9DV p {
    display: block;
    color: #535358;
}

.MyProfilePane_MyAddresses__3XJuK {
    display: flex;
    flex-direction: column;
    width: 50%;
    margin-left: 40px;
}

.MyProfilePane_MyProfilePaneHeaderMobile__y_x9Z {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.MyProfilePane_MyProfilePaneHeaderUserName__1CKhd {
    display: flex;
    justify-content: center;
    align-items: center;
}
.AddAddressForm_AddressFormRow__2v6JH {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.AddAddressForm_TextBoxStyle__1mC0h {
    padding-top: 10px;
    height: 2.6rem;
    display: flex;
}

.AddAddressForm_Label__1U9tk {
    margin-right: 0.5rem;
    padding-top: 0.5rem;
    min-width: 2.5rem;
    font-size: 14px;
    white-space: nowrap;
}

.AddAddressForm_Input__3-PGX {
    color: #5b6977ff;
    font-size: 16px;
    text-align: start;
    height: 32px;
    border-radius: 3px !important;
    border: 1px solid #e2e8ec;
    width: 100%;
    box-sizing: border-box;
    padding: 20px;
}

.AddAddressForm_inputVerificationHolder__2gO7O {
    display: flex;
    flex-direction: column;
}

.AddAddressForm_ErrorMessage__2ccQr {
    color: red;
    font-size: 10px;
    width: 90%;
    margin-top: 0.2rem;
}
.MyAddress_AddressCard__3Vbfd {
    box-sizing: border-box;
    border: 1px solid #e2e8ecff;
    border-radius: 6px;
    min-height: 30px;
    height: -webkit-max-content;
    height: max-content;
    padding: 1rem 2rem;
    margin: 0 auto 2rem;
    color: #535358;
    display: flex;
    flex-direction: column;
}

@media screen and (max-width: 699px){
    .MyAddress_AddressCard__3Vbfd {
        background-color: #F1F4F6;
        margin: 0 auto 1rem;
    }
}

.MyAddress_AddressHeader__ApTzx {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.MyAddress_AddressHeader__ApTzx h3 {
    font-size: 21px;
    font-weight: 500;
    color: #223d62ff;
    margin: 12px 0;
}

.MyAddress_AddressWrapper__3VOm1 {
    min-height: 30px;
    height: -webkit-max-content;
    height: max-content;
    display: flex;
    flex-direction: row;
    margin-bottom: 1rem;
}

.MyAddress_AddressPart__3JXmY {
    width: 85%;
    font-size: 16px;
}

.MyAddress_AddressPart__3JXmY p {
    margin: 8px 0 0;
}

.MyAddress_EditAddress__3Dcns:hover {
    color: #416CAA;
    cursor: pointer;
}
.MyAddresses_AddressesHeader__hQEaR {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin: 1rem 0;
}

.MyAddresses_AddressesHeader__hQEaR h2 {
    font-weight: 500;
}

.MyAddresses_AddressesHolder__17iuP {
    margin-top: 1rem;
}

@media screen and (min-width: 700px){
    .MyAddresses_AddressesHeader__hQEaR {
        display: flex;
        justify-content: space-between;
    }
}
.UserInfoSection_UserInfo__3gH-r {
    display: flex;
    flex-direction: column;
    color: #535358;
}

.UserInfoSection_Filed__22aLc {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: .5rem;
    margin-top: .5rem;
    position: relative;
    width: 100%;
    color: #4D5055;
}

.UserInfoSection_FiledNameAndSurname__1KUUY {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: .5rem;
    margin-top: .5rem;
    position: relative;
    width: 100%;
}

.UserInfoSection_Label__326z4 {
    margin: 1.5rem 0 1rem;
    font-size: 18px;
}

.UserInfoSection_Information__3Ogzz {
    padding-left: 1rem;
    font-size: 16px;
}

.UserInfoSection_NameAndSurname__1BLRg {
    color: #013A63;
    font-size: 18px;
    font-weight: bold;
    margin-top: 1rem;
    margin-bottom: .5rem;
}

.UserInfoSection_NameAndSurnameEditButton__3Des8 {
    position: absolute;
    right: 0;
    top: 12px;
    background-color: transparent;
    border-radius: 6px;
}

.UserInfoSection_PhoneNumberEditButton__CoOSl {
    position: absolute;
    right: 0;
    top: -6px;
    background-color: transparent;
    border-radius: 6px;
}

@media screen and (min-width: 700px) {
    .UserInfoSection_Filed__22aLc {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        border: 1px solid #e2e8ecff;
        border-radius: 6px;
        padding-left: 1rem;
        height: 60px;
    }

    .UserInfoSection_Label__326z4 {
        margin: 1.5rem 0 1rem;
        font-size: 18px;
    }

    .UserInfoSection_Information__3Ogzz {
        padding-left: 1rem;
        font-size: 16px;
    }
}
.EditProfileModal_EditInputFiled__3Jjoj {
    width: 100%;
    border: 1px solid #f1f4f6ff;
    border-radius: 3px;
    box-sizing: border-box;
    padding: 1rem;
    margin-top: 1rem;
    font-size: 16px;
    color: #5b6977ff;
}

.EditProfileModal_ButtonsHolder__1CUIL {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.EditProfileModal_BottomMessageHolder__3XhD3 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.EditProfileModal_BadPasswordMsg__1lP-t {
    color: red;
    font-size: 12px;
}
.Order_Order__1omkt {
    border-radius: 3px;
    height: -webkit-max-content;
    height: max-content;
    border: 1px solid #e1e5e9ff;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    margin-top: 2rem;
    color: #4d5055ff;
}

.Order_Order__1omkt:hover {
    box-shadow: 3px 3px 10px #e1e5e9ff;
}

.Order_OrderHeader__2qx0N {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 117px;
}

.Order_OrderHeader__2qx0N .Order_OrderInfoPart__1S6Ud {
    padding: 1rem .5rem;
    display: flex;
    justify-content: space-between;
    width: 75%;
    height: 100%;
    box-sizing: border-box;
}

.Order_OrderInfoPartSection__1hYBm {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.Order_OrderInfoPartSectionTitle__3cKSm {
    font-size: 14px;
    margin-bottom: 5px;
}

.Order_OrderInfoPartSectionAddress__3mI82 {
    font-size: 12px;
    cursor: pointer;
    stroke: #4a4f54ff;
    display: flex;
    align-items: center;
    text-decoration: underline;
}

@media screen and (min-width: 700px) {
    .Order_OrderHeader__2qx0N .Order_OrderInfoPart__1S6Ud {
        padding: 1rem 1.5rem;
        display: flex;
        justify-content: space-between;
        width: 75%;
        height: 100%;
        box-sizing: border-box;
    }

    .Order_OrderInfoPartSection__1hYBm {
        align-items: center;
    }

    .Order_OrderInfoPartSectionTitle__3cKSm {
        font-size: 18px;
        margin-bottom: 10px;
    }

    .Order_OrderInfoPartSectionAddress__3mI82 {
        font-size: 18px;
        display: flex;
        align-items: flex-start;
    }

    .Order_OrderInfoPartSectionAddress__3mI82:hover {
        color: #00B1B0;
        stroke: #00B1B0 !important;
    }
}

.Order_OrderHeader__2qx0N .Order_FeedbackPart__2uG7p {
    padding: 1rem 1.5rem;
    float: right;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 25%;
    border-left: 1px solid #e1e5e9ff;
    height: 100%;
    box-sizing: border-box;
}

.Order_OrderBody__1tgbg {
    display: flex;
}

.Order_OrderBody__1tgbg .Order_OrderList__a4Deg {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.Order_OrderBody__1tgbg .Order_OrderList__a4Deg table {
    border: none;
    border-collapse: collapse;
    width: 100%;
}

.Order_OrderBody__1tgbg .Order_OrderList__a4Deg tr {
    border-top: 1px solid #e1e5e9ff;
}

.Order_OrderBody__1tgbg .Order_OrderList__a4Deg th, td {
    padding: 12px;
}

.Order_OrderBody__1tgbg .Order_OrderTotal__t-aJv {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 25%;
    border-left: 1px solid #e1e5e9ff;
    border-top: 1px solid #e1e5e9ff;
    box-sizing: border-box;
}

.Order_OrderBody__1tgbg .Order_OrderTotal__t-aJv .Order_TotalAmount__3BXrU {
    font-size: 24px;
    margin: 0;
}

.Order_TotalAmount__3BXrU {
    font-weight: bold;
}

.Order_OrderTotalMobile__2SVnB {
    display: flex;
    justify-content: space-between;
    background-color: #F1F4F6;
    padding: 0.5rem;
}

.Order_CollapsedOrderList__CoPx5 {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 0.5rem;
}

@media screen and (min-width: 700px){

    .Order_OrderBody__1tgbg .Order_OrderList__a4Deg th, td {
        padding: 18px;
    }

    .Order_CollapsedOrderList__CoPx5 {
        justify-content: flex-start;
        width: 73%;
        font-size: 18px;
        padding: 0 0 0 26px;
        height: 112px;
    }
}

.Order_CollapsedOrderList__CoPx5:hover {
    cursor: pointer;
}

.Order_MyDropdownSlidedown__3FShR {
    display: flex;
    width: 75%;
}

.Order_BlinkOrange__1cVjg {
    width: 200px;
    height: 40px;
    background-color: orange;
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 15px;
    border-radius: 6px;
    margin-top: auto;
    margin-left: auto;
}

.Order_BlinkOrange__1cVjg span {
    font-size: 14px;
    color: white;
    -webkit-animation: Order_blink__2thBU .75s alternate infinite;
            animation: Order_blink__2thBU .75s alternate infinite;
}

.Order_BlinkRed__3W-s5 {
    width: 200px;
    height: 40px;
    background-color: red;
    padding: 15px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 15px;
    border-radius: 6px;
    margin-top: auto;
    margin-left: auto;
}

.Order_BlinkRed__3W-s5 span {
    font-size: 14px;
    color: white;
    -webkit-animation: Order_blink__2thBU .75s alternate infinite;
            animation: Order_blink__2thBU .75s alternate infinite;
}

.Order_BlinkGreen__2NSmr {
    width: 250px;
    height: 40px;
    background-color: green;
    padding: 15px;
    text-align: center;
    display: flex;
    align-items: center;
    border-radius: 6px;
    margin-top: auto;
    margin-left: auto;
}

.Order_BlinkGreen__2NSmr span {
    font-size: 14px;
    color: white;
    -webkit-animation: Order_blink__2thBU .75s alternate infinite;
            animation: Order_blink__2thBU .75s alternate infinite;
}

@-webkit-keyframes Order_blink__2thBU {
    0% {
        opacity: 0;
    }
    50% {
        opacity: .5;
    }
    100% {
        opacity: 1;
    }
}

@keyframes Order_blink__2thBU {
    0% {
        opacity: 0;
    }
    50% {
        opacity: .5;
    }
    100% {
        opacity: 1;
    }
}

.Order_NoteForUser__2MIKl {
    display: flex;
    flex-direction: column;
    margin: 1rem;
    padding: 1rem;
    border-radius: 6px;
    border: 1px solid #e1e5e9ff;
    background-color: #EFF4F6;
}

.Order_OrderRatingBadge__3lSkh {
    display: flex;
    align-items: center;
    color: #013A63;
    margin: 8px 0;
}
.OrderStatus_OrderStatus__7iLec {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.OrderStatus_Status__3vrB5 {
    font-weight: bold;
}

.OrderStatus_Message__2FaPA {
    text-align: center;
    font-size: 12px;
}

@media screen and (min-width: 700px){
    .OrderStatus_Status__3vrB5 {
        font-size: 18px;
        justify-content: center;
    }

    .OrderStatus_Message__2FaPA {
        font-size: 16px;
    }
}

.OrderStatus_ColoredStatusMobile__1Jceo {
    display: flex;
    align-items: center;
}
.Orders_NoActiveOrders__34KHj {
    color: #535358;
    font-size: 16px;
}
.MyOrders_Order__TDQjj {
    padding: 1rem 1.5rem;
    border-radius: 15px;
    width: 96%;
    height: -webkit-max-content;
    height: max-content;
    box-shadow: 0 0 0.8rem #cccccc;
    display: flex;
    flex-direction: row;
}

.MyOrders_OrderInfoPart__1Glh7 {
    width: 70%;
}

.MyOrders_OrdersPane__eedo_ {

}

.MyOrders_OrdersPane__eedo_ h2 {
    font-weight: 500;
}

.MyOrders_FeedbackPart__nQUsS {
    display: flex;
    align-items: center;
    justify-content: center;
}

.MyOrders_BlinkRed__3nTDL{
    width:200px;
    height: 20px;
    background-color: red;
    padding: 15px;
    text-align: center;
    line-height: 20px;
    border-radius: 25px;
}
.MyOrders_BlinkRed__3nTDL span{
    font-size: 14px;
    color: white;
    -webkit-animation: MyOrders_blink__1uIPY .75s alternate infinite;
            animation: MyOrders_blink__1uIPY .75s alternate infinite;
}

.MyOrders_BlinkGreen__3CHvT{
    width:200px;
    height: 20px;
    background-color: greenyellow;
    padding: 15px;
    text-align: center;
    line-height: 20px;
    border-radius: 25px;
}
.MyOrders_BlinkGreen__3CHvT span{
    font-size: 14px;
    color: white;
    -webkit-animation: MyOrders_blink__1uIPY .75s alternate infinite;
            animation: MyOrders_blink__1uIPY .75s alternate infinite;
}


@-webkit-keyframes MyOrders_blink__1uIPY{
    0%{opacity: 0;}
    50%{opacity: .5;}
    100%{opacity: 1;}
}


@keyframes MyOrders_blink__1uIPY{
    0%{opacity: 0;}
    50%{opacity: .5;}
    100%{opacity: 1;}
}

@media screen and (max-width: 699px){
    .MyOrders_OrdersPane__eedo_ h2 {
        display: none;
    }
}

.SingleTherapy_SingleTherapyWrapper__3mJJv {
    display: flex;
}

.SingleTherapy_SingleTherapy__JCXmJ {
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    border-radius: 6px;
    border: 1px solid #D0D4D9;
    padding: 24px 16px;
    margin: 1rem 0;
    width: 100%;
}

.SingleTherapy_SingleTherapy__JCXmJ:hover {
    border: 1px solid #00B2B1;
}

.SingleTherapy_ActionsWrapper__1Gsfw {
    display: flex;
}

.SingleTherapy_TherapyName__2cQ_4 {
    display: flex;
    align-items: center;
    font-size: 18px;
}

.SingleTherapy_IconStyle__bHw7U {
    stroke: #8B8C8F;
    height: 18px;
    width: 18px;
    margin: 0 16px;
    cursor: pointer;
}

.SingleTherapy_IconStyle__bHw7U:hover {
    stroke: #00B2B1;
}

.SingleTherapy_SponsoredTherapy__1PdK8 {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

@media screen and (min-width: 700px){
    .SingleTherapy_SingleTherapyWrapper__3mJJv {
        margin-right: 2rem;
    }
}
.ConfirmationModal_ConfirmationModal__2qp8W {
    box-sizing: border-box;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    width: 440px;
    background-color: white;
    z-index: 35;
    position: fixed;
    left: 5vw;
    top: 15%;
    box-shadow: 0 0 1.2rem #acaaaa;
    max-width: 90vw;
}

@media screen and (min-width: 700px){
    .ConfirmationModal_ConfirmationModal__2qp8W {
        top: 200px;
        left: calc(50% - 220px);
    }
}

.ConfirmationModal_ConfirmationModal__2qp8W .ConfirmationModal_Header__FZnyn {
    width: 100%;
    height: 60px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 10px;
    box-sizing: border-box;
    background-color: #00B2B1;
}

.ConfirmationModal_Content__3fjaC {
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    padding: 2rem;
    height: calc(100% - 60px);
    /*margin: auto;*/
    justify-content: flex-end;
    color: #5b6977ff;
}

.ConfirmationModal_ActionWrapper__1R4Ek {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 1rem;
    width: 45%;
}

@media screen and (min-width: 700px) {
    .ConfirmationModal_ActionWrapper__1R4Ek {
        width: 35%;
    }
}
.MyTherapies_MyTherapiesWrapper__35rhg {
    width: 100%;
    /*height: 100%;*/
    display: flex;
    flex-direction: column;
}

.MyTherapies_TherapyHeaderLeftHalf__2jMWq {
    width: 90vw;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: bold;
    margin-bottom: 32px;
    font-size: 18px;
}

.MyTherapies_TherapyHeaderRightSide__1Khnt {
    width: 46%;
    margin-left: 4%;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 32px;
    border-bottom: 1px solid #E2E8EC;
    font-size: 18px;
}

.MyTherapies_TherapyHeader__1uJBl {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: bold;
}

.MyTherapies_MyTherapiesWrapperScroll__1a4uW {
    overflow-y: auto;
    height: 55vh;
    width: 50%;
}

.MyTherapies_SingleTherapyWrapper__RPxeX {
    width: 90vw;
}

@media screen and (min-width: 700px){
    .MyTherapies_TherapyHeaderLeftHalf__2jMWq {
        width: 50%;
    }
}

.MyTherapies_NewTherapyMobile__1irsO {
    position: fixed;
    bottom: 0;
    left: 0;
    /*width: 100vw;*/
    background: white;
    /*z-index: 2; */
    height: calc(100vh - 44px);
}
.TherapyArticles_TherapyArticles__3omUi {
    padding-left: 32px;
    box-sizing: border-box;
    width: 50%;
    right: 0;
    top: 0;
    position: absolute;
    border-left: 1px solid #E2E8EC;
    /*overflow-y: auto;*/
    height: 55vh;
}
.TherapyArticle_TherapyArticle__RRBxE {
    border-bottom: 1px solid #E2E8EC;
    padding: 24px 0;
    margin-left: 4%;
    font-size: 14px;
    font-weight: bold;
    display: flex;
}

.TherapyArticle_Information__3_9ba {
    display: flex;
    padding-top: 1rem;
}

.TherapyArticle_ArticleLabels__37YrO {
    display: flex;
    flex-direction: column;
    border-right: 1px solid #E2E8EC;
    padding-right: 2rem;
    font-weight: bold;
    font-size: 12px;
}

.TherapyArticle_ArticleLabel__uGbM6 {
    padding-bottom: 1rem;
}

.TherapyArticle_ArticleDetails__2BOEI {
    display: flex;
    flex-direction: column;
    padding-left: 2rem;
    font-size: 12px;
    font-weight: normal;
    padding-bottom: 1rem;
}

.TherapyArticle_ArticleNameAndQtyWrapper__1s0SG {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.TherapyArticle_IconStyle__2Uk3m {
    stroke: #8B8C8F;
    height: 18px;
    width: 18px;
    cursor: pointer;
}

.TherapyArticle_IconStyle__2Uk3m:hover {
    stroke: #00B2B1;
}

.TherapyArticle_WrapperForTherapyImage__1n8DS {
    width: 100px;
}

.TherapyArticle_ImageForTherapy__2e359 {
    width: inherit;
    padding-right: 6px;
    box-sizing: border-box;
}
.NewTherapy_NewTherapyHeader__1FHE- {
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    align-items: center;
    font-weight: bold;
    margin-bottom: 32px;
    font-size: 18px;
}

.NewTherapy_NewTherapyHeaderActions__1JvVo {
    display: flex;
}

.NewTherapy_NewTherapyContent__ts5El {
    display: flex;
}

.NewTherapy_ContentLeftSide__3OJlO {
    display: flex;
    flex-direction: column;
    width: 50%;
    margin-right: 2rem;
}

.NewTherapy_ContentRightSide__3PdAZ {
    display: flex;
    flex-direction: column;
    width: 50%;
    padding-left: 32px;
    box-sizing: border-box;
    border-left: 1px solid #E2E8EC;
}

.NewTherapy_TherapyName__Qw9L4 {
    height: 3rem;
    width: 100%;
    box-sizing: border-box;
    padding: 1px 2px 1px 16px !important;
    border: 1px solid #e6e6e6;
    border-radius: 6px;
    border-bottom: 1px solid #E2E6EA;
}

.NewTherapy_TherapyName__Qw9L4:hover,
.NewTherapy_TherapyName__Qw9L4:focus,
.NewTherapy_TherapyName__Qw9L4:active {
    border: 1px solid #00B2B1;
}

.NewTherapy_NewTherapyMobileWrapper__1bsD5 {
    display: flex;
    flex-direction: column;
    color: #4a4f54ff;
    width: 90vw;
    margin: 1rem auto;
    position: relative;
    height: calc(100% - 64px);
    /*justify-content: space-between;*/
}

.NewTherapy_TherapyNameMobile__2Iok2 {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.NewTherapy_NewTherapyArticlesMobile__2n5nJ {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
}


.SearchBarForTherapy_TherapySearchBar__2nCGr {
    width: 100%;
    height: 3rem;
    border: 1px solid #e6e6e6;
    padding-left: 1rem;
    box-sizing: border-box;
}

.SearchBarForTherapy_TherapySearchBar__2nCGr:hover,
.SearchBarForTherapy_TherapySearchBar__2nCGr:active {
    border: 1px solid #00B2B1;
}


.SearchItemForTherapy_SearchItem__lwsQr {
    display: flex;
    align-items: center;
    margin: 6px;
    cursor: pointer;
}

.SearchItemForTherapy_SearchItem__lwsQr:hover {
    background-color: #E2E8EC;
}

.SearchItemForTherapy_IconWrapper__-sN9m {
    border: 1px solid #00B2B1;
    border-radius: 6px;
    margin-right: 1rem;
}

.SearchItemForTherapy_IconStyle__1-63N {
    height: 16px;
    width: 16px;
    color: #0A0C10;
    margin: 6px;
}
.SearchItemsForTherapy_SearchItemsForTherapy__3Xs8q {
    background-color: white;
    overflow-y: auto;
    /*border-radius: 2.188rem;*/
    display: flex;
    flex-direction: column;
    z-index: 21;
    box-shadow: 0 0 0.625rem -0.25rem #535358;
    max-height: 40vh;
    box-sizing: border-box;
    border-radius: 0 0 3px 3px;
    border-bottom: 1px solid #00B2B1;
    border-left: 1px solid #00B2B1;
    border-right: 1px solid #00B2B1;
    position: absolute;
    width: 100%;
}

.SearchItemsForTherapy_SearchItemsDisplayNone__1g1WW {
    display: none;
}

.SearchItemsForTherapy_ItemActive__fRZTI {
    background: #d3d3d33b;
}

.SearchItemsForTherapy_Item__b0cmS:hover {
    cursor: pointer;
}

@media screen and (min-width: 700px){
    .SearchItemsForTherapy_SearchItemsForTherapy__3Xs8q {
        max-height: 25vh;
    }
}
.AddArticleToTherapyModal_QuantityButton__YNsE1 {
    height: 24px;
    width: 24px;
    font-size: 22px;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    cursor: pointer;
}

.AddArticleToTherapyModal_QuantityInput__W8n7F {
    height:34px !important;
    width: 34px !important;
    text-align: center;
    padding: 3px !important;
    border: 1px solid #00B2B1 !important;
    border-radius: 6px !important;
}

/* uklanjanje strelica iz number input fielda */
/* Chrome, Safari, Edge, Opera */
.AddArticleToTherapyModal_QuantityInput__W8n7F::-webkit-outer-spin-button,
.AddArticleToTherapyModal_QuantityInput__W8n7F::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
.AddArticleToTherapyModal_QuantityInput__W8n7F[type=number] {
    -moz-appearance: textfield;
}
.TherapyArticlesMobile_TherapyArticlesMobile__2gCbi {
    position: fixed;
    top: 44px;
    left: 0;
    width: 100vw;
    background: white;
    /* z-index: 2; */
    height: calc(100vh - 44px);
}
.MyLoyaltyPoints_LoyaltyPageWrapper__QNFxz {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: 2rem;
}

.MyLoyaltyPoints_LoyaltyPageWrapper__QNFxz .MyLoyaltyPoints_LeftPart__MYNtF {
    display: flex;
    flex-direction: column;
    align-items: baseline;
    width: 100%;
}

.MyLoyaltyPoints_LoyaltyPageWrapper__QNFxz h2 {
    font-weight: 500;
}

.MyLoyaltyPoints_LoyaltyPageWrapper__QNFxz .MyLoyaltyPoints_LoyaltyPointsWrapper__xyFnd {
    display: flex;
    flex-direction: column;
    border-radius: 6px;
    border: 1px solid #e1e5e9ff;
    align-items: center;
    justify-content: space-between;
    padding: 8px 16px;
    width: 100%;
    font-size: 18px;
    box-sizing: border-box;
    margin-top: 1rem;
}

.MyLoyaltyPoints_LoyaltyPointsWrapper__xyFnd .MyLoyaltyPoints_Mark__3VTEx {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px;
    color: #003b63ff;
}

.MyLoyaltyPoints_LoyaltyPointsWrapper__xyFnd .MyLoyaltyPoints_Points__1whWJ {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    background-color: #FFAE00;
    color: white;
    padding: 2px 36px;
    margin-bottom: 32px;
    margin-top: 8px;
}

.MyLoyaltyPoints_Points__1whWJ .MyLoyaltyPoints_Number__3IuE7 {
    font-size: 22px;
    line-height: 48px;
    font-weight: bold;
}

.MyLoyaltyPoints_ProgressWrap__3c2_G {
    display: flex;
    position: relative;
    margin-top: 30px;
}

.MyLoyaltyPoints_LevelWrapper__3_4po {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin: 8px;
}

.MyLoyaltyPoints_LevelLabelWrapper__3jW9q {
    display: flex;
    justify-content: space-between;
    width: 330px;
    margin-top: 15px;
}

.MyLoyaltyPoints_LevelLabelText__1hSjA {
    font-weight: bold;
    color: #8B8C8F;
}

.MyLoyaltyPoints_TimeNeededText__1-sP8 {
    font-size: 14px;
}

.MyLoyaltyPoints_TimeNeededTime__p6v5b {
    color: #FFAE00;
}

@media screen and (min-width: 700px) {
    .MyLoyaltyPoints_LoyaltyPageWrapper__QNFxz {
        flex-direction: row;
    }

    .MyLoyaltyPoints_LoyaltyPageWrapper__QNFxz .MyLoyaltyPoints_LeftPart__MYNtF {
        width: 40%;
    }

    .MyLoyaltyPoints_LoyaltyPageWrapper__QNFxz .MyLoyaltyPoints_LoyaltyPointsWrapper__xyFnd {
        width: 55%;
    }

    .MyLoyaltyPoints_LoyaltyPointsWrapper__xyFnd .MyLoyaltyPoints_Points__1whWJ {
        padding: 10px 52px;
    }

    .MyLoyaltyPoints_Points__1whWJ .MyLoyaltyPoints_Number__3IuE7 {
        font-size: 36px;
        line-height: 48px;
        font-weight: bold;
    }

    .MyLoyaltyPoints_LevelLabelWrapper__3jW9q {
        width: 516px;
        margin-top: 30px;
    }
}
.LoyaltyCatalogue_CatalogueWrapper__26lCk {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-top: 1px solid #E2E8EC;
}
.ShopPageHeader_ShopPageHeader__22y2j {
    top: 70px;
    box-sizing: border-box;
    height: 450px;
    margin: 0 32px;
}

@media screen and (max-width: 699px){
    .ShopPageHeader_ShopPageHeader__22y2j {
        display: none;
    }
}

.ShopPageHeader_ShopPageHeader__22y2j .ShopPageHeader_Content__1rxA7 {
    display: flex;
    justify-content: space-between;
    height: 100%;
    align-items: center;
}

.ShopPageHeader_ShopPageHeader__22y2j .ShopPageHeader_ContentLeft__2phXe {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.ShopPageHeader_ExitAndShopBrandGroup__dEbwf {
    display: flex;
    flex-direction: row;
    margin-top: auto;
}

.ShopPageHeader_ShopPageHeader__22y2j input {
    height: 2.375rem;
    width: 35rem;
}

.ShopPageHeader_ShopPageHeader__22y2j .ShopPageHeader_ContentRight__EO6fK {
    display: flex;
    flex-direction: row;
    margin: auto 0;
}

.ShopPageHeader_ShopHQ__ftTgL {
    color: white;
    margin: 0;
    padding-left: 1rem;
    font-size: 1.25rem;
}

.ShopPageHeader_ShopName__35XZ8 {
    color: white;
    margin: 0;
    padding-left: 1rem;
    font-size: 2rem;
}

.ShopPageHeader_ShopExitButton__1Rxac {
    font-size: 0.875rem;
    background-color: transparent;
    color: white;
    margin-top: 0.425rem;
    padding: 0.5rem;
    border-radius: 0.625rem;
    border: 1px solid white;
    text-align: center;
}

.ShopPageHeader_ShopExitButton__1Rxac:hover {
    cursor: pointer;
    background-color: white;
    color: #416caa;

}

/*.ExitIcon {*/
/*    fill: white;*/
/*    size: 24px;*/
/*}*/

/*.ExitIcon:hover {*/
/*    fill: #416caa;*/
/*}*/

/*.IconStyle {*/
/*    fill: white;*/
/*    height: 20px;*/
/*    width: 20px;*/
/*    transform: rotate(180deg);*/
/*    !*stroke-width: 1;*!*/
/*    !*margin: auto;*!*/
/*}*/
.ShopPageHeader_IconStyle__3qdpc {
    fill: white;
    height: 32px;
    width: 32px;
    stroke-width: 1;
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
    /*margin: auto;*/
}

.ShopPageHeader_CommercialImage__3wKFy {
    display: flex;
    flex-direction: column;
    height: 100%;
    cursor: pointer;
}
/*.ShopInfoBox {*/
/*    background-color: white;*/
/*    border-radius: 6px;*/
/*    width: 350px;*/
/*    height: 180px;*/
/*    padding: 20px;*/
/*    box-sizing: border-box;*/
/*    margin: auto;*/
/*    display: flex;*/
/*    flex-direction: column;*/
/*    justify-content: flex-start;*/
/*}*/

/*.ShopInfoBox .UpperPart {*/
/*    height: 130px;*/
/*}*/

.ShopInfoBox_ShopInfoBoxHeader__3EPuZ {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 0;
    justify-content: space-between;
}

.ShopInfoBox_ShopInfoHeaderMobile__74g_3 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 0.75rem;
}

.ShopInfoBox_DetailsAndBadgesMobile__qn3yn {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 0;
}

.ShopInfoBox_ShopName__mfDcn p {
    font-size: 18px;
    font-weight: bold;
    color: #4a4f54ff;
    margin: 0;
}

.ShopInfoBox_ShopName__mfDcn p:hover {
    cursor: pointer;
}

.ShopInfoBox_ShopExitIcon__5Lakg {
    display: flex;
    justify-content: center;
    align-items: center;
}

.ShopInfoBox_ShopExitIcon__5Lakg:hover {
    cursor: pointer;
}

.ShopInfoBox_ShopAddress__1nCBQ {
    color: #4a4f54ff;
    font-size: 14px;
    margin: 0;
    padding-bottom: 4px;
}

.ShopInfoBox_ShopPhoneNumber__RwpuD {
    margin: 0;
    color: #4a4f54ff;
    font-size: 14px;
    display: flex;
}

.ShopInfoBox_MobileIcon__2rKI7 {
    width: 18px;
    height: 18px;
    stroke:#013A63;
}

.ShopInfoBox_StoreLinkMobile__1bLYZ {
    display:flex;
    align-items:center;
    color:#219EBC;
    padding:0.5rem 0;
    font-size:12px;
}

.ShopInfoBox_PharmacyInfoHolderMobile__10JLZ {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-top: 12px;
    border-bottom: 1px solid #E2E6EA;
    border-top: 1px solid #E2E6EA;
}

.ShopInfoBox_WorkingHours__1EdPu {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #4a4f54ff
}


@media screen and (min-width: 700px) {
    .ShopInfoBox_ShopName__mfDcn {
        display: flex;
        align-items: center;
    }

    .ShopInfoBox_ShopName__mfDcn p {
        margin-top: 0;
        text-decoration: underline;
        line-height: 24px;
        font-size: 24px;
    }
    .ShopInfoBox_FirstRow__K-lv0 {
        display: flex;
        justify-content: space-between;
        width: 100%;
    }

}

.ShopInfoBox_ShopBadges__3SxSp {
    display: flex;
}

.ShopInfoBox_SqBox1__vBa5u,
.ShopInfoBox_SqBox3__2o2xM, .ShopInfoBox_SqBox4__115Jb,
.ShopInfoBox_SqBox5__UhxM0, .ShopInfoBox_ShopExitIcon__5Lakg {
    position: relative;
}

.ShopInfoBox_BadgeTooltipText__2cJ7H {
    /* Position the tooltip */
    left: -10.4rem;
    /*right: 20%;*/
    top: 2.2rem;
}

.ShopInfoBox_BadgeTooltipText__2cJ7H::after {
    /* position tooltip correctly */
    left:79%;

    /* vertically center */
    bottom:94%;
}

.ShopInfoBox_SqBox1__vBa5u:hover .ShopInfoBox_BadgeTooltipText__2cJ7H,
.ShopInfoBox_SqBox1__vBa5u:hover .ShopInfoBox_BadgeTooltipText__2cJ7H:after,
.ShopInfoBox_SqBox3__2o2xM:hover .ShopInfoBox_BadgeTooltipText__2cJ7H,
.ShopInfoBox_SqBox3__2o2xM:hover .ShopInfoBox_BadgeTooltipText__2cJ7H:after,
.ShopInfoBox_SqBox4__115Jb:hover .ShopInfoBox_BadgeTooltipText__2cJ7H,
.ShopInfoBox_SqBox4__115Jb:hover .ShopInfoBox_BadgeTooltipText__2cJ7H:after,
.ShopInfoBox_SqBox5__UhxM0:hover .ShopInfoBox_BadgeTooltipText__2cJ7H,
.ShopInfoBox_SqBox5__UhxM0:hover .ShopInfoBox_BadgeTooltipText__2cJ7H:after,
.ShopInfoBox_ShopExitIcon__5Lakg:hover .ShopInfoBox_BadgeTooltipText__2cJ7H,
.ShopInfoBox_ShopExitIcon__5Lakg:hover .ShopInfoBox_BadgeTooltipText__2cJ7H:after
{
    visibility: visible;
    opacity: 1;
    transition: 600ms ease-out;
}

@media screen and (max-width: 699px){
    .ShopInfoBox_BadgeTooltipText__2cJ7H {
        display: none;
    }
}

.ShopInfoBox_InfoWithIconWrapper__31f2Y {
    display:flex;
    margin-bottom: 8px;
}

.ShopInfoBox_RateBadgeText__2W31C {
    color: white;
    font-weight: 800;
    font-size: 14px;
}

@media screen and (min-width: 700px) {
    .ShopInfoBox_RateBadgeText__2W31C {
        font-size: 16px;
    }
}
.SideDrawer_SideDrawer__3R1a5 {
    position: fixed;
    width: 17%;
    height: 100%;
    right: 20px;
    z-index: 30;
    background-color: white;
    box-sizing: border-box;
    transition: -webkit-transform 0.3s ease-out;
    transition: transform 0.3s ease-out;
    transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
    border-radius: 6px;
}

.SideDrawer_Open__3FEDM {
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
}

.SideDrawer_Close__awT1H {
    -webkit-transform: translateX(120%);
            transform: translateX(120%);
}
.ShoppingCartListItem_ShoppingCartListItem__1f4KL {
    box-sizing: border-box;
    width: 100%;
    /*background-color: red;*/
    border-bottom: 1px solid #e2e8ecff;
    display: flex;
    flex-direction: row;
    font-size: 14px;
    /*padding: 0 24px;*/
}

.ShoppingCartListItem_ShoppingCartListItem__1f4KL .ShoppingCartListItem_Image__1Alw4 {
    min-width: 5rem;
    min-height: 5rem;
    padding: 0 0.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.ShoppingCartListItem_ShoppingCartListItem__1f4KL .ShoppingCartListItem_RightPartSideDrawer__3GUGi {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 70%;
}

.ShoppingCartListItem_ShoppingCartListItem__1f4KL .ShoppingCartListItem_BottomPartSideDrawer__3Suaw {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.ShoppingCartListItem_ShoppingCartListItem__1f4KL .ShoppingCartListItem_LeftPartSideDrawer__39w3Y {
    display: flex;
    flex-direction: column;
    width: 30%;
}

.ShoppingCartListItem_ShoppingCartListItem__1f4KL .ShoppingCartListItem_RightPartShopPage__18uPM {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 70%;
}

.ShoppingCartListItem_ShoppingCartListItem__1f4KL .ShoppingCartListItem_RightPart__3LdCJ {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-evenly;

}

/*style={{display:"flex", flexDirection:"column", justifyContent:"space-between", width:"70%"}}*/

.ShoppingCartListItem_RightPartShopPage__18uPM p {
    margin-top: 1rem;
    font-weight: 700;
}

.ShoppingCartListItem_Quantity__26sTy {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.ShoppingCartListItem_QuantityText__2uxWw {
    font-size: 0.875rem;
    border: 2px solid #00B2B1;
    border-radius: 6px !important;
    width: 36px;
    height: 32px;
    box-sizing: border-box;
    text-align: center;
    font-weight: 700;
}

.ShoppingCartListItem_Button__3rPw1 {
    background-color: transparent;
    color: #4a4f54ff;
    border: none;
    cursor: pointer;
}

.ShoppingCartListItem_IconStyle__2E1Pd {
    color: #4a4f54;
    height: 18px;
    width: 18px;
    stroke-width: 1;
    display: flex;
    align-content: center;
}

.ShoppingCartListItem_ItemAvailability__1d6ME {
    position: absolute;
    left: 0;
    top: 0;
}

.ShoppingCartListItem_ItemPrice__1TECE {
    font-size: 18px;
    display: flex;
    justify-content: end;
    align-items: center;
}

.ShoppingCartListItem_ItemPriceQuestionMark__1FOH9 {
    position: relative;
}

.ShoppingCartListItem_TooltipText__289E5 {
    /* Position the tooltip */
    left: -176px;
    top: 32px;
}

.ShoppingCartListItem_TooltipText__289E5::after {
    /* position tooltip correctly */
    left:80%;

    /* vertically center */
    bottom: 94%;
}

.ShoppingCartListItem_ItemPriceQuestionMark__1FOH9:hover .ShoppingCartListItem_TooltipText__289E5,
.ShoppingCartListItem_ItemPriceQuestionMark__1FOH9:hover .ShoppingCartListItem_TooltipText__289E5:after
{
    visibility: visible;
    opacity: 1;
    transition: 600ms ease-out;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

.ShoppingCartListItem_LoyaltyPointsIcon__1FQTb {
    display: flex;
    justify-content: flex-end;
}
.ShoppingCartList_ShoppingCartList__t54Us {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: calc(100% - 94px - 75px);
    box-sizing: border-box;
    /*padding: 0 24px;*/
}

.ShoppingCartList_ShoppingCartListScrollbar__3hN1p {
    height: 100%;
    overflow-y: hidden;
}

.ShoppingCartList_ConfirmationScrollBar__3PrAG {
    /*height: 40vh;*/
    /*overflow-y: hidden;*/
    margin-bottom: 1rem;
}

.ShoppingCartList_ShoppingCartList__t54Us .ShoppingCartList_TotalSideDrawer__9CPtg {
    height: -webkit-max-content;
    height: max-content;
    display: flex;
    justify-content: space-between;
    padding: 0 24px;
    font-size: 20px;
    font-weight: 700;
}

.ShoppingCartList_ShoppingCartList__t54Us .ShoppingCartList_TotalShoppingCartPage__3fIRn {
    display: none;
}

.ShoppingCartList_ShoppingCartListFinalView__2w92O {

}

.ShoppingCartList_TotalConfirmation__3Ew-a {
    display: none;
    /*display: flex;*/
    /*justify-content: space-between;*/
    /*padding: 0 0.625rem;*/
}
.AlertComponent_AlertWrapper__3_dSU {
    padding: 8px;
    font-weight: bold;
    display: flex;
    align-items: center;
}

@media screen and (max-width: 699px) {
    .AlertComponent_AlertWrapper__3_dSU {
        margin-left: 1rem;
    }
}

.AlertComponent_WarningAlert__3zURk {
    border: 1px solid #F58300;
    color: #F58300;
}
.ShopPageActionMenu_SearchDiv__1d7Wo {
    height: auto;
    width: 100%;
    position: relative;
    border-radius: 3px;
    border: 1px solid #e6e6e6;
    padding-left: 2rem;
    outline: 0;
    box-shadow: 0 0 0 0 hsl(0deg 0% 53% / 0%);
    font-size: 1rem;
    color: #535358;
    z-index: 22;
    background-color: #fff;
    display: flex;
    justify-content: space-between;
}

.ShopPageActionMenu_IconStyle__3YMgE {
    width: 24px;
    height: 24px;
    stroke: #88959eff;
}

.ShopPageActionMenu_IconStyleClicked__tI8qg {
    width: 24px;
    height: 24px;
    stroke: #00B2B1;
}

.ShopPageActionMenu_IconStyle__3YMgE:hover {
    stroke: #00B2B1;
}
.ShopArticleSort_SelectHolder__38fe0 {
    display: flex;
    align-items: center;
    position: relative;
    border: none;
    padding-right: 0.75rem;
}

select {
    height: 100%;
    width: 135px;
    font-size: 14px;
    color: #88959eff;
    border-radius: 3px;
    cursor: pointer;
    border: none;
}


@media screen and (min-width: 700px) {
    .ShopArticleSort_SelectHolder__38fe0 {
        border: 1px solid #e6e6e6;
    }
    select {
        width: 180px;
        height: 44px;
        padding: 0 12px;
    }
}
.ValidateRegistrationPane_ValidateRegistrationPane__3AnaW {

}

.OrderCompletionPage_OrderCompletionPage__3g_02 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 4.375rem;
}



.Stepline_SteplineWrapper__WrGRm {
    width: 1366px;
    box-sizing: border-box;
    padding: 1.5rem 32px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.Stepline_StepLineInfoText__JFy9T {
    width: 320px;
    height: 162px;
    color: #5b6977ff;
    font-size: 1.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    }

.Stepline_StepsWrapper__3xFjZ {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.Stepline_StepNumberWrapper__vJJ3w {
    width: 90%;
    height: 1px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.Stepline_SteplineItem__1PBcQ {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin: 10px;
    z-index: 10;
}

.Stepline_SteplineItem__1PBcQ.Stepline_Unmarked__NpWoc,
.Stepline_SteplineItem__1PBcQ .Stepline_Skipped__2AOFG {
    border-bottom: 2px solid #bdccd4ff;
}

.Stepline_SteplineItem__1PBcQ .Stepline_Marked__33iq2 {
    border-bottom: 2px solid #00B2B1;
}

.Stepline_StepNumberWrapper__vJJ3w.Stepline_Unmarked__NpWoc,
.Stepline_StepNumberWrapper__vJJ3w .Stepline_Skipped__2AOFG {
    background-color: #bdccd4ff;
    color: #013a63ff;
    border: 1px solid #bdccd4ff;
}

.Stepline_StepNumberWrapper__vJJ3w.Stepline_Marked__33iq2{
    background-color: #00B2B1;
    color: white;
    border: 1px solid #00B2B1;
}

.Stepline_StepNumberText__3xtFv {
    font-size: 20px;
    width: 100%;
    font-weight: bold;
}

.Stepline_StepNumberText__3xtFv.Stepline_Unmarked__NpWoc,
.Stepline_StepNumberText__3xtFv.Stepline_Skipped__2AOFG {
    color: #bdccd4ff;
}

.Stepline_StepNumberText__3xtFv.Stepline_Marked__33iq2 {
    color: #00B2B1;
}

/*.RightPane {*/
/*    width: 100%;*/
/*    height: 100%;*/
/*    display: flex;*/
/*    flex-direction: column;*/
/*    justify-content: space-between;*/
/*    border-radius: 0.375rem;*/
/*    box-sizing: border-box;*/
/*    padding: 0.5rem;*/
/*}*/

.CartSummary_ShopInfo__1vSKi {
    color: #5b6977ff;
}

.CartSummary_UserInfo__OZf5c {
    background-color: #F09200;
    color: white;
    border: 1px solid #F09200;
    border-radius: 15px;
    padding: 1rem;
}

.CartSummary_CartSummaryContinueShoppingButtons__2ehfe {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
}

.CartSummary_CartSummaryContinueShoppingButtons__2ehfe .CartSummary_IconWrapper__1amSu {
    /*border: 1px solid #5b6977ff;*/
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1.8rem;
    height: 1.8rem;
    border-radius: 50%;
    padding:.3rem;
}

.CartSummary_LeftPartHeader__84bbT {
    height: 110px;
    border-bottom:1px solid #E2E8EC;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.CartSummary_CartActionsWrapper__2wJBT {
    display: flex;
    justify-content: space-between;
    padding-bottom: 1rem;
}
.OrderCompletionPageTemplate_WrapperForOCPT__cbm6r {
    width: 1304px;
    min-height: 60vh;
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    flex-direction: column;
    background-color: white;
    box-sizing: border-box;
    border-radius: 6px;
    color: #5b6977ff;
}

.OrderCompletionPageTemplate_WrapperForOCPTLeftSide__2WkMH {
    min-height: -webkit-min-content;
    min-height: min-content;

}

.OrderCompletionPageTemplate_WrapperForOCPTRightSide__3fBQU {
    width: 100%;
    min-height: 60vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /*border-radius: 0.375rem;*/
    box-sizing: border-box;
}

.OrderCompletionPageTemplate_WrapperForOCPTTop__2erYl {
    background-color: white;
}
.PharmacyData_ShopInfo__EXZTv {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 1rem;
}

.PharmacyData_PharmacyName__3Oknh {
    font-size: 16px
}

.PharmacyData_WorkingHours__1QWTb {
    display: flex;
    align-items: center;
    font-size: 16px;
}

.PharmacyData_PharmacyClosed__3tNDK {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 20px;
    color: #00B2B1;
}

.PharmacyData_PharmacyClosedText__3QxG5 {
    text-align: center;
}

.PharmacyData_PharmacyAddress__1eUXj {
    font-size: 14px;
    margin-bottom: 0.5rem;
}

@media screen and (min-width: 700px) {
    .PharmacyData_ShopInfo__EXZTv {
        align-items: flex-start;
        margin-top: 0;
    }

    .PharmacyData_PharmacyName__3Oknh {
        font-size: 24px;
        font-weight: 700;
    }

    .PharmacyData_WorkingHours__1QWTb {
        font-size: 18px;
    }

    .PharmacyData_PharmacyClosed__3tNDK {
        padding: 8px 0;
    }

    .PharmacyData_PharmacyClosedText__3QxG5 {
        text-align: left;
    }

    .PharmacyData_PharmacyAddress__1eUXj {
        font-size: 16px;
    }
}
.TotalAmount_TotalAmount__20r1_ {
    display: flex;
    justify-content: space-between;
    /*color: #00A4B4;*/
    border-bottom: 1px solid #e2e8ecff;
    margin-bottom: 1rem;
    height: 110px;
}

.TotalAmount_TotalAmount__20r1_ h2 {
    margin-top: 0;
    margin-bottom: 26px;
    font-weight: 700;
    font-size: 28px;
}
.Actions_Actions__1Nw4z {
    border-bottom: 1px solid #e2e8ecff;
    display: flex;
    justify-content: space-between;
    padding: 0 0 2rem 0;
    margin-bottom: 2rem;
}
.OrderCompletionPageTemplateMobile_WrapperForOCPTMobile__13QDQ {
    display: flex;
    flex-direction: column;
    align-items: center;

}
.DeliveryOptions_ShopInfo__30Gg- {
    background-color: #416caa;
    color: white;
    border: 1px solid #416caa;
    border-radius: 15px;
    padding: 1rem;
}

.DeliveryOptions_DeliveryChoiceButton__2WIYf {
    border: 1px solid #00B2B1;
    border-radius: 3px;
    box-sizing: border-box;
    cursor: pointer;
    width: 320px;
    height: 4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    color: #00B2B1;
    stroke: #00B2B1;
}

.DeliveryOptions_DeliveryChoiceButton__2WIYf:hover {
    background-color: #16CBCA;
    color: white;
    stroke: white;
}

.DeliveryOptions_DeliverySelected__uEToL {
    background-color: #00B2B1;
    color: white;
    stroke: white;
}

.DeliveryOptions_DeliveryChoiceButton__2WIYf h4 {
    width: -webkit-max-content;
    width: max-content;
}

.DeliveryOptions_DeliveryChoiceButtonHolder__N-FFc {
    height: 40px;
    border: 1px solid transparent;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
}

.DeliveryOptions_DeliveryChoiceButtonDisabled__2smdr {
    border: 1px solid #00B2B1;
    border-radius: 3px;
    box-sizing: border-box;
    cursor: pointer;
    width: 320px;
    height: 4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    color: #00B2B1;
    stroke: #00B2B1;
}

.DeliveryOptions_ChoiceWrapper__18fmD {
    display: flex;
    margin-top: 1.5rem;
    border-top: 1px solid #e2e8ecff;
    height: 35vh;
    align-items: center;
    justify-content: left;
}

.DeliveryOptions_DeliveryChoiceButtonWrapper__2C5sd {
    height: 50%;
}
.AddressSelection_AddressesList__1StO- {
    /*box-shadow: 0 0 0.8rem #cccccc;*/
    border: 1px solid #e2e8ecff;
    border-radius: 5px;
    height: 6.75rem;
    padding: 1rem 1.5rem;
    min-width: 15rem;
    width: 17.0625rem;
    margin-top: 0.75rem;
    cursor: pointer;
    position: relative;
    box-sizing: border-box;
    margin-right: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.AddressSelection_UserAddressesWrapper__3O5y4 {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
}

.AddressSelection_AddressesListRadioButtonWrapper__3zMgM {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    border: 1px solid #5b6977ff;
    display: flex;
    justify-content: center;
    align-items: center;
}

.AddressSelection_AddressesListRadioButton__2UHbF {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    border: 1px solid #5b6977ff;
}

.AddressSelection_AddressesListRadioButtonWrapperSelected__14SU5 {
    border: 1px solid #00B2B1;
}

.AddressSelection_AddressesListRadioButtonSelected__k4ofT {
    background-color: #00B2B1;
    border: 1px solid #00B2B1;
}

.AddressSelection_ShopInfo__2Wqyr {
    background-color: #416caa;
    color: white;
    border: 1px solid #416caa;
    border-radius: 15px;
    padding: 1rem;
}

@media screen and (max-width: 699px) {
    .AddressSelection_AddressesList__1StO- {
        width: 90%;
    }

    .AddressSelection_UserAddressesWrapper__3O5y4 {
        width: 100vw;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
    }
}
.EditNameAndSurname_EditInputFiled__1ESCy {
    width: 100%;
    border: 1px solid #f1f4f6ff;
    border-radius: 3px;
    box-sizing: border-box;
    padding: 1rem;
    margin-top: 1rem;
    font-size: 16px;
    color: #5b6977ff;
}

.EditNameAndSurname_FormWrapper__3Dwg- {
    width: 100%;
    padding: 0 1rem;
    box-sizing: border-box;
}


@media screen and (min-width: 700px) {
    .EditNameAndSurname_FormWrapper__3Dwg- {
        font-weight: bold;
        margin-top: 1rem;
        padding: 0;
    }

    .EditNameAndSurname_Form__zf_Qf {
        display: flex;
    }
}

.EditNameAndSurname_Checkbox__JQeO2 {
    outline: none;
    border: 0.063rem solid #ccc;
    background-color: white;
    font: inherit;
    padding: 0.375rem 0.625rem;
    display: block;
    width: 100%;
    box-sizing: border-box;
}
.SummaryAndConfirmation_ShopInfo__kw63I {
    background-color: #416caa;
    color: white;
    border: 1px solid #416caa;
    border-radius: 15px;
    padding: 1rem;
}

.SummaryAndConfirmation_PulsingPhoneText__25mER {
    /* Chrome, Safari, Opera */
    -webkit-animation: SummaryAndConfirmation_PULSE__21dZf 1s infinite;

    /* Standard Syntax */
    animation: SummaryAndConfirmation_PULSE__21dZf 1.25s infinite;
}

/* Chrome, Safari, Opera */
@-webkit-keyframes SummaryAndConfirmation_PULSE__21dZf{
    0%{color:rgba(255,0,255,0);}
    110%{color: black;}
}

/* Standard Syntax */
@keyframes SummaryAndConfirmation_PULSE__21dZf{
    0%{color:rgba(0,255,255, 0);}
    110%{color: black;}
}

.SummaryAndConfirmation_UserNoteWrapper__2q1KW {
    margin: 1rem 0 1rem;
    width: 90%;
}

.SummaryAndConfirmation_UserNoteWrapper__2q1KW textarea {
    height: 4.5rem;
    border-radius: 6px;
}

.SummaryAndConfirmation_PrescriptionImagesPreviewPlacer__115An {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
}


.SummaryAndConfirmation_TooltipWrapper__2u7Lh {
    position: relative;
}

.SummaryAndConfirmation_TooltipText__1lomS {
    /* Position the tooltip */
    left: -5%;
    top: 4rem;
}

.SummaryAndConfirmation_TooltipText__1lomS::after {
    /* position tooltip correctly */
    left:50%;

    /* vertically center */
    bottom: 94%;
}

.SummaryAndConfirmation_TooltipWrapper__2u7Lh:hover .SummaryAndConfirmation_TooltipText__1lomS,
.SummaryAndConfirmation_TooltipWrapper__2u7Lh:hover .SummaryAndConfirmation_TooltipText__1lomS:after
{
    visibility: visible;
    opacity: 1;
    transition: 600ms ease-out;
}

.SummaryAndConfirmation_Note__x2poi {
    width: 100%;
    resize: none;
    border: 1px solid #e5e5e5;
}

.SummaryAndConfirmation_CharactersNumber__Hh_7i {
    display: flex;
    justify-content: flex-end;
    font-size: 12px;
    color: #5b6977ff;
}

.SummaryAndConfirmation_PrescriptionPart__FQk2N {
    width: 90%;
    padding-top: 2rem;
    margin: auto;
    border-top: 1px solid #e2e8ecff;
}

.SummaryAndConfirmation_PrescriptionPartCheckbox__3kflv {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    width: 90%;
    padding-top: 1rem;
    border-top: 1px solid #e2e8ecff;
    margin-top: 1rem;
}

.SummaryAndConfirmation_DeliveryOptionHeaderMobile__tQA5w {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 1.5rem;
    width: 90%;
    border-bottom: 1px solid #e2e8ecff;
    color: #4D5055;
}

.SummaryAndConfirmation_UserAddressMobile__9-oDA {
    width: 100vw;
    padding: .5rem;
    background-color: #FAFAFB;
    display: flex;
    justify-content: center;
}

@media screen and (min-width: 700px){
    .SummaryAndConfirmation_UserNoteWrapper__2q1KW {
        width: 100%;
    }

    .SummaryAndConfirmation_UserNoteWrapper__2q1KW textarea {
        height: auto;
        border-radius: 6px;
        padding: 1rem;
    }

    .SummaryAndConfirmation_PrescriptionPart__FQk2N {
        width: auto;
        margin: 0;
    }
    
    .SummaryAndConfirmation_PrescriptionPartCheckbox__3kflv {
        width: 100%;
    }
}
.EnterPhoneNumber_EnterPhoneNumberWrapper__76LvM {
    padding: 1rem 1rem 1rem;
    width: 90vw;
}

@media screen and (min-width: 700px) {
    .EnterPhoneNumber_EnterPhoneNumberWrapper__76LvM {
        border-top: 1px solid #e2e8ecff;
        border-bottom: 1px solid #e2e8ecff;
        width: 100%;
        padding: 1rem 0 1rem;
    }
}
.PrescriptionImageUploader_ImagesUploadHolder__1emT8 {
    display: flex;
}

.PrescriptionImageUploader_ImageUploader__2qTLS {
    margin: 1rem;
    max-height: 60vh;
    overflow-y: scroll;
    border: 1px solid #5b6977ff;
    border-radius: 6px;
}

.PrescriptionImageUploader_ButtonsHolder__21yS7 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 1rem 1rem;
}

.PrescriptionImageUploader_UploadProgressMessage__3NKc1 {
    width: 900px;
    height: 600px;
    position: fixed;
    top: calc(50% - 300px);
    left: calc(50% - 450px);
    border: 1px solid grey;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.PrescriptionImageUploader_Modal__3j1Ca {
    width: 800px;
    left: calc(50% - 400px);
    z-index: 40;
}

@media screen and  (max-width: 699px) {
    .PrescriptionImageUploader_Modal__3j1Ca {
        width: 95vw;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
    }
}
.PrescriptionImageThumbnail_PrescriptionImagePreviewHolder__Jrz6U {
    width: 100px;
    border: 1px solid #8b8989;
    border-radius: 3px;
    box-sizing: border-box;
    padding: 5px;
    margin-bottom: 16px;
    margin-right: 6px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    cursor:pointer;
}

.PrescriptionImageThumbnail_PrescriptionImagePreview__2DZEK {
    max-width: 100px;
    max-height: 100px;
}

.PrescriptionImageThumbnail_PrescriptionImagePreview__2DZEK img {
    max-width: 100%;
    max-height: 100%;
}

.PrescriptionImageThumbnail_PrescriptionImageDeleteButton__2b7c7 {
    position: absolute;
    right: 2px;
    top: 2px;
    width: 24px;
    height: 24px;
    border-radius: 3px;
    background-color: #ef7f1a;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    cursor: pointer;
    

}
.OrderCompletionMessagePage_OrderCompletionPageHolder__1Prjk {
    padding: 6rem 20rem 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.OrderCompletionMessagePage_OrderCompletionPageMessage__2tmP9 {
    padding: 4rem 7rem;
    display: flex;
    box-sizing: border-box;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: white;
    /*border: 1px solid #e2e8ecff;*/
    border-radius: 6px;
    font-size: 18px;
    /*box-shadow: 0 0 0.8rem #cccccc;*/
}

.OrderCompletionMessagePage_OrderCompletionPage__236Ud p {
    margin: auto;
}

.OrderCompletionMessagePage_OrderCompletionPageHolderMobile__kmAnG {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 80vh;
}
.OrderCompletionMessagePage_OrderCompletionPageMobile__3YCBI {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 90%;
}

.OrderCompletionMessagePage_OrderCompletionPageMobile__3YCBI p {
    color: #4D5055;
    text-align: center;
}
.DeliveryOptionsMobile_DeliveryOptionsHolderMobile___3VrJ {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.DeliveryOptionsMobile_DeliveryOptionsHolderMobile___3VrJ .DeliveryOptionsMobile_MessageHeader__1WmrK {
    font-size: 18px;
    text-align: left;
    margin-top: 1rem;
    margin-left: 1rem;
}

.DeliveryOptionsMobile_ChoiceWrapper__2AwX_ {
    display: flex;
    flex-direction: column;
    margin-top: 1.5rem;
    height: 35vh;
    align-items: center;
    justify-content: space-evenly;
}
.ButtonRes_BigButton__3hJSW {
    border: 1px solid #e2e8ecff;
    border-radius: 6px;
    padding: 0;
    box-sizing: border-box;
    cursor: pointer;
    width: 250px;
    height: 4rem;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    background-color: transparent;
}

.ButtonRes_Link__im5_x {
    background-color: transparent;
    color: #5b6977ff;
    text-decoration: underline;
    font-weight: bold;
    border: none;
    cursor: pointer;
    padding: 1rem;
}
.ErrorPage_ErrorPage__2xSeE {
    height: 62vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 1rem;
}

.ErrorPage_ErrorPage__2xSeE .ErrorPage_Title__5iQgs {
    font-size: 22px;
    font-weight: bold;
}

.ErrorPage_LinkToMain__2OvAR {
    padding: 0.5rem;
    cursor: pointer;
    text-decoration: none;
    color: #535358;
    font-size: 14px;
}

.ErrorPage_LinkToMain__2OvAR:hover {
    background-color: rgba(255, 255, 255, 0.2);
}



.StorePageHeader_StorePageHeader__3axKa {
    top: 70px;
    padding: 5rem 2rem 4.5rem;
    box-sizing: border-box;
    height: 450px;
}

.StorePageHeader_StorePageHeader__3axKa .StorePageHeader_Content__3crqH {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    /*width: 75rem;*/
    margin: auto;
    position: relative;

}

.StorePageHeader_StorePageHeader__3axKa .StorePageHeader_ContentLeft__1Mglp {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.StorePageHeader_StorePageHeader__3axKa .StorePageHeader_ExitAndShopBrandGroup__29TsB {
    display: flex;
    flex-direction: row;
    margin-top: auto;
}

.StorePageHeader_StoreImage__32xLC {
    display: flex;
    flex-direction: column;
    max-width: 480px;
    height: 100%;
    /*position: absolute;*/
    right: 0;
    /*bottom: 2.1875rem;*/
    cursor: pointer;
}

.StorePageHeader_Image__1S2U7 {
    position: absolute;
    right: 0;
    bottom: 0;
    height: 300px;
    width: auto;
    margin-right: 11rem;
}

.StorePageHeader_StoreInfoHeaderMobile__3pmMf {
    padding: 0.5rem;

}
.StorePageActionMenu_ActionsHolder__3F2Fh {
    display: flex;
}

.StorePageActionMenu_ActionsHolder__3F2Fh span {
    color: #88959eff;
}
.StorePageSubNavigation_SubNavigationMenuWrapper__LLK0X {
    width: 100vw;
    /*margin: auto;*/
    /*display: flex;*/
    /*justify-content: space-around;*/
}

.StorePageSubNavigation_SubNavigationMenuWrapper__LLK0X nav ul {
    width: 100%;
    display: flex;
    justify-content: space-around;
    list-style-type: none;
    margin-top: 1rem;
    padding: 0;
    overflow: hidden;
}

@media screen and (min-width: 700px) {
    .StorePageSubNavigation_SubNavigationMenuWrapper__LLK0X {
        width: 100%;
        height: 3rem;
        margin: auto;
        display: flex;
        justify-content: space-around;
    }

    .StorePageSubNavigation_SubNavigationMenuWrapper__LLK0X nav ul {
        width: 70rem;
        margin-top: 6rem;
    }
}

nav ul li a {
    display: block;
    color: grey !important;
    text-align: center;
    padding: 16px;
    text-decoration: none;
}

.StorePageSubNavigation_LinkTextWrapper__2L7e1 {
    cursor: pointer;
    width: 100%;
    position: relative;
    /*z-index: 1;*/
    display: flex;
    justify-content: center;

}

.StorePageSubNavigation_SubNavigation__3fVd1 {
    cursor: pointer;
    width: 100%;
    position: relative;
    /*z-index: 1;*/
    display: flex;
    justify-content: center;
    border-bottom: 3px transparent;
}

.StorePageSubNavigation_SubNavigation__3fVd1:hover:after  {
    content: ""; /* This is necessary for the pseudo element to work. */
    position: absolute;
    bottom: 0;
    /*display: block; !* This will put the pseudo element on its own line. *!*/
    margin: 0 auto; /* This will center the border. */
    width: 5px; /* Change this to whatever width you want. */
    padding-top: 20px; /* This creates some space between the element and the border. */
    border-bottom: 3px solid grey; /* This creates the border. Replace black with whatever color you want. */
}

/* ovo se ucitava inicijalno, kada se pristupi strani /myprofile */
.StorePageSubNavigation_Selected__2mDvI:after {
    content: ""; /* This is necessary for the pseudo element to work. */
    position: absolute;
    bottom: 0;
    margin: 0 auto; /* This will center the border. */
    width: 100%; /* Change this to whatever width you want. */
    padding-top: 20px; /* This creates some space between the element and the border. */
    border-bottom: 3px solid grey; /* This creates the border. Replace black with whatever color you want. */

}

.StorePageSubNavigation_Selected__2mDvI:focus:after {
    content: ""; /* This is necessary for the pseudo element to work. */
    position: absolute;
    bottom: 0;
    margin: 0 auto; /* This will center the border. */
    width: 100%; /* Change this to whatever width you want. */
    padding-top: 20px; /* This creates some space between the element and the border. */
    border-bottom: 3px solid grey; /* This creates the border. Replace black with whatever color you want. */
    transition: width .2s ease-out;
}

.StorePage_StorePage__39k-F {

}

.StorePage_PagePart__1TBas {
    width: 100%;
    display: flex;
    flex-direction: row;
}

.StorePage_LeftSide__3vGKA {
    width: 400px;
    margin: 32px;
    box-sizing: border-box;

}

.StorePage_RightSide__2ZEwJ {
    /*background-color: white;*/
    width: calc(100% - 400px - 64px);
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin: 32px 32px 0 0 ;
    box-sizing: border-box;
}
.InformationBox_InformationBox__1sKqg {
    display: flex;
    flex-direction: column;
    border-radius: 6px;
    background-color: white;
    border: 1px solid #E2E8EC;
    box-shadow: 0 0 0.8rem #E2E8EC;
    padding: 27px;
}

.InformationBox_InfoTitle__2QzPb {
    color: #434E59;
    margin-bottom: 27px;
}

.InformationBox_LeftBoxItemWrapper__2frAa {
    display: flex;
    flex-direction: row;
    margin-bottom: 27px;
}

.InformationBox_IconStyle__3-hxs {
    height: 24px;
    background-color: #88959E;
    color: white;
    /*width: 81px;*/
    width: 24px;
    margin-right: 27px;
    border-radius: 3px;
    padding: 0.1rem;
    box-sizing: border-box;
    }

.InformationBox_PharmacyInfo__Rujuf {
    color: #434E59;
}

.InformationBox_PharmacyAddress__2_oQj {
    cursor: pointer;
    color: #219EBC;
}

.InformationBox_PharmacyPhone__3a3kL {
    color: #219EBC;
}

.InformationBox_PharmacyEmail__3US9m {
    color: #219EBC;
}

.InformationBox_PharmacyOpeningHoursWrapper__2k4yt {
    width: 100%;
    display: flex;
    flex-direction: column;
}
.InformationBox_PharmacyOpeningHoursDays__3W8K8 {
    display: flex;
    flex-direction: row;
    color: #434E59;
}
.InformationBox_Day__1SJA3 {
    width: 40%;
}

.InformationBox_CurrentDay__3alq- {
    color: #219EBC;
}
.InformationBoxRight_InformationBoxRightWrapper__1czkY {
    background-color: white;
    border-radius: 6px;
    margin-bottom: 27px;
    box-sizing: border-box;
    padding: 27px;

    border: 1px solid #E2E8EC;
    box-shadow: 0 0 0.8rem #E2E8EC;
}

.InformationBoxRight_PharmacyName__1P-od {
    color: #434E59;
    margin-bottom: 27px;
}

.InformationBoxRight_PharmacyDescription__2obJ9 {
    color: #434E59;
    box-sizing: border-box;
}
.GoodbyePage_GoodbyePageWrapper__3NdLI {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    /*background-color: #F9F9F9;*/
}

.GoodbyePage_MessageBox__3YIKi {
    width: 40%;
    height: -webkit-min-content;
    height: min-content;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1.5rem;
    box-shadow: 0 0 0.8rem #cccccc;
}
.GoodbyePage_GoodbyePageWrapper__3NdLI a {
    color: white;
    text-decoration: none;
}


@media screen and (max-width: 699px) {
    .GoodbyePage_MessageBox__3YIKi p, .GoodbyePage_MessageBox__3YIKi a {
        text-align: center;
    }
}

.LegalDocuments_LegalDocuments__1YMrV {
    padding: 50px 50px;
    display: flex;
    flex-direction: column;
    justify-content: left;
}

.LegalDocuments_LegalDocuments__1YMrV table {
    border-collapse: collapse;
    width: 100%;
}

.LegalDocuments_LegalDocuments__1YMrV tr {
    border-top: 1px solid #ccc;
}

.LegalDocuments_LegalDocuments__1YMrV th {
    padding-top: 16px;
    padding-bottom: 16px;
}

.LegalDocuments_LegalDocuments__1YMrV .LegalDocuments_Headers__OUGyt {
    font-weight: bold;
}
.VideoCallPage_VideoCallPage__1W0Nt {
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 352px);
}
/*main {*/
/*    width: 70%;*/
/*    margin: auto;*/
/*    text-align: center;*/
/*}*/

.VideoCall_Zoom__3z7Fb {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    top: 65px;
}

.VideoCall_Zoom__3z7Fb h1 {
    text-align: center;
}

.VideoCall_Zoom__3z7Fb .VideoCall_Description__3IrZ3 {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 1rem;
}

#VideoCall_meetingSDKElement__26PdA {
    top: 59px !important;
    left: 0 !important;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    width: 244px; /* width of speaker and ribbon view */
}

.VideoCall_MuiBox-root__3DrtD {
    width: 700px;
}
.MisljenjeMinistarstva_PdfContainer__3UGa3 {
    width: 100%;
    display: flex;
    justify-content: center;
}

@media screen and (min-width: 700px) {
    .MisljenjeMinistarstva_PdfContainer__3UGa3 {
        padding-top: 100px;
    }
}

@media print {
    .MisljenjeMinistarstva_PdfFile__3Me__ {
        display: none;
    }
}
.ComingSoon_ComingSoonWrapper__XMyFq {
    padding: 50px 50px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    min-height: 40vh;
}
.LoyaltyProgramPage_HeaderImage__3XS7q {
    background-repeat: no-repeat;
    background-size: 100vw;
    background-position-y: -60px;
    height: 185px;
}

.LoyaltyProgramPage_TextTitle__2pyq1 {
    margin: 2rem;
    font-size: 22px;
    text-align: center;
}

.LoyaltyProgramPage_Text__1EuqF {
    width: 90vw;
    margin: auto;
    padding: 0 1rem;
    color: #474A53;
}

.LoyaltyProgramPage_TextWithBackground__2kbkI {
    background-color: #E2E8EC;
    display: flex;
    flex-direction: column;
    margin: 3rem 0;
    color: #474A53;
}

.LoyaltyProgramPage_TableStyle__3ETfu {
    border-radius: 16px;
    border: 1px solid #FFAE00;
    width: 90vw;
    margin: 2rem auto;
    text-align: center;
    border-collapse: collapse;
}

.LoyaltyProgramPage_TableStyle__3ETfu .LoyaltyProgramPage_OrangeRow__1z-c2 {
    color: white;
    background-color: #FFAE00;
    font-weight: bold;
}

.LoyaltyProgramPage_TableStyle__3ETfu .LoyaltyProgramPage_ColoredText__1hkXP {
    color: #FFAE00;
}

.LoyaltyProgramPage_TableStyle__3ETfu .LoyaltyProgramPage_MiddleColumn__eAhFc {
    border-left: 1px solid #FFAE00;
    border-right: 1px solid #FFAE00;
}

.LoyaltyProgramPage_TableStyle__3ETfu th {
    padding: 8px;
    width: 33%;
}

@media screen and (min-width: 700px) {
    .LoyaltyProgramPage_HeaderImage__3XS7q {
        width: 100%;
        background-repeat: no-repeat;
        background-size: cover;
        background-position-y: -200px;
        height: 600px;
    }

    .LoyaltyProgramPage_Text__1EuqF {
        width: 820px;
        border-left: 2px solid #FFAE00;
    }

    .LoyaltyProgramPage_TextWithBackground__2kbkI {
        display: flex;
        flex-direction: row;
        margin: 3rem 0;
    }

    .LoyaltyProgramPage_TableStyle__3ETfu {
        width: 854px;
    }
}
.RFZOItem_ShoppingCartListItem__11cb9 {
    box-sizing: border-box;
    width: 100%;
    /*background-color: red;*/
    border-bottom: 1px solid #e2e8ecff;
    display: flex;
    flex-direction: row;
    font-size: 14px;
    /*padding: 0 24px;*/
}

.RFZOItem_ShoppingCartListItem__11cb9 .RFZOItem_Image__YWG6N {
    min-width: 5rem;
    min-height: 5rem;
    padding: 0 0.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.RFZOItem_ShoppingCartListItem__11cb9 .RFZOItem_RightPartSideDrawer__RQiPm {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-evenly;
}

.RFZOItem_ShoppingCartListItem__11cb9 .RFZOItem_MiddlePartSideDrawer__di8un {
    display: flex;
    flex-direction: column;
}

.RFZOItem_ShoppingCartListItem__11cb9 .RFZOItem_RightPartShopPage__x4tnX {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}

.RFZOItem_RightPartShopPage__x4tnX p {
    margin-top: 1rem;
    font-weight: 700;
}

.RFZOItem_Quantity__15V0p {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.RFZOItem_QuantityText__3VUJx {
    font-size: 0.875rem;
    margin: auto;
    border: 2px solid #00B2B1;
    border-radius: 6px !important;
    width: 42px;
    height: 32px;
    box-sizing: border-box;
    text-align: center;
    font-weight: 700;
}

.RFZOItem_Button__3GkTy {
    background-color: transparent;
    color: #4a4f54ff;
    border: none;
    cursor: pointer;
}

.RFZOItem_IconStyle__3Og5- {
    color: #4a4f54;
    height: 18px;
    width: 18px;
    stroke-width: 1;
    display: flex;
    align-content: center;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
}

.RFZOItem_ItemAvailability__32O-M {
    margin-right: auto;
}

.RFZOItem_ItemPrice__25lQh {
    font-size: 18px;
    display: flex;
    justify-content: end;
    align-items: center;
}

.RFZOItem_ItemPriceInCart__dBtGC {
    padding-right: 1rem;
}

.RFZOItem_ItemPriceQuestionMark__2BpcW {
    position: relative;
}

@media screen and (min-width: 700px) {
    .RFZOItem_ItemPrice__25lQh {
        width: 25%;
    }
}

.RFZOItem_TooltipText__qmk18 {
    /* Position the tooltip */
    left: -176px;
    top: 32px;
}

.RFZOItem_TooltipText__qmk18::after {
    /* position tooltip correctly */
    left:80%;

    /* vertically center */
    bottom: 94%;
}

.RFZOItem_ItemPriceQuestionMark__2BpcW:hover .RFZOItem_TooltipText__qmk18,
.RFZOItem_ItemPriceQuestionMark__2BpcW:hover .RFZOItem_TooltipText__qmk18:after
{
    visibility: visible;
    opacity: 1;
    transition: 600ms ease-out;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}
.InactiveToolbar_InactiveToolbarWrapper__1Vxge {
    width: 100%;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
}

.InactiveToolbar_InactiveToolbarContent__2kQCo {
    width: 1366px;
    float: left;
    height: 3.5rem;
    padding:0.5rem 1rem;
    box-sizing: border-box;
}
.RFZOpage_WrapperStyle__3YBOK {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 1rem;
}

.RFZOpage_RFZOArticleList__Qhf13 {
    width: 90vw;
}

@media screen and (min-width: 700px){
    .RFZOpage_WrapperStyle__3YBOK {
        margin-top: 5rem;
    }

    .RFZOpage_RFZOArticleList__Qhf13 {
        width: 70%;
    }
}
.AboutUs_Content__3xlB5 {
    padding-top: 72px;
}

.AboutUs_SectionAboutUs__3UZmi {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 64px;
    position: relative;
    height: 100%;
    box-sizing: border-box;
    color: #474A53;
}

.AboutUs_AllInOnePlace__EC-mm {
    color: #474A53;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    box-sizing: border-box;
    margin-left: 64px;
}

.AboutUs_ColoredBoxWrapper__1ppeV {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.ColoredBox_ColoredBox__yUsbX {
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}
* {
    box-sizing: border-box;
}

.LandingPageForPromo_pageWrapFull__3kzQO {
    width: 100%;
    background: radial-gradient(93.97% 83.47% at 27.43% 63.56%, #04F5F4 0%, #00B2B1 100%);
}

.LandingPageForPromo_pageWrap__3fO_H {
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: auto;
    background: url(/static/media/snowflake.28eed326.png);
    background-position: top right;
    background-repeat: no-repeat;
    max-width: 1440px;
}

.LandingPageForPromo_description__n884a {
    width: 45%;
    color: #FFF;
    font-family: sans-serif;
    font-size: 18px;
    line-height: 120%;
    font-style: normal;
    font-weight: 500;
    padding: 0px 40px 30px;
}

.LandingPageForPromo_paragraph__1cJlT {
    margin-bottom: 15px;
}


.LandingPageForPromo_rfzoImage__2qr1s {
    display: block;
    margin: auto;
    width: 250px;
}

.LandingPageForPromo_image__BpEWD {
    aspect-ratio: 1;
    width: 55%;
    display: block;
}


.LandingPageForPromo_imageWrap__hQmiL {
    margin-right: 10px;
}

.LandingPageForPromo_storeWrap__2HdyB {
    margin: 30px 0;
}


@media screen and (max-width: 1200px) {
    .LandingPageForPromo_pageWrap__3fO_H {
        /* flex-direction: column; */
    }

    .LandingPageForPromo_description__n884a {
        /* width: 100%; */
    }

    .LandingPageForPromo_image__BpEWD {
        /* width: 100%; */
    }

    .LandingPageForPromo_imageWrap__hQmiL img {
        width: 140px;
    }
}

@media screen and (max-width: 992px) {
    .LandingPageForPromo_pageWrap__3fO_H {
        flex-direction: column;
    }

    .LandingPageForPromo_description__n884a {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin: 20px 0;
        align-items: flex-start;
    }

    .LandingPageForPromo_image__BpEWD {
        width: 60%;
    }

    .LandingPageForPromo_paragraphWrap__1r_5R {
        width: 45%;
    }

    .LandingPageForPromo_storeWrapAndContact__3Il1_ {
        width: 45%;
    }

    .LandingPageForPromo_rfzoImage__2qr1s {
        width: 200px;
        margin: 0;
    }

    .LandingPageForPromo_storeWrap__2HdyB {
        margin: 0;
    }
    
    .LandingPageForPromo_imageWrap__hQmiL {
        display: block;
    }

    .LandingPageForPromo_imageWrap__hQmiL img {
        width: 140px;
        margin: 10px 0;
    }
}

@media screen and (max-width: 768px) {
    .LandingPageForPromo_pageWrap__3fO_H {
        flex-direction: column;
    }

    .LandingPageForPromo_description__n884a {
        width: 100%;
    }

    .LandingPageForPromo_image__BpEWD {
        width: 100%;
    }

    .LandingPageForPromo_imageWrap__hQmiL img {
        width: 140px;
    }
}

@media screen and (max-width: 576px) {
    .LandingPageForPromo_pageWrap__3fO_H {
        flex-direction: column;
    }

    .LandingPageForPromo_description__n884a {
        width: 100%;
        padding: 15px;
        flex-direction: column;
    }

    .LandingPageForPromo_image__BpEWD {
        width: 100%;
    }

    .LandingPageForPromo_paragraphWrap__1r_5R {
        width: 100%;
    }

    .LandingPageForPromo_storeWrapAndContact__3Il1_ {
        width: 100%;
    }

    .LandingPageForPromo_imageWrap__hQmiL {
        display: inline-block;
    }

    .LandingPageForPromo_imageWrap__hQmiL img {
        width: 140px;
    }
}
